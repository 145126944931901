import React from "react";
import BackProfileScreenBtn from "../component/backToProfileScreenBtn";
import ScreenName from "../component/screenName";
import TrashIcon from "../../../utils/svg/trashIcon";
import EditIcon from "../../../utils/svg/editIcon";


const AccountPaymentDetails: React.FC = () => {

  function handleDeleteBankAccountBtn() {
    alert("delete bank account");
  }

  function handleEditBtn() {
    alert("edit bank account details");

  }

  return <div className=" bg-light-grey6 pb-[1rem] h-screen  " >
    <div className="mobile:max-w-[22.5rem] mobile:mx-auto mobilels:max-w-[25rem] tablet768:max-w-[31.25rem] tablet:max-w-[31.25rem]" >
      {/*  */}
      <div className=" w-full mobile:px-0 px-[1rem] pt-[2rem] pb-[1rem] " >
        <BackProfileScreenBtn />
      </div>

      {/* screen name */}
      <ScreenName screenName="payment details" />

      <div className=" bg-white px-[1rem] py-[1rem] " >

        <div className=" border-[0.1rem] rounded-[0.2rem] p-[0.5rem] tablet768:flex tablet768:flex-row tablet768:items-center " >

          <div className=" tablet768:w-8/12 " >
            <div className=" flex flex-row items-center border-b-[0.1rem] pb-[1rem]  " >
              <div className=" w-7/12 font-inter uppercase flex flex-col" >
                <span className=" text-locked-grey text-[0.875rem] " >name</span>
                <span className=" text-card-grey text-[1.0625rem] font-semibold  " >{"firstname"} <br /> {"lastname"}</span>
              </div>

              <div className=" w-5/12 font-inter uppercase flex flex-col" >
                <span className=" text-locked-grey text-[0.875rem] " >expiry(m/y)</span>
                <span className=" text-card-grey text-[1.0625rem] font-semibold  " >{"07/2088"}</span>
              </div>
            </div>

            <div className=" flex flex-col tablet768:flex-row tablet768:items-center " >
              <div className=" tablet768:w-7/12 font-inter uppercase flex flex-col tablet768:border-b-[0] border-b-[0.1rem] py-[0.5rem]  " >
                <span className=" text-locked-grey text-[0.875rem] " >card no.</span>
                <div className="" >
                  {[1, 2, 3, 4].map((item, idx) => (
                    <span key={idx} className=" text-card-grey text-[1.0625rem] font-semibold " >
                      {"0000 "}
                      {idx === 1 && < span className="hidden tablet768:flex "><br /></span>}
                    </span>
                  ))}
                </div>
              </div>

              <div className=" tablet768:w-5/12 font-inter uppercase flex flex-col py-[0.5rem] " >
                <span className=" text-locked-grey text-[0.875rem] " >CVV/CVC</span>
                <span className=" text-card-grey text-[1.0625rem] font-semibold  " >{"000"}</span>
              </div>
            </div>
          </div>


          <div className=" tablet768:w-4/12 tablet768:ml-[1rem] "  >
            <div className=" pt-[1rem] flex flex-row tablet768:flex-col " >
              {/* edit */}
              <button className=" tablet768:mr-0 mr-[0.3rem] w-6/12 tablet768:w-full font-semibold flex flex-row justify-between py-[0.7rem] px-[1rem] rounded-[0.3rem] mb-[1rem] hover:bg-marking-grey active:bg-light-grey4 bg-light-grey6 text-almost-black "
                onClick={() => handleEditBtn()} >
                <span className=" font-inter uppercase text-[1.0625rem] desktopsm:text-[1.0625rem] " > edit</span>
                <EditIcon width="22" height="22" stroke="#171718" />
              </button>
              {/* delete bank account */}
              <button className=" tablet768:ml-0 ml-[0.3rem] w-6/12 tablet768:w-full font-semibold flex flex-row justify-between py-[0.7rem] px-[1rem] rounded-[0.3rem] mb-[1rem] hover:bg-marking-grey active:bg-light-grey4 bg-light-grey6 text-red1 "
                onClick={() => handleDeleteBankAccountBtn()} >
                <span className=" font-inter uppercase text-[1.0625rem] desktopsm:text-[1.0625rem] " >delete</span>
                <TrashIcon width="23" height="23" stroke="#FF3131" />
              </button>
            </div>
          </div>

        </div>

      </div>
      {/*  */}
    </div>

    {/*  */}
  </div >
}

export default AccountPaymentDetails;