// 
import React, { useEffect } from "react";
import "./account.css"
import HeaderMenu from "../../components/headerMenu/headerMenu";
import SigninCard from "./component/signinCard";
import SignupCard from "./component/signupCard";
import SmallScreenView from "./component/smallScreen";
import { AUTH_PAGE_IMAGE } from "../../utils/constants/constants";
// import { useAppSelector } from "../../utils/store/redux/hooks";
// import { RootState } from "../../utils/store/redux/store";
// import { localStorage_getItem } from "../../utils/store/localStorage/localStorage";
// import { LS_IMAGES } from "../../utils/constants/constants";

// 

const Account: React.FC = () => {
  // let images = useAppSelector((state: RootState) => state.contentImageSlice);
  // const [image, setImage] = useState<string | null>(null);


  // 
  useEffect(() => {
    // if (images && Object.keys(images).length === 0) {
    //   images = localStorage_getItem(LS_IMAGES);
    //   setImage(images['accountpageimage']);
    // }
  }, []);

  // 
  return <div >
    <div >
      <HeaderMenu screen={'4'} />
    </div>

    {/* for screens less than 992px */}
    <SmallScreenView />

    <div className="  mx-auto hidden  
      tablet:max-w-[59rem] tablet:bg-almost-black tablet:pt-[2rem] tablet:pb-[2rem] tablet:block tablet:text-white tablet:uppercase tablet:px-5 
      desktopsm:max-w-[77rem] 
      desktopmd:max-w-[87rem] 
      desktoplg:max-w-[90rem]
      " >

      <div className=" flex flex-row justify-between" >
        <div
          className=" tablet:w-[19.875rem] desktopsm:w-[29.75rem] desktopmd:w-[39.75rem] flex flex-col" >

          <div className=" text-white tablet:text-[5.875rem] " >Account</div>
          <div className=" hidden relative tablet:flex bg-green-600 tablet:flex-col h-full tablet:justify-center text-center " >
            <img className=" w-full h-full " onClick={() => alert("nothing see")} src={AUTH_PAGE_IMAGE} />
          </div>
          {/* {
            image && image.length > 0 ?
              <div className=" hidden relative tablet:flex bg-green-600 tablet:flex-col h-full tablet:justify-center text-center " >
                <img className=" w-full h-full " onClick={() => alert("nothing see")} src={image} />
              </div>
              : (
                <div className="card hidden relative tablet:flex tablet:flex-col h-full tablet:justify-center text-center w-full bg-slate-800">
                  <div className="image-placeholder h-full text-center w-ful placeholder bg-slate-100"></div>
                </div>
              )
          } */}
        </div>

        <SignupCard />
        <SigninCard />
      </div>

    </div>

  </div>
}

export default Account;