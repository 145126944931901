import { API_BASE_URL } from "../../utils/constants/constants";


export async function CreateOrder(data: any): Promise<any> {
  try {
    if (data && data.user_id && data.total_amount) {

      let _results = null;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "user_id": data.user_id,
        "discount": data.discount,
        "total_amount": data.total_amount
      });

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${API_BASE_URL}/orders/createorder`, requestOptions)
        .then((response) => response.json())
        .then((result) => _results = result)
        .catch((error) => _results = { message: error.message, data: null });

      return _results;
    }
    else {
      return { message: "provide correct data", data: [] }
    }
  }
  catch (err: any) {
    return { message: err.message, data: [] }
  }
}