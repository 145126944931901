import { API_BASE_URL } from "../../utils/constants/constants";



export async function CreateBillingInfo(data: any, order_id: string): Promise<any> {
  try {
    if (data) {
      let _results = null;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const sa2 = data.streetAddress2 ? data.streetAddress2 : data.streetAddress1;

      const raw = JSON.stringify({
        "order_id": order_id,
        "username": data.username,
        "email": data.email,
        "buildingtype": data.buildingType,
        "streetaddress": data.streetAddress1,
        "streetaddress1": sa2,
        "postcode": data.zipCode,
        "suburb": data.suburb,
        "city": data.city,
        "country": "south africa",
        "phonenumber": data.phoneNumber,
        "shipmethod": data.shippingMethod
      });

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${API_BASE_URL}orders/storebillinginformation`, requestOptions)
        .then((response) => response.json())
        .then((result) => _results = result)
        .catch((error) => _results = { message: error.message, data: [] });

      return _results;
    }
    else {
      return { message: "provide correct data", data: [] }
    }
  }
  catch (err: any) {
    return { message: err.message, data: null }
  }
}