import React from "react";
import ShopImages from "./shopImages";
import TextDescription from "./textDesc";


const ShopLeftComponent: React.FC = () => {

  return <div className=" px-[0.7rem] bg-white" >

    <div className=" uppercase font-anton flex flex-col mb-[1rem]" >
      <span className=" text-[3.375rem] tablet768:text-[5.875rem] desktopsm:text-[7.75rem] " >shop</span>
      <span className=" text-[1.3125rem] desktopsm:text-[2.125rem] font-inter font-semibold " >alkaline water</span>
    </div>

    <div className=" tablet:hidden " ><ShopImages /></div>

    <TextDescription />

    <div className=" hidden tablet:block" ><ShopImages /></div>
  </div>
}

export default ShopLeftComponent;