import { API_BASE_URL } from "../../utils/constants/constants";

export async function AdminServiceStoreImage(url: string, name: string, auth: string): Promise<any> {
  try {
    let _results = null;

    if (url && name && auth) {


      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${auth}`);


      const raw = JSON.stringify({
        "name": name,
        "url": url,
      });

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${API_BASE_URL}/administrators/storeimagefile`, requestOptions)
        .then((response) => response.json())
        .then((result) => _results = result)
        .catch((error) => _results = { success: false, message: error.message, data: null });

      return _results;
    }
    else {
      return { success: false, message: "provide correct data", data: null }
    }
  }
  catch (e: any) {
    return { success: false, message: e.message, data: null }
  }
}