import { API_BASE_URL } from "../../utils/constants/constants";

export async function AdminServiceDeleteProduct(id: string, auth: string): Promise<any> {
  try {
    if (id && auth) {

      let _results = null;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${auth}`);

      // body
      const raw = JSON.stringify({
        "id": id
      });

      const requestOptions: RequestInit = {
        method: "DELETE",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${API_BASE_URL}/administrators/deleteproduct`, requestOptions)
        .then((response) => response.json())
        .then((result) => _results = result)
        .catch((error) => _results = {
          message: error.message,
          data: null,
          success: false,
        });

      return _results;
    }
    else {
      return { success: false, message: "incorrect key provided", data: null }
    }
  }
  catch (e: any) {
    return { success: false, message: e.message, data: [] }
  }
}