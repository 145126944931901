// import { BUSINESSEMAIL, LOCATIONADDRESSSTRING, PHONENUMBER } from "../../../utils/constants/constants"
import { SectionComponentProps } from "../components/interfaces_";

const TermsAndConditionsData: SectionComponentProps[] = [
  {
    header: "Introduction",
    texts: [
      "Welcome to Solid Water, a registered company with the Companies and Intellectual Property Commission (CIPC) in South Africa. These Terms and Conditions govern your use of our website and services. By accessing and using our website, you agree to comply with and be bound by these Terms and Conditions."
    ]
  },
  // {
  //   header: "Company Information",
  //   texts: [
  //     "Company Name: Solid Water",
  //     // "Company Registration Number: [Insert CIPC Registration Number]",
  //     `${LOCATIONADDRESSSTRING}`,
  //     `${BUSINESSEMAIL}`,
  //     `<a href="tel:${PHONENUMBER}"></a>`
  //   ]
  // },
  {
    header: "Acceptance of Terms",
    texts: [
      "By using our website and services, you agree to these Terms and Conditions. If you do not agree, please do not use our website."
    ]
  },
  {
    header: "Products and Services",
    texts: [
      "Products: We offer bottled water in the following sizes: 500ml, 1 liter, and 5 liters.",
      "Description: All product descriptions, prices, and availability are subject to change without notice."
    ]
  },
  {
    header: "Ordering Process",
    texts: [
      "How to Order: Orders can be placed through our website. You must provide accurate and complete information to process your order.",
      "Payment Terms: Payments can be made via [insert accepted payment methods]. Full payment must be received before delivery."
    ]
  },
  {
    header: "Delivery and Shipping",
    texts: [
      "Delivery Areas: We offer delivery services throughout South Africa. Delivery rates vary based on the delivery area.",
      "Shipping Costs: Delivery charges will be calculated based on the client's location and added to the total order amount.",
      "Delivery Times: Estimated delivery times will be provided upon order confirmation. We are not responsible for delays caused by factors beyond our control."
    ]
  },
  {
    header: "Returns and Refunds",
    texts: [
      "Policy: We accept returns for damaged or incorrect items. Returns must be requested within [insert number of days, e.g., 7 days] of receipt.",
      "Process: To request a return, please contact our customer service at [insert contact email]. The product must be returned in its original condition.",
      "Refunds: Refunds will be processed to the original payment method within [insert number of days, e.g., 14 days] after we receive the returned product."
    ]
  },
  {
    header: "Customer Responsibilities",
    texts: [
      "Accuracy of Information: You are responsible for providing accurate delivery information. We are not liable for delays or errors resulting from incorrect information provided by you.",
      "Use of Website: You agree not to use the website for any unlawful purposes or to interfere with its functionality."
    ]
  },
  {
    header: "Intellectual Property",
    texts: [
      "Ownership: All content on our website, including text, images, and logos, is the property of Solid Water and is protected by intellectual property laws. You may not use any content from the website without our prior written consent."
    ]
  },
  {
    header: "Limitation of Liability",
    texts: [
      "Disclaimer: To the fullest extent permitted by law, Solid Water is not liable for any indirect, incidental, or consequential damages arising from the use of our website or products. Our liability is limited to the amount paid for the products in question."
    ]
  },
  {
    header: "Privacy Policy",
    texts: [
      "Data Protection: We collect, use, and protect your personal data in accordance with the Protection of Personal Information Act (POPIA). For details, please refer to our Privacy Policy [insert link]."
    ]
  },
  {
    header: "Governing Law",
    texts: [
      "Jurisdiction: These Terms and Conditions are governed by the laws of South Africa. Any disputes arising from these Terms will be subject to the exclusive jurisdiction of the South African courts."
    ]
  },
  {
    header: "Changes to Terms",
    texts: [
      "Modification: We reserve the right to update or modify these Terms and Conditions at any time. Changes will be effective immediately upon posting on our website. Your continued use of the website after any changes indicates your acceptance of the new terms."
    ]
  },
  // {
  //   header: "Contact Information",
  //   texts: [
  //     `Email: ${BUSINESSEMAIL}`,
  //     `Phone: ${PHONENUMBER}`
  //   ]
  // }
];

export default TermsAndConditionsData;