import React, { useEffect } from "react";
import { SP1, SP2, SP3 } from "../../../utils/constants/constants";

const IMGS = [SP1, SP2, SP3];

// 
const ShopImages: React.FC = () => {

  useEffect(() => { }, []);

  return <div className=" flex 
    tablet:mt-[1rem] tablet:mb-[2rem] tablet:w-full tablet:overscroll-x-none flex-nowrap overflow-x-auto scrollbar-hide
    tablet:flex tablet:flex-col
  " >
    {
      IMGS && IMGS.map((image, idx) => (
        <img key={idx}
          className=" mb-[1rem] min-w-[15rem] min-h-[21.25rem] mr-[0.7rem]
             mobile:min-w-[18rem] mobile:h-[26.25rem]
             tablet768:w-[18rem]
             tablet:w-full tablet:h-auto tablet:max-h-screen tablet:mb-[1rem] tablet:mr-0 tablet:object-cover
            "
          alt="products"
          src={image} />
      ))
    }
  </div>
}

export default ShopImages;

// desktopsm:w-[32.75rem] desktopsm:h-[43.75rem]
// desktopmd:w-[42.75rem] desktopmd:h-[50rem]
// desktoplg:w-[45.75rem]