// FREQUENTLY ASKED QUESTIONS COMPONENTS
// 
import React, { useEffect, useState } from "react"
import { SEARCHDATA, SearchDataInterface } from "../data";
import { ReactComponent as ArrowDown } from "../../../utils/svg/arrow_down.svg";
import { ReactComponent as SearchIcon } from "../../../utils/svg/search_icon.svg";



const FrequentlyAskedQuestion: React.FC = () => {
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const [showSelectedAnswer, setShowSelectedAnswer] = useState<boolean | null>(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [faqData, setFaqData] = useState<SearchDataInterface[] | null>(null);

  const showAnswer = (ans: string | null, idx: number | null) => {
    if (ans?.toLowerCase() === selectedAnswer?.toLowerCase()) {
      setShowSelectedAnswer(false);
      setSelectedAnswer(null);
      setSelectedIdx(null);
    }
    else {
      setShowSelectedAnswer(true);
      setSelectedAnswer(ans);
      setSelectedIdx(idx);
    }
  }


  function removeDuplicates(data: SearchDataInterface[]): SearchDataInterface[] {
    const seenQuestions = new Set<string>();
    const uniqueData: SearchDataInterface[] = [];

    for (const item of data) {
      if (!seenQuestions.has(item.question)) {
        seenQuestions.add(item.question);
        uniqueData.push(item);
      }
    }

    return uniqueData;
  }

  const search = () => {
    if (searchTerm && searchTerm.length) {
      let results: SearchDataInterface[] = [];

      // get terms
      const terms = searchTerm.toLowerCase().trim().split(" ");
      // loop over each term
      terms.forEach(term => {
        const found: SearchDataInterface[] | any[] = SEARCHDATA.filter(
          (item) => item.question.toLowerCase().includes(
            term.toLowerCase()));
        // add the found item to the arrays
        if (found.length > 0) {
          results = results.concat(found);
        }
      });
      // 
      // remove duplicate objects
      const searchedResults: SearchDataInterface[] = removeDuplicates(results);
      setFaqData(searchedResults);
    }
  }

  useEffect(() => {
    setFaqData(SEARCHDATA);
    // when the input field has no data, display all data
    if (searchTerm === null || searchTerm.length === 0) {
      setFaqData(SEARCHDATA);
    }

  }, [searchTerm])

  return <div id="#faq" >
    <form onSubmit={(event) => { event.preventDefault(); search() }} className="bg-light-grey pl-[0.7rem]  flex flex-row items-center " >
      <div
        className=" p-[0.3rem] rounded-sm active:bg-violet-700 hover:bg-very-dark-grey" >
        <SearchIcon className="cursor-pointer " onClick={() => search()} />
      </div>
      <input
        placeholder="Search"
        onChange={(e) => setSearchTerm(e.target.value)}
        className=" uppercase py-[0.9rem] focus:outline-none ml-[0.5rem] bg-light-grey text-white placeholder-white text-[1.5rem] w-full " />
    </form>

    <div className=" mt-[1.5rem] flex flex-row flex-wrap justify-between" >
      {
        faqData && faqData.map((item, idx) => (
          // frequentlyAskQuestionStyle
          <div key={idx} className="  tablet:w-[49%] w-full " >
            <button

              onClick={() => showAnswer(item.answer, idx)} className=" active:bg-light-grey cursor-pointer  py-[0.5rem] w-full mb-[1rem] flex flex-row px-[0.65rem] justify-between items-center font-bold text-[1.1875rem] tablet:text-[1.5rem] bg-card-grey hover:bg-light-grey2 hover:text-card-grey " >
              <span className=" w-11/12 text-left uppercase" > {item.question} </span>
              <ArrowDown className="" />
            </button>

            {showSelectedAnswer === true && selectedIdx === idx ?
              <div className=" bg-light-grey2 text-card-grey font-bold mx-[1rem] p-[0.5rem] mb-[1rem] " >{selectedAnswer}</div> : ""}
          </div>
        ))
      }
    </div>
  </div>

}


export default FrequentlyAskedQuestion;