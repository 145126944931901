import React from "react";
import Quantity1Icon from "../../../utils/svg/quantity1Icon";
import Quantity2Icon from "../../../utils/svg/quantity2Icon";
import Quantity4Icon from "../../../utils/svg/quantity4Icon";
import Quantity6Icon from "../../../utils/svg/quantity6Icon";
import Quantity12Icon from "../../../utils/svg/quantity12Icon";
import Quantity24Icon from "../../../utils/svg/quantity24Icon";

interface DottedQuantityProps {
  quantity: number;
  stroke: string;
}

const DottedQuantity: React.FC<DottedQuantityProps> = ({ quantity, stroke }) => {

  const _style = " w-full text-center flex justify-center "

  if (quantity === 2) {
    return <div className={_style} >
      <Quantity2Icon width="48" height="48" stroke={stroke} />
    </div>
  }
  else if (quantity === 4) {
    return <div >
      <Quantity4Icon width="48" height="48" stroke={stroke} />
    </div>
  }
  else if (quantity === 6) {
    return <div >
      <Quantity6Icon width="48" height="48" stroke={stroke} />
    </div>
  }
  else if (quantity === 12) {
    return <div >
      <Quantity12Icon width="48" height="48" stroke={stroke} />
    </div>
  }
  else if (quantity === 24) {
    return <div >
      <Quantity24Icon width="48" height="48" stroke={stroke} />
    </div>
  }
  else {
    return <div className={_style} >
      <Quantity1Icon width="48" height="48" stroke={stroke} />
    </div>
  }

}


export default DottedQuantity;