// use this type when you wants to change color of the SVG

import React from 'react';

interface StarLikeIconProps {
  stroke: string;
  width: string;
  height: string;
}

const StarLikeIcon: React.FC<StarLikeIconProps> = ({ stroke, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.7012 0.946289H28.1012L25.6893 20.2414L41.2004 8.4998L45.4004 15.7744L27.4751 23.3371L45.4003 30.8998L41.2003 38.1744L25.6868 26.431L28.1012 45.7463H19.7012L22.1155 26.4313L6.60245 38.1744L2.40245 30.8998L20.3276 23.3371L2.40234 15.7744L6.60234 8.4998L22.113 20.2411L19.7012 0.946289Z" fill={stroke} />
    </svg>


  );
}

// 47, 232526
export default StarLikeIcon;
