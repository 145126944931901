import React from "react";

const BrowserRouterErrorPage: React.FC = () => {

  return <div className="bg-pink-like h-screen flex flex-col justify-center text-center text-2xl text-black" >
    Url Not Found
  </div>
}


export default BrowserRouterErrorPage;