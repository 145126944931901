import React, { useEffect } from "react"
import FooterBottomPart from "../../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import ArrowUpperLeft from "../../../utils/svg/arrowUpperLeft";
import LockIcon from "../../../utils/svg/lockIcon";
import MUIOtpForm from "../component/muiOtpForm";
import ChangePasswordForm from '../component/changePasswordForm';
import { AccountScreenRouterName } from "../../../utils/routers/routersNames";


const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();

  function handleGoBack() {
    navigate(AccountScreenRouterName);
  }


  useEffect(() => {
  }, [])


  function BackBtn() {
    return <div className=" px-[0.7rem] tablet:w-[60%] desktopsm:w-[65%] " >
      <button onClick={() => handleGoBack()}
        className=" bg-light-grey5 text-[1.3125rem] mobilels:justify-between px-[1rem] flex justify-center w-full desktopsm:text-[1.5rem] tablet:py[0rem] py-[0.7rem] " >
        <ArrowUpperLeft width="35" height="35" stroke={"#232526"} />
        <span className="underline ml-[0.2rem] font-inter font-bold uppercase" >
          Go Back To Sign In
        </span>
      </button>
    </div>
  }


  return <div className=" bg-light-grey6 " >

    <div className=" mx-auto desktopsm:w-[66.25rem] tablet:w-[56.125rem] bg-light-grey6 pt-[1.5rem] pb-[1rem] tablet:pt-[0.5rem] tablet:flex target:flex-row" >

      <div className=" tablet:w-7/12 " >
        <div className="tablet:hidden" >
          <BackBtn />
        </div>

        <div className=" px-[0.7rem] py-[2rem] " >
          <div className=" font-anton uppercase text-[3.375rem] desktopsm:text-[7.75rem] tablet:text-8xl " >Forgot your password?</div>
          <div className="  font-inter font-bold uppercase tablet:text-[2.125rem] text-[1.3125rem] text-card-grey "  >
            let's create a new one
          </div>
        </div>

        <div className=" mx-[0.7rem] bg-light-grey5 flex justify-center py-[3rem] " >
          <LockIcon stroke={"#171718"} width={"90"} height={"116"} />
        </div>

        <div className=" hidden tablet:block tablet:mt-[1.5rem] " >
          <BackBtn />
        </div>
      </div>

      <div className=" tablet:w-5/12 tablet:py-[2rem] bg-white mx-[0.7rem] my-[2rem] pb-[2rem] pt-[1rem] px-[0.5rem] " >
        <div className=" font-anton text-[2.25rem] uppercase " >what to do</div>
        <div className=" font-inter text-[1.0625rem] " >
          We’ve sent you a One-Time-Pin (OTP) via email. Enter it here to complete your password change.
        </div>

        <div className=" mt-[2.5rem] " >
          <MUIOtpForm />
        </div>
        {/*  */}

        <div className=" mt-[1rem]" >
          <ChangePasswordForm />
        </div>
      </div>
    </div>

    {/*  */}
    <FooterBottomPart />
  </div >
}


export default ForgotPassword;
