import React from 'react';
import { Link } from 'react-router-dom';

interface PhoneLinkProps {
  phoneNumber: string,
  children: any | null,
  cssStyle: string,
}

const PhoneLink: React.FC<PhoneLinkProps> = ({ phoneNumber, children, cssStyle }) => {

  const telLink = `tel:${phoneNumber}`;

  return (
    <Link className={cssStyle} target="_blank" to={telLink}>
      {children}
    </Link>
  );
};

export default PhoneLink;
