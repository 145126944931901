// use this type when you wants to change color of the SVG
import React from 'react';


interface SignOutIconProps {
  stroke: string;
  width: string;
  height: string;
}

const SignOutIcon: React.FC<SignOutIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.0001 13C14.0001 15.7614 11.7615 18 9.00008 18C6.23866 18 4.00008 15.7614 4.00008 13C4.00008 10.2386 6.23866 8 9.00008 8C9.33141 8 9.65522 8.03223 9.96853 8.09372L11.5739 6.48835C10.7772 6.17318 9.90887 6 9.00008 6C5.13409 6 2.00008 9.13401 2.00008 13C2.00008 16.866 5.13409 20 9.00008 20C12.8661 20 16.0001 16.866 16.0001 13C16.0001 11.8319 15.714 10.7306 15.208 9.76237L13.6948 11.2756C13.8923 11.8131 14.0001 12.394 14.0001 13Z" fill={stroke} />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.61617 1.99662L9.61617 3.99662L16.1015 3.99662L7.61617 12.4819L9.03038 13.8961L17.5157 5.41084L17.5157 11.8961L19.5157 11.8961L19.5157 1.99662L9.61617 1.99662Z" fill={stroke} />
  </svg>
}

export default SignOutIcon;
