import React from "react";
import ArrowTopRight from "../../../utils/svg/arrowTopRight";
// use this when svg is only used once in the entire app
// import { ReactComponent as ArrowTopRight } from "../../../utils/svg/arrow_top_right.svg"
import { Link } from 'react-router-dom';

interface ContactUsTypeProps {
  idx: number;
  contactType: string;
  contactText: string;
  contactIcon: React.ReactNode,
  rel: string;
}



const ContactUsType: React.FC<ContactUsTypeProps> = ({ idx, contactType, contactText, contactIcon, rel }) => {

  // 
  return <div key={idx}
    className={` desktopsm:flex-col 
    
    w-full cursor-pointer bg-secondary-grey hover:bg-light-grey2 mt-[1rem] flex flex-row-reverse justify-between py-[1.2rem] px-3`} >
    {/*  */}
    <div className=" 
      desktopsm:mb-[8rem]
      cursor-pointer text-end flex justify-end text-[1rem] " >
      <ArrowTopRight height="31.31" width="32" stroke={'#232526'} />
    </div>

    <div className=" 
      flex flex-row items-center 
      desktopsm:flex-col desktopsm:items-start
    
    " >
      <Link to={rel} >
        {contactIcon}
      </Link>

      <div className=" flex flex-col ml-[1rem] desktopsm:flex-col desktopsm:ml-[0rem] " >
        <span className=" font-semibold text-[1.5rem] tracking-normal ">
          {contactType}
        </span>
        {/* text-[1.3125rem] */}
        <Link
          to={rel}
          className=" text-[0.9375rem] hover:underline "
          target={"_blank"} >{contactText}</Link>
      </div>
    </div>
  </div>
}

export default ContactUsType;