import { SectionComponentProps } from "../components/interfaces_";


const PrivacyAndPolicyData: SectionComponentProps[] = [
  {
    header: "Introduction",
    texts: [
      "Welcome to Solid Water, a registered company with the Companies and Intellectual Property Commission (CIPC) in South Africa. These Terms and Conditions govern your use of our website and services. By accessing and using our website, you agree to comply with and be bound by these Terms and Conditions."
    ]
  },
  {
    header: "Information We Collect",
    texts: [
      "Personal Information: When you place an order or contact us, we may collect personal information such as your name, email address, phone number, and delivery address.",
      "Payment Information: We collect payment details necessary to process transactions, such as credit card information, but we do not store payment details ourselves. These are handled securely by our payment processing partners.",
      "Usage Data: We may collect information about your interaction with our website, including IP address, browser type, pages visited, and the date and time of your visit."
    ]
  },
  {
    header: "How We Use Your Information",
    texts: [
      "Order Processing: To process and fulfill your orders, including managing delivery and handling any related inquiries.",
      "Customer Service: To respond to your questions, comments, and support requests.",
      "Communication: To send you information about our products, services, promotions, and updates, if you have opted in to receive such communications.",
      "Improvement: To analyze and improve our website and services, based on your usage and feedback."
    ]
  },
  {
    header: "Sharing Your Information",
    texts: [
      "Service Providers: We may share your information with third-party service providers who assist us in operating our website, processing payments, and delivering products. These providers are contractually obligated to protect your information and use it only for the services they provide to us.",
      "Legal Requirements: We may disclose your information if required by law or to comply with legal processes or enforceable government requests."
    ]
  },
  {
    header: "Data Security",
    texts: [
      "We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. Despite our efforts, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security."
    ]
  },
  {
    header: "Cookies and Tracking Technologies",
    texts: [
      "We use cookies and similar tracking technologies to enhance your browsing experience, analyze website usage, and personalize content. You can control and manage cookies through your browser settings. However, disabling cookies may affect the functionality of our website."
    ]
  },
  {
    header: "Your Rights",
    texts: [
      "Access: You have the right to request access to the personal information we hold about you.",
      "Correction: You may request correction of any inaccurate or incomplete information.",
      "Deletion: You can request the deletion of your personal information, subject to any legal or contractual obligations we may have.",
      "Objections: You may object to the processing of your data under certain circumstances."
    ]
  },
  {
    header: "Third-Party Links",
    texts: [
      "Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review their privacy policies before providing any personal information."
    ]
  },
  {
    header: "Changes to This Privacy Policy",
    texts: [
      "We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any changes will be posted on our website with the revised date. We encourage you to review this Privacy Policy periodically."
    ]
  }
];

export default PrivacyAndPolicyData;
