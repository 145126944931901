import { useEffect, useState } from "react";
import Product from "./product";
import { useAppSelector } from "../../../utils/store/redux/hooks";
import { RootState } from "../../../utils/store/redux/store";
import { CART_ITEM } from "../../../utils/interfaces/item";


interface ProductTypeProps {
  name: string;
  totalCartAmount: number;
  set_totalCartAmount: (v: number) => void;
}

const ProductType: React.FC<ProductTypeProps> = ({ name, set_totalCartAmount, totalCartAmount }) => {
  const cartItemSelector = useAppSelector((state: RootState) => state.cartItemsSlice);
  const [numberBottles, setNumberBottles] = useState<number>(0);
  const [subTotalAmount, setSubTotalAmount] = useState<number>(0);


  useEffect(() => {
    // number of bottles
    const nb_ = cartItemSelector.filter((i) => i.name === name).reduce((sum: number, prod: CART_ITEM) => sum += prod.quantity, 0);
    setNumberBottles(nb_);
    // subtotal
    const sta = cartItemSelector.filter((i) => i.name === name).reduce((sum: number, prod: CART_ITEM) => sum += prod.total, 0);
    setSubTotalAmount(sta);
    // 
  }, []);

  return <div className="mb-[2rem] " >
    <div className=" tablet:text-[1.5rem] text-[1.1875rem] font-inter py-[0.6rem] font-semibold border-b-2 uppercase text-white" >
      {name} bottles
    </div>

    {
      cartItemSelector &&
      cartItemSelector.length > 0 &&
      cartItemSelector.filter((i: CART_ITEM) => i.name === name).map((prod: CART_ITEM, idx: number) => <div key={idx} >
        <Product
          product={prod}
          index={idx}
          setNumberBottles={setNumberBottles}
          numberBottles={numberBottles}
          setSubTotalAmount={setSubTotalAmount}
          subTotalAmount={subTotalAmount}
          totalCartAmount={totalCartAmount} set_totalCartAmount={set_totalCartAmount}
        />
      </div>
      )
    }

    <div className="border-b-[0.09rem] font-inter uppercase border-grey-like2 tablet:text-[0.9375rem] text-[0.875rem] text-marking-grey  py-[1rem] flex flex-row justify-between" >
      <div className="  " >
        = <span className=" text-white text-[0.9375rem] tablet:text-[1.1875rem] " >{numberBottles}</span> bottles
      </div>
      <div className="" >
        sub total = <span className=" tablet:text-[1.5rem] text-white text-[0.9375rem] " >R{subTotalAmount}</span>.{0}
      </div>
    </div>
  </div>
}


export default ProductType;