import React, { useState } from 'react';
import { useAppDispatch } from '../../../utils/store/redux/hooks';
import { assignBillingAddress } from '../../../utils/store/redux/slices/billingAddress';



interface MobileBuildingTypeSelectProps {
  setter_func: (v: any) => void;
  formdata: any;
}


const MobileBuildingTypeSelect: React.FC<MobileBuildingTypeSelectProps> = ({ setter_func, formdata }) => {
  const [selectedItem, setSelectedItem] = useState('');
  const dispatch = useAppDispatch();

  const handleItemClick = (item: any) => {
    setSelectedItem(item);
    setter_func({ ...formdata, buildingType: item.toLowerCase() });
    // redux
    dispatch(assignBillingAddress({ buildingType: item.toLowerCase() }));

  };


  // ["residential", "PO BOX", "office", "store", "other"]
  return (
    <div className="flex items-center w-full ">
      <select
        className="block bg-very-dark-grey w-full font-inter uppercase px-[0.8rem] py-[1rem] font-semibold appearance-none text-white border border-gray-400 hover:border-gray-500 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        value={selectedItem}
        onChange={(e) => handleItemClick(e.target.value)}
      >
        <option className=' uppercase' value="">CHOOSE</option>
        <option className=' uppercase' value="RESIDENTIAL">RESIDENTIAL</option>
        <option value="PO BOX" >PO BOX</option>
        <option value="OFFICE" >OFFICE</option>
        <option value="STORE" >STORE</option>
        <option value="OTHER" >OTHER</option>
      </select>
    </div>
  );
};

export default MobileBuildingTypeSelect;
