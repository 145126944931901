
// 
import React, { useEffect } from "react";
import HeaderMenu from "../../components/headerMenu/headerMenu";
import PrivacyAndPolicyData from "./data/pp";
import { SectionComponentProps } from "./components/interfaces_";
import SectionComponent from "./components/section";
import FooterBottomPart from "../../components/footer/footer";
import ScrollToTopRoutine from "../../utils/routines/scrollToTopRoutine";


const PolicyPrivacy: React.FC = () => {

  useEffect(() => {
    ScrollToTopRoutine();
  }, []);

  return <div className=" text-white font-inter flex flex-col" >

    <HeaderMenu screen={'3'} />

    <div className="  mb-[5rem] leading-none max-w-[18.5rem] mx-auto 
    mobile:max-w-[22rem] 
    mobilels:max-w-[27rem] 
    tablet768:max-w-[42.5rem] 
    tablet:max-w-[56.5rem] 
    desktopsm:max-w-[74.5rem] 
    desktopmd:max-w-[84.5rem]" >

      {/*  */}
      <div className=" text-5xl pt-[1rem] pb-[2rem] " >
        Privacy Policy
      </div>

      <div className=" pt-[2rem] " >
        {PrivacyAndPolicyData && PrivacyAndPolicyData.map((section: SectionComponentProps, idx: number) => (
          <div key={idx} >
            <SectionComponent header={section.header} texts={section.texts} />
          </div>
        ))}
      </div>

    </div>

    {/* footer */}
    <FooterBottomPart />
  </div>

}

export default PolicyPrivacy;