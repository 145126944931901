import { useState } from "react";
import MinusIcon from "../../../utils/svg/minusIcon";
import PlusIcon from "../../../utils/svg/plusIcon";
import { CART_ITEM } from "../../../utils/interfaces/item";
import { useAppDispatch, useAppSelector } from '../../../utils/store/redux/hooks';
import { updateCartItem } from "../../../utils/store/redux/slices/cartSlice";
import { RootState } from "../../../utils/store/redux/store";


interface ProductProps {
  product: CART_ITEM;
  index: number;
  setNumberBottles: (v: number) => void;
  numberBottles: number;
  setSubTotalAmount: (v: number) => void;
  subTotalAmount: number;
  totalCartAmount: number;
  set_totalCartAmount: (v: number) => void;
}

const Product: React.FC<ProductProps> = ({ product, index, setNumberBottles, setSubTotalAmount, numberBottles, subTotalAmount, set_totalCartAmount, totalCartAmount }) => {
  //
  const cartItemSelector = useAppSelector((state: RootState) => state.cartItemsSlice);
  const [quantity, setQuantity] = useState<number>(product.quantity);
  const dispatch = useAppDispatch();

  // 
  function handleChangeQuantity(quantChangeVal: number) {
    // make sure to not go negative
    if (quantity === 0 && quantChangeVal === -1) return;
    // make sure to not exceed what available in store
    if (quantity == product.available && quantChangeVal === 1) {
      alert("maximun available product in store");
      return;
    }

    // otherwise perform codes function
    const new_quantity = quantity + quantChangeVal;
    // new total for this product
    const total = product.price * new_quantity;
    // update in redux
    dispatch(updateCartItem({ id: product.id, quantity: new_quantity, total: total }));
    // update number of bottles.
    setNumberBottles(numberBottles + quantChangeVal);
    // update subtotal price.
    setSubTotalAmount(subTotalAmount + (product.price * quantChangeVal));
    // update total amount for the entire cart
    set_totalCartAmount(totalCartAmount + ((product.price * quantChangeVal)));
    // update state.
    setQuantity(new_quantity);
  }

  return <div key={index} className="flex flex-row items-center font-inter justify-between border-b-[0.09rem] border-grey-like2 py-[1rem]">
    {/*  */}
    <div className=" flex flex-row items-center" >
      <div className=" p-[2rem] rounded-[0.6rem] bg-grey-like2 " ></div>
      <div className="  flex flex-col pl-[0.5rem]" >
        <span className=" text-white tablet:text-[1.1875rem] text-[1.0625rem] " >{product.pack} Pack</span>
        <span className=" tablet:text-[0.9375rem] text-[0.75rem] text-marking-grey " >{quantity} x R{product.price} = R{quantity * product.price}</span>
      </div>
    </div>

    {/* buttons */}
    <div className=" flex flex-row items-center " >
      <button onClick={() => handleChangeQuantity(-1)}
        className=" bg-card-grey px-[0.7rem] hover:bg-faint-grey active:bg-faint-grey py-[0.6rem] rounded-[0.2rem] text-[1rem]  " >
        <MinusIcon width="12" height="20" stroke="#fff" />
      </button>

      <span className=" px-[0.5rem] tablet:text-[1.3125rem] w-[2rem] text-[1rem] font-bold text-white " >{quantity}</span>

      <button onClick={() => handleChangeQuantity(1)}
        className=" bg-card-grey px-[0.7rem] hover:bg-faint-grey active:bg-faint-grey py-[0.6rem] rounded-[0.2rem] text-[1rem]  " >
        <PlusIcon width="18" height="20" stroke="#fff" />
      </button>
      {/*  */}
    </div>
  </div>
}


export default Product;