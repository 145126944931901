import React, { useEffect, useState } from "react";
import ArrowRightIcon from "../../../utils/svg/arrowRigtIcon";
import { useAppDispatch, useAppSelector } from "../../../utils/store/redux/hooks";
import { RootState } from "../../../utils/store/redux/store";
import { YocoPayCreateTransaction } from "../../../services/payments/yoco/yoco";
import { service_charge_user } from "../../../services/payments/yoco/charge";
import Loading from "../../../components/loading";
import { useNavigate } from "react-router-dom";
import { AccountScreenRouterName, ShopScreenRouterName } from "../../../utils/routers/routersNames";
import { validateBillingAddressForm } from "../routines/validations";
import { RUSH_SHIPPING_COST } from "../../../utils/constants/constants";
import ShippingMethodForm from "./shippingMethod";
import { extractPaymentData, extractTransactionData } from "../routines/extractData";
import { CreateOrder } from "../../../services/order/createOrder";
import { CreateBillingInfo } from "../../../services/order/createBillingInfo";
import { CreateInitialTransaction } from "../../../services/order/createInitialTransaction";
import { CreateOrderedProducts } from "../../../services/order/createOrderedProucts";
import { CreateOrderPayment } from "../../../services/order/createOrderPayment";
import { clearCartItems } from "../../../utils/store/redux/slices/cartSlice";

// 
const PaymentYoco: React.FC = () => {
  // redux 
  const userProfile = useAppSelector((state: RootState) => state.userProfile);
  const cartItems = useAppSelector((state: RootState) => state.cartItemsSlice);
  const billingAddressInfo = useAppSelector((state: RootState) => state.billingAddressSlice);
  const amountToPay: number = cartItems.reduce((sum: number, obj: any) => sum += obj.total, 0);
  const [shippingAmount, setShippingAmount] = useState<number>(0);
  const dispatch = useAppDispatch();
  // 
  const [loadingStatus, setLoadingStatus] = useState<any>({ isloading: false, message: "" })
  const navigate = useNavigate();
  // 
  async function myCallBackFunc(data: any) {
    // 
    setLoadingStatus({ ...loadingStatus, isLoading: true, message: "creating transaction" });
    if (data && data.id) {
      if (userProfile && userProfile.id) {
        // check if the charge transaction is created and ready
        if (data && data?.status === 'charge_ready') {
          const userID = userProfile.id;
          setLoadingStatus({ ...loadingStatus, message: "processing payment" });
          const chargingResponse = await service_charge_user(data.id, amountToPay, userID);
          // when the user was charged
          if (chargingResponse && chargingResponse.status == true && chargingResponse.message === 'successful') {
            const create_transaction_data = extractTransactionData(data);
            // 
            if (chargingResponse && chargingResponse.status === true) {
              const chargingData = extractPaymentData(chargingResponse);
              // 
              // create order in the database
              setLoadingStatus({ ...loadingStatus, message: "storing data" });
              const orderData = { user_id: userID, discount: 0, total_amount: amountToPay + shippingAmount };
              const createOrderRes = await CreateOrder(orderData);
              if (createOrderRes && createOrderRes.id) {
                const order_id = createOrderRes.id;
                // create billing paymentInformationSlice
                const createBillInfo = await CreateBillingInfo(billingAddressInfo, order_id);
                // store ordered products
                setLoadingStatus({ ...loadingStatus, message: "creating order" });
                const createOrdersProdsRes = await CreateOrderedProducts(cartItems, order_id)
                // store initial transactions information
                const createTransactionRes = await CreateInitialTransaction(create_transaction_data);
                if (createTransactionRes && createTransactionRes.id) {
                  // store payment details
                  const trans_id: string = createTransactionRes.id;
                  const orderMadeRes = await CreateOrderPayment(chargingData, order_id, trans_id, cartItems);
                  if (orderMadeRes && orderMadeRes.success === true) {
                    setLoadingStatus({ ...loadingStatus, isLoading: false, message: "" });
                    navigate(ShopScreenRouterName);
                    // clear the cart
                    dispatch(clearCartItems());
                    alert(`${chargingResponse.message}. Order Placed`);
                  }
                  else {
                    setLoadingStatus({ ...loadingStatus, isLoading: false, message: "" });
                    alert(`${chargingResponse.message}. Order Placed`);
                  }
                }
                else {
                  alert(`${createTransactionRes.message}`)
                  setLoadingStatus({ ...loadingStatus, isLoading: false, message: "" });
                }
              }
              else {
                setLoadingStatus({ ...loadingStatus, isLoading: false, message: "" });
                alert(`${createOrderRes.message}`);
              }
            }
            else {
              alert(`${chargingResponse.message}`)
              setLoadingStatus({ ...loadingStatus, isLoading: false, message: "" });
            }
          }
          else {
            alert(`${chargingResponse.message}`);
          }
        }
        else {
          alert(`unable to charge account : ${data?.status}`);
        }
      }
      // user is not loggedin
      else { navigate(AccountScreenRouterName); }
    }
    else {
      setLoadingStatus({ ...loadingStatus, isLoading: false, message: "" });
    }
  }

  async function handleFormOnSubmit() {
    try {
      if (amountToPay) {
        // validate billing form
        const isValidBillingInfo: string | boolean = validateBillingAddressForm(billingAddressInfo);
        // 
        if (isValidBillingInfo === true) {
          // add amount for shipping type
          let amountToPayShipping = amountToPay;
          if (billingAddressInfo.shippingMethod && billingAddressInfo.shippingMethod === 'rush') {
            amountToPayShipping += RUSH_SHIPPING_COST;
          }
          setLoadingStatus({ ...loadingStatus, isLoading: true, message: "creating order" });
          await YocoPayCreateTransaction(amountToPayShipping, myCallBackFunc);
          setLoadingStatus({ ...loadingStatus, isLoading: false, message: "" });

        }
        // when form is not complete
        else { alert(`${isValidBillingInfo}`); }
      }
    }
    catch (err: any) {
      // log app activities
    }
  }
  // 
  // 
  // 
  useEffect(() => { }, []);

  // 
  return <div >
    <ShippingMethodForm setShippingAmount={setShippingAmount} />

    <div className=" px-[0.8rem] py-[1rem] tablet:my-[2rem] bg-white" >
      <div className=" text-almost-black text-[3.375rem] mb-[1rem] font-anton uppercase " >
        payment
      </div>

      {/* submit button */}
      <button onClick={() => handleFormOnSubmit()}
        className=" mt-[2rem] rounded-[0.4rem] bg-almost-black flex font-inter uppercase w-full text-[1.1875rem] text-white flexrow justify-between items-center px-[1rem] py-[0.7rem]" >
        <span className="" > proceed to pay R{amountToPay + shippingAmount} </span>
        <ArrowRightIcon width="21" height="22" stroke="#fff" />
      </button>

      {/* for loading */}
      {loadingStatus.isLoading && <Loading message={loadingStatus.message} />}

    </div >
  </div>

}

export default PaymentYoco;