import React, { useEffect, useState } from "react";
import { googleBtnStyle, } from "../constants";
import { useGoogleLogin } from "@react-oauth/google";
import { servive_google_auth_login } from "../../../services/google";
import { ReactComponent as GoogleIcon } from "../../../utils/svg/google_icon.svg"
import { Service_RegisterUser } from "../../../services/auth/register";
import { setUserProfile } from "../../../utils/store/redux/slices/userProfile";
import { useAppDispatch, useAppSelector } from "../../../utils/store/redux/hooks";
import { removeSpecialChars } from "../../../utils/routines/helps";
import Loading from "../../../components/loading";
import { RootState } from "../../../utils/store/redux/store";
import { CheckOutScreenRouterName, ShopScreenRouterName } from "../../../utils/routers/routersNames";
import { useNavigate } from "react-router-dom";

const GoogleAuthBtn: React.FC = () => {
  // redux
  const activitySlice = useAppSelector((state: RootState) => state.activity);

  const [googleUser, setGoogleUser] = useState<any | null>(null);
  const [loadingStatus, setLoadingStatus] = useState({ isLoading: false, message: "" });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  async function auth_customer(res: any) {
    try {
      const { email, given_name, name, id } = res;
      const e_name = removeSpecialChars(email.slice(0, email.indexOf("@")));
      const username = given_name ? given_name : name ? name : e_name
      const userdata = { name: username, email: email, thirdpartyid: id, isthirdparty: 1, phone: "" }
      // 
      const response = await Service_RegisterUser(userdata);
      dispatch(setUserProfile(response.data)); // set user in SM
      if (response.message != 'success') { alert(`${response.message}`); }
      setLoadingStatus({ ...loadingStatus, isLoading: false, message: "" });
      // 
      // make user go back to shopping screen if user was shopping, 
      // otherwise make user go to shop page
      if (activitySlice.wasShopping === true) { navigate(CheckOutScreenRouterName); }
      else { navigate(ShopScreenRouterName); }
      // 
    }
    catch (e: any) {
      alert(`${e.message}`)
    }
  }

  const google = useGoogleLogin({
    onSuccess: async (response: any) => {
      try {
        setLoadingStatus({ ...loadingStatus, isLoading: true, message: "loading" });
        setGoogleUser(response);
      } catch (error_msg) {
        // setErrorMsgs({
        //   ...errorMsgs,
        //   login_response: error_msg,
        // });
      }
    },
    onError: (err: any) => {
      // setErrorMsgs({
      //   ...errorMsgs,
      //   login_response: err?.error_description
      //     ? err?.error_description
      //     : "google error",
      // });
      // setTimeout(() => {
      //   setErrorMsgs(null);
      // }, 5000);
    },
  });


  useEffect(() => {
    if (googleUser) {
      servive_google_auth_login(googleUser).then((res: any) => {
        auth_customer(res)
      }).catch(err => {
      })
    }
  }, [googleUser])

  return <div >

    <button onClick={() => google()} className={googleBtnStyle} >
      <GoogleIcon />
      <span className=" pl-[0.7rem]" >
        google
      </span>
    </button>
    {loadingStatus.isLoading && <Loading message={loadingStatus.message} />}
  </div>
}


export default GoogleAuthBtn;