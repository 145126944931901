import { API_BASE_URL } from "../../utils/constants/constants";

// 
// 
export async function CreateOrderedProducts(data: any, order_id: string): Promise<any> {
  try {
    if (order_id && data && data.length > 0) {
      let _results = null;
      // 
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "order_id": order_id,
        "data": data
      });
      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${API_BASE_URL}/orders/createtransaction`, requestOptions)
        .then((response) => response.json())
        .then((result) => _results = result)
        .catch((error) => _results = { message: error.message, data: null });

      return _results;
    }
    else {
      return { message: "provide correct data", data: null }
    }
  }
  catch (err: any) {
    return { message: err.message, data: null }
  }
}