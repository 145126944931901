import React from "react";
import { Link } from "react-router-dom";
// import { ReactComponent as UserAccountIcon } from "../../utils/svg/user_account.svg";
import ShopCartBtn from "./components/shopCartBtn";
import HeaderMenuAccountBtn from "./components/accountBtn";
import HamBurgerMenuBtn from "./components/hamburgerBtn";
import LogoBtn from "./components/logoBtn";
import { DARK_MODE } from "../../utils/constants/constants";
// 
// 
// 
const HeaderMenu: React.FC<{ screen: string }> = ({ screen }) => {
  const whiteForAccountScreen: string = DARK_MODE ? "hover:text-light-grey4" : "hover:text-very-dark-grey"

  // make max screen to be 1200px
  return <div className={` fixed z-10 top-0 left-0 right-0 uppercase flex justify-between px-5 ${DARK_MODE ? "text-almost-black bg-almost-black" : "text-faint-grey bg-white"}  lg:mx-auto tablet:py-4 py-3 items-center `} >
    {/* logo */}
    <LogoBtn />

    {/* menu */}
    <div className=" hidden tablet:flex justify-between " >
      <Link to="/" className={` mx-5 font-bold font-inter ${whiteForAccountScreen} ${screen === "1" ? DARK_MODE ? "text-white" : "text-very-dark-grey" : "text-faint-grey"}   `} >Home</Link>
      <Link to="/shop" className={`font-bold  font-inter mx-5 ${whiteForAccountScreen}  ${screen === "2" ? DARK_MODE ? "text-white" : "text-very-dark-grey" : "text-faint-grey"}  `} >Shop</Link>
      <Link to="/contactus" className={` font-bold font-inter mx-5 ${whiteForAccountScreen}  ${screen === "3" ? DARK_MODE ? "text-white" : "text-very-dark-grey" : "text-faint-grey"}  `} >Contact us</Link>
    </div>

    {/* account | cart */}
    <div className=" flex justify-between" >
      {/* account button  */}
      <HeaderMenuAccountBtn screen={screen} />

      <span className={` hidden showMenuAccBtn:flex ${DARK_MODE ? " text-light-grey2 " : ""} mx-[0.9rem] items-center justify-center flex flex-col text-grey `} >|</span>

      {/* shopping cart button */}
      <ShopCartBtn screen={screen} />

      <span className={` hidden showMenuAccBtn:flex ${DARK_MODE ? " text-light-grey2 " : ""} mx-[0.9rem] items-center justify-center flex flex-col text-grey tablet:hidden `} >|</span>

      {/*  */}
      <HamBurgerMenuBtn />
    </div>

  </div >
}


export default HeaderMenu;