// validate phone number
export const validatePhone = (text: string) => {
  if (text === undefined || text === null) {
    return 'Phone number is required';
  }
  else if (text.length > 0) {
    var number = text;
    var trimmed = number.replace(/\s/g, '');
    var regex = /^0(6|7|8){1}[0-9]{8}$/;
    if (regex.test(trimmed) === true) return 1;

    return "Invalid Phone Number";
  }
  else {
    return "No Phone Number provided";
  }
}

// VLAIDATE EMAIL
export const validateEmail = (text: string) => {
  if (text === undefined || text === null) {
    return 'Email is required';
  } else if (text.length > 0 && /@/.test(text) && /[.]/.test(text)) {
    // 
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailPattern.test(text)) return 1;
    // 
    return 'Invalid Email';
  }
  else {
    return 'Invalid Email';
  }
}

// validate password
export function validatePassword(password: string): boolean | string {
  const minLength = 6; // More than 6 characters means minimum length is 6
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);

  if (password === undefined || password === null || password.length < minLength) {
    return "password is less than 6 chars";
  }
  if (!hasSpecialChar) {
    return "no special character in the password";
  }
  if (!hasUpperCase) {
    return "no atleast one uppercase letter in the password";
  }
  if (!hasNumber) {
    return "no atleast one number in the password";
  }
  return true;
}
