import { SectionComponentProps } from "./interfaces_";


// 
const SectionComponent: React.FC<SectionComponentProps> = ({ header, texts }) => {

  return <div className=" font-inter mb-[2rem] leading-normal " >

    <div className=" text-3xl pb-[1rem] font-light " >{header}</div>

    {/*  */}
    <div className=" pl-[1rem] " >
      {
        texts && texts.map((text: string, idx: number) => (
          <div className=" font-light font-inter mb-[1rem] " key={idx} >{text}</div>
        ))
      }
    </div>
  </div>
}

export default SectionComponent;