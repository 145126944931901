import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface ActivitySliceInterface {
  wasShopping: boolean;
}

const initialState: ActivitySliceInterface = {
  wasShopping: false,
}

export const activitySlice = createSlice({
  name: 'activitySlice',
  initialState,
  reducers: {
    updateWasShoppingAuth(state, action: PayloadAction<boolean>) {
      state.wasShopping = action.payload;
      return state;
    }
  }
})

export const { updateWasShoppingAuth } = activitySlice.actions;

export const useActivitySlice = (state: RootState) => state.activity;

export default activitySlice.reducer;
