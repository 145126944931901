import React from "react";


const ProudlySA: React.FC = () => {
  const _date = new Date();
  const year: number = _date.getFullYear();

  return <div className="  flex flex-col items-end text-[1.125rem] desktopsm:text-[1.25rem] font-inter text-locked-grey uppercase 
    
     " >
    <div className=" text-left w-[10.18rem] desktopsm:w-[11.18rem] " >proudly</div>
    <div className=" text-end  w-[11rem] desktopsm:w-[12rem] " >south african</div>
    <div className=" text-end  w-[9.0625rem] " >{year}</div>
  </div>
}


export default ProudlySA;