import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ShoppingCartIcon from "../../../utils/svg/shopCartIcon";
import { DARK_MODE } from "../../../utils/constants/constants";
import { useAppSelector } from "../../../utils/store/redux/hooks";
import { RootState } from "../../../utils/store/redux/store";

interface ShopCartBtnProps {
  screen: string,
}

const ShopCartBtn: React.FC<ShopCartBtnProps> = ({ screen }) => {
  const cartItemsSelector = useAppSelector((state: RootState) => state.cartItemsSlice);

  useEffect(() => {
  }, [])


  return <Link to="/cart" className={` ${DARK_MODE ? "bg-very-dark-grey text-white " : "bg-grey-like text-very-dark-grey"} active:bg-grey-like1 hover:bg-grey-like1 px-[0.7rem] py-[0.8rem] rounded-[0.3rem] flex flex-row items-center font-bold font-inter `} >

    <div className=" mr-1 " >
      <ShoppingCartIcon width="16" height="17" stroke={DARK_MODE ? "#fff" : "#232526"} />
    </div>

    <span className=" hidden desktopsm:flex mx-1 " >Cart</span>

    <span className={` ${DARK_MODE ? "bg-white text-almost-black" : "bg-very-dark-grey text-white"} ml-1 rounded-full px-[0.46rem] py-[0.02rem] flex items-center `} >{cartItemsSelector.length}</span>
  </Link>
}

export default ShopCartBtn;