import React from "react";
import GreaterThanIcon from "../../../utils/svg/greaterThanIcon";
import UserAccountIcon from "../../../utils/svg/userAccount";
import LockIcon from '../../../utils/svg/lockIcon';
import NotificationIcon from "../../../utils/svg/notificationIcon";
import HomeIcon from "../../../utils/svg/homeIcon";
import PaymentIcon from '../../../utils/svg/paymentIcon';
import HelpIcon from "../../../utils/svg/helpIcon";
import SignOutIcon from "../../../utils/svg/signOutIcon";
import TrashIcon from "../../../utils/svg/trashIcon";
import { useNavigate } from "react-router-dom";
import { AppHomeScreenRouterName, UserDetailsScreenRouterName, UserMoreScreenRouterName, UserNotificationScreenRouterName, UserPasswordSecurityScreenRouterName, UserPaymentDetailsScreenRouterName, UserSavedAddressScreenRouterName } from "../../../utils/routers/routersNames";
import { clearUserProfile } from "../../../utils/store/redux/slices/userProfile";
import { useAppDispatch } from "../../../utils/store/redux/hooks";


const SCREENS = [
  { icons: <LockIcon height="22" width="22" stroke="#232526" />, text: "password & security", link: UserPasswordSecurityScreenRouterName },
  { icons: <UserAccountIcon height="22" width="22" stroke="#232526" cssStyle="text-black" />, text: "your details", link: UserDetailsScreenRouterName },
  { icons: <NotificationIcon height="22" width="23" stroke="#232526" />, text: "notifications", link: UserNotificationScreenRouterName },
  { icons: <HomeIcon height="22" width="23" stroke="#232526" />, text: "saved addresses", link: UserSavedAddressScreenRouterName },
  { icons: <PaymentIcon height="22" width="23" stroke="#232526" />, text: "payment details", link: UserPaymentDetailsScreenRouterName },
  { icons: <TrashIcon height="22" width="23" stroke="#232526" />, text: "more", link: UserMoreScreenRouterName },
];

const ProfileRightComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function handleGetHelpBtn(): any {
    alert("clicked..");
  }

  function handleSignOutBtn(): any {
    dispatch(clearUserProfile());
    navigate(AppHomeScreenRouterName);
  }

  function handleItemBtn(url: string): any {
    if (UserPaymentDetailsScreenRouterName === url || url === UserPasswordSecurityScreenRouterName || url === UserSavedAddressScreenRouterName) {
      alert("disabled");
    }
    else {
      navigate(url);
    }
  }

  return <div className=" bg-light-grey6 pb-[2rem] " >

    <div className=" text-[3.375rem] uppercase text-almost-black px-[0.5rem]
      tablet768:text-[5.875rem] 
      desktopsm:text-[7.75rem] mobile:px-0
       " >
      account
    </div>

    <div className="bg-white px-[0.5rem] " >

      <span className=" uppercase text-[2.75rem] 
        desktopsm:text-[3.375rem]
        ">
        settings & <br /> preferences</span>

      <div className=" mt-[1.5rem] mb-[1rem] flex flex-col items-center uppercase " >
        {
          SCREENS && SCREENS.map((item, idx) => (
            <button key={idx} onClick={() => handleItemBtn(item?.link)}
              className=" cursor-pointer uppercase border-b-[0.1rem] border-b-light-grey4 hover:bg-light-grey2 active:bg-marking-grey py-[0.7rem] w-full flex flex-row justify-between items-center " >
              <div className=" flex-row flex" >
                {item.icons}
                <span className=" font-semibold font-inter pl-[0.5rem] text-[1.0625rem] " >{item.text}</span>
              </div>
              <GreaterThanIcon width="22" height="22" stroke="#232526" />
            </button>
          ))
        }
      </div>
    </div>

    <button onClick={() => handleGetHelpBtn()}
      className=" px-[0.5rem] w-full mt-[1rem] bg-light-grey5 hover:bg-light-grey2 active:bg-faint-grey py-[0.8rem] uppercase font-inter flex flex-row items-center justify-between " >
      <div className=" flex flex-row items-center">
        <HelpIcon height="22" width="23" stroke="#232526" />
        <span className=" px-[0.5rem] " >get help</span>
      </div>
      <GreaterThanIcon width="22" height="22" stroke="#232526" />
    </button>
    {/*  */}
    <button onClick={() => handleSignOutBtn()}
      className=" px-[0.5rem] w-full mt-[1rem] bg-light-grey5 hover:bg-light-grey2 active:bg-faint-grey py-[0.8rem] uppercase font-inter flex flex-row items-center justify-between " >
      <div className=" flex flex-row items-center">
        <SignOutIcon height="22" width="23" stroke="#232526" />
        <span className=" px-[0.5rem] " >sign out</span>
      </div>
      <GreaterThanIcon width="22" height="22" stroke="#232526" />
    </button>
    {/*  */}
  </div>
}

export default ProfileRightComponent;