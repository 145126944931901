// 
import { useNavigate } from "react-router-dom";
import { googleBtnStyle } from "../constants"
import { CheckOutScreenRouterName, ShopScreenRouterName } from "../../../utils/routers/routersNames";
import { useAppDispatch, useAppSelector } from "../../../utils/store/redux/hooks";
import { getInformation, getRandomString } from "../../../utils/routines/helps";
import { updateUserProfile } from "../../../utils/store/redux/slices/userProfile";
import { RootState } from "../../../utils/store/redux/store";

// 
const GuestAccountBtn: React.FC = () => {
  const activitySlice = useAppSelector((state: RootState) => state.activity);
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  // 
  function handleContinueAsGuestBtn() {
    let deviceID: string = getInformation();
    deviceID = deviceID ? deviceID : getRandomString();

    if (deviceID) {
      // add some information to user profile for guest users.
      dispatch(updateUserProfile({ id: deviceID }));
      // make user go back to shopping screen if user was shopping, 
      // otherwise make user go to shop page
      if (activitySlice.wasShopping === true) { navigate(CheckOutScreenRouterName); }
      else { navigate(ShopScreenRouterName); }
    }
  }
  // 
  return <button
    className={` ${googleBtnStyle} font-inter `}
    onClick={() => handleContinueAsGuestBtn()} >
    Continue as guest
  </button>
}

export default GuestAccountBtn;