
export const LOCATIONADDRESSSTRING: string = "https://www.google.com/maps/@-26.1812245,27.9958191,3a,75y,352.91h,82.72t/data=!3m6!1e1!3m4!1s4Jjpio1nMbff2fXSG5NtKA!2e0!7i16384!8i8192?coh=205409&entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D";
export const PHONENUMBER: string = "+270699645151";
export const BUSINESSEMAIL: string = "Info@solidwater.com";
export const RUSH_SHIPPING_COST: number = 50;
// local storage | store this data in env
export const LS_ADMIN_PROFILE: string = "adminProfileData";
export const LS_IMAGES: string = "contentimages";
//  
// for dark mode, store in redux and user browser local storage
export const DARK_MODE = false;
export const CONTACT_IMAGE = require("../assets/contactimg.png");
export const DUMMY_IMAGE = require("../assets/sw.jpg");
export const BUILDING_TYPES: string[] = ["residential", "PO BOX", "office", "store", "other"]
// shop page left side page images
export const SP1 = require("../assets/shop_page1.jpg");
export const SP2 = require("../assets/shop_page2.jpg");
export const SP3 = require("../assets/shop_page2.jpg");
export const AUTH_PAGE_IMAGE = require("../assets/auth_page_image.jpg");
export const HOMEPAGE_IMAGE1 = require("../assets/homepage1.jpg");
export const HOMEPAGE_IMAGE2 = require("../assets/homepage2.jpg");
export const HOMEPAGE_IMAGE3 = require("../assets/homepage3.jpg");
export const HOMEPAGE_IMAGE4_PROD500ML = require("../assets/homepage4_prod500ml.jpg");
export const HOMEPAGE_IMAGE5_PROD1L = require("../assets/homepage5_prod1l.jpg");
export const HOMEPAGE_IMAGE5_PROD5L = require("../assets/homepage6_prod5l.jpg");
// 
export const API_BASE_URL: any = process.env.REACT_APP_API_BASE_URL;