// use this type when you wants to change color of the SVG

import React from 'react';

interface Stars3IconProps {
  stroke: string;
  width: string;
  height: string;
}

const Stars3Icon: React.FC<Stars3IconProps> = ({ stroke, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 74 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.5577 56.2576C37.5011 56.9924 36.424 56.9924 36.3673 56.2576C35.6047 42.9833 31.0872 38.4658 17.8129 37.7032C17.0787 37.6467 17.0787 36.5703 17.8129 36.5138C31.0872 35.7512 35.6047 31.2337 36.3674 17.9594C36.424 17.2246 37.5011 17.2246 37.5577 17.9594C38.3203 31.2337 42.8378 35.7512 56.1121 36.5138C56.8463 36.5703 56.8463 37.6467 56.1121 37.7032C42.8378 38.4658 38.3203 42.9833 37.5577 56.2576Z" fill={stroke} />
      <path d="M54.0124 61.9514C53.9887 62.26 53.5363 62.26 53.5125 61.9514C53.1922 56.3762 51.2948 54.4788 45.7196 54.1585C45.4112 54.1348 45.4112 53.6827 45.7196 53.659C51.2948 53.3387 53.1922 51.4413 53.5125 45.8661C53.5363 45.5575 53.9887 45.5575 54.0124 45.8661C54.3327 51.4413 56.2301 53.3387 61.8053 53.659C62.1137 53.6827 62.1137 54.1348 61.8053 54.1585C56.2301 54.4788 54.3327 56.3762 54.0124 61.9514Z" fill={stroke} />
      <path d="M19.1035 27.2888C19.0893 27.474 18.8179 27.474 18.8036 27.2888C18.6114 23.9437 17.473 22.8053 14.1279 22.6131C13.9428 22.5988 13.9428 22.3276 14.1279 22.3134C17.473 22.1212 18.6114 20.9828 18.8036 17.6376C18.8179 17.4525 19.0893 17.4525 19.1035 17.6376C19.2957 20.9828 20.4341 22.1212 23.7793 22.3134C23.9643 22.3276 23.9643 22.5988 23.7793 22.6131C20.4341 22.8053 19.2957 23.9437 19.1035 27.2888Z" fill={stroke} />
    </svg>
  );
}

// 74, 75, 232526
export default Stars3Icon;
