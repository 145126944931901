import React from "react";
import LinkedInLink from "./linkedin";
import TwitterLink from "./twitter";
import InstagramLink from "./instagram";


const SocialLinks: React.FC = () => {

  return <div className=" text-light-grey2 flex flex-col justify-between
    tablet:flex-row tablet:items-center
  ">
    <LinkedInLink />

    <TwitterLink />

    <InstagramLink />
  </div>
}

export default SocialLinks;