import React from "react";
import HeaderMenu from "../../components/headerMenu/headerMenu";
import ProfileRightComponent from "./component/leftComponent";
import ProfileLeftComponent from "./component/rightComponent";


const Profile: React.FC = () => {

  return <div className=" bg-light-grey6 " >

    <HeaderMenu screen={"4"} />

    <div className=" bg-light-grey6
      mobile:px-[0.7rem] 
      tablet768:flex  tablet768:flex-row tablet768:max-w-[45rem] tablet768:mx-auto tablet768:px-0 
      tablet:max-w-[59rem] 
      desktopsm:max-w-[77rem] 
      desktopmd:max-w-[87rem] 
      desktoplg:max-w-[90rem]
    " >
      <div className="
        tablet768:w-6/12 tablet768:mr-[0.5rem]
        tablet:w-5/12 
      " >
        <ProfileRightComponent />
      </div>
      <div className="
        tablet768:w-6/12 tablet768:ml-[0.5rem]
        tablet:w-7/12 
      " >
        <ProfileLeftComponent />
      </div>
    </div>
  </div >
}

export default Profile;