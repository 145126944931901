export function extractTransactionData(data: any) {
  try {
    const { sourceType, status } = data;
    const tok_id = data.id;
    const { brand, expiryMonth, expiryYear, fingerprint, maskedCard, object } = data.source
    const card_id = data.source.id;

    return {
      sourceType: sourceType,
      status: status,
      "tok_id": tok_id,
      "card_id": card_id,
      "brand": brand,
      "expiryMonth": expiryMonth,
      "expiryYear": expiryYear,
      "fingerprint": fingerprint,
      "maskedCard": maskedCard,
      "object": object
    }
  }
  catch (err: any) { return null }
}


export function extractPaymentData(data: any) {
  try {
    const { message, status, } = data;
    const { amountInCents, captureState, chargeId, currency, liveMode, object } = data.data;

    return {
      message: message,
      status: status,
      amountInCents: amountInCents,
      captureState: captureState,
      chargeId: chargeId,
      currency: currency,
      liveMode: liveMode,
      object: object
    }
  }
  catch (err: any) { return null }
}




