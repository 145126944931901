
interface ProductImageProps {
  image: string;
  desc: string;
  product: string;
}

const ProductImage: React.FC<ProductImageProps> = ({ image, desc, product }) => {
  return <div className=' uppercase
      w-[10rem]
      mobile:w-[10.5rem]
      mobilels:w-[12.75rem] 
      tablet768:w-[20.5rem] 
      
      tablet:w-[17.75rem] desktopsm:w-[22.75rem] desktopmd:w-[25rem]
    ' >

    <div className='' >
      <img src={image} className="  
            h-[11.25rem] 
            mobile:h-[15rem] 
            mobilels:h-[16.25rem] 
            tablet768:h-[26.25rem] 
            tablet:h-[22.125rem] desktopsm:h-[28.375rem] desktopmd:h-[31.25rem]
          " />
      {/* {
        image && image.length > 0 ?
          (<img src={image} className="  
            h-[11.25rem] 
            mobile:h-[15rem] 
            mobilels:h-[16.25rem] 
            tablet768:h-[26.25rem] 
            tablet:h-[22.125rem] desktopsm:h-[28.375rem] desktopmd:h-[31.25rem]
          " />)
          : (
            <div className="card bg-slate-800">
              <div className="image-placeholder h-full text-center w-ful placeholder bg-slate-100"></div>
            </div>
          )
      } */}
    </div>

    {/*  */}
    <div className=' flex flex-col' >
      <span className=' font-inter font-bold 
        text-[1.75rem] tablet:text-[2.25rem] desktopsm:text-[2.75rem] '  >
        {product}
      </span>
      <span className=' font-inter font-semibold 
        text-[1.1875rem] desktopsm:text-[1.6875rem] ' >
        {desc}
      </span>
    </div>
  </div>
}


export default ProductImage