// use this type when you wants to change color of the SVG

import React from 'react';

interface HumBurgerMenuIconProps {
  stroke: string;
}
// 232526
const HumBurgerMenuIcon: React.FC<HumBurgerMenuIconProps> = ({ stroke }) => {

  return <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="7" width="22" height="2" fill={stroke} />
    <rect y="13" width="22" height="2" fill={stroke} />
  </svg>


}

export default HumBurgerMenuIcon;
