import { configureStore } from '@reduxjs/toolkit'
import cartItemReducer from "./slices/cartSlice"
import ml500Slice from './slices/ml500Slice'
import userProfileSlice from './slices/userProfile'
import activitySlice from './slices/activitiesSlice'
import billingAddress from './slices/billingAddress'
import paymentInformationSlice from './slices/paymentInfoSlice'
import productsSlice from "./slices/products";
import userAdminProfileSlice from './slices/userAdminProfile'
import contentImageSlice from './slices/contentImages'

export const solidWaterReduxStore = configureStore({
  reducer: {
    cartItemsSlice: cartItemReducer,
    ml500Slice: ml500Slice,
    userProfile: userProfileSlice,
    activity: activitySlice,
    billingAddressSlice: billingAddress,
    paymentInformation: paymentInformationSlice,
    productsSlice: productsSlice,
    userAdminProfile: userAdminProfileSlice,
    contentImageSlice: contentImageSlice
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof solidWaterReduxStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof solidWaterReduxStore.dispatch