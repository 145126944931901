// use this type when you wants to change color of the SVG
import React from 'react';

interface Quantity12IconProps {
  stroke: string;
  width: string;
  height: string;
}

const Quantity24Icon: React.FC<Quantity12IconProps> = ({ stroke, height = "48", width = "48" }) => {
  // const Quantity12Icon: React.FC = () => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.5" cy="34.5" r="2" transform="rotate(-90 6.5 34.5)" fill={stroke} />
      <circle cx="6.5" cy="27.5" r="2" transform="rotate(-90 6.5 27.5)" fill={stroke} />
      <circle cx="13.5" cy="34.5" r="2" transform="rotate(-90 13.5 34.5)" fill={stroke} />
      <circle cx="13.5" cy="27.5" r="2" transform="rotate(-90 13.5 27.5)" fill={stroke} />
      <circle cx="20.5" cy="34.5" r="2" transform="rotate(-90 20.5 34.5)" fill={stroke} />
      <circle cx="20.5" cy="27.5" r="2" transform="rotate(-90 20.5 27.5)" fill={stroke} />
      <circle cx="6.5" cy="20.5" r="2" transform="rotate(-90 6.5 20.5)" fill={stroke} />
      <circle cx="6.5" cy="13.5" r="2" transform="rotate(-90 6.5 13.5)" fill={stroke} />
      <circle cx="13.5" cy="20.5" r="2" transform="rotate(-90 13.5 20.5)" fill={stroke} />
      <circle cx="13.5" cy="13.5" r="2" transform="rotate(-90 13.5 13.5)" fill={stroke} />
      <circle cx="20.5" cy="20.5" r="2" transform="rotate(-90 20.5 20.5)" fill={stroke} />
      <circle cx="20.5" cy="13.5" r="2" transform="rotate(-90 20.5 13.5)" fill={stroke} />
      <circle cx="27.5" cy="34.5" r="2" transform="rotate(-90 27.5 34.5)" fill={stroke} />
      <circle cx="27.5" cy="27.5" r="2" transform="rotate(-90 27.5 27.5)" fill={stroke} />
      <circle cx="34.5" cy="34.5" r="2" transform="rotate(-90 34.5 34.5)" fill={stroke} />
      <circle cx="34.5" cy="27.5" r="2" transform="rotate(-90 34.5 27.5)" fill={stroke} />
      <circle cx="41.5" cy="34.5" r="2" transform="rotate(-90 41.5 34.5)" fill={stroke} />
      <circle cx="41.5" cy="27.5" r="2" transform="rotate(-90 41.5 27.5)" fill={stroke} />
      <circle cx="27.5" cy="20.5" r="2" transform="rotate(-90 27.5 20.5)" fill={stroke} />
      <circle cx="27.5" cy="13.5" r="2" transform="rotate(-90 27.5 13.5)" fill={stroke} />
      <circle cx="34.5" cy="20.5" r="2" transform="rotate(-90 34.5 20.5)" fill={stroke} />
      <circle cx="34.5" cy="13.5" r="2" transform="rotate(-90 34.5 13.5)" fill={stroke} />
      <circle cx="41.5" cy="20.5" r="2" transform="rotate(-90 41.5 20.5)" fill={stroke} />
      <circle cx="41.5" cy="13.5" r="2" transform="rotate(-90 41.5 13.5)" fill={stroke} />
    </svg>

  );
}
export default Quantity24Icon;
