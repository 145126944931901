import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface ML500SliceProps {
  numberBottles: number;
  subTotal: number;
}

const initialState: ML500SliceProps = {
  numberBottles: 0,
  subTotal: 0,
}

export const ML500Slice = createSlice({
  name: "ml500sSlice",
  initialState,
  reducers: {
    numberBottlesIncrement: (state, action: PayloadAction<number>) => {
      // make sure to not increment more than what is not in stock
      state.numberBottles = state.numberBottles + action.payload;
      return state;
    },
    numberBottlesDecrement: (state, action: PayloadAction<number>) => {
      // make sure to not decrement below zero.
      // in future when forms are added, make sure user 
      // input minus from quantity in store, doesnt go below zero.
      if (state.numberBottles === 0 && (state.numberBottles - action.payload) < 0) {
        return state
      }
      // 
      state.numberBottles = state.numberBottles - action.payload;
      return state;
    },
    numberBottlesReset: (state, action) => {
      // make the number of bottles to zero
      state.numberBottles = 0;
      return state;
    },
    numberBottlesSet: (state, action: PayloadAction<number>) => {
      // make the number of bottles to zero
      if (action.payload < 0) return

      state.numberBottles = action.payload;
      return state;
    },
    // 
    // for subtotal
    // 
    subTotalSet: (state, action: PayloadAction<number>) => {
      // make sure increment doesnt go above the amount in store.
      if (action.payload < 0) return state;

      state.subTotal = action.payload
      return state;
    },
    subTotalReset: (state, action) => {
      // make the sub total amount to zero
      state.subTotal = 0;
      return state;
    },
  }
})
// 
// 
// actions
export const { numberBottlesIncrement, numberBottlesDecrement, numberBottlesReset, numberBottlesSet, subTotalSet, subTotalReset } = ML500Slice.actions;
// 
// selectors
export const NUMBERBOTTLES = (state: RootState) => state.ml500Slice.numberBottles;
export const ML500_SUBTOTAL = (state: RootState) => state.ml500Slice.subTotal;
// 
export default ML500Slice.reducer;