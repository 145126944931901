export const servive_google_auth_login = async (user_info) => {
    if (user_info) {
        let user_data = null;
        try {
            await fetch(
                `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user_info.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user_info.access_token}`,
                    Accept: "application/json",
                },
            }
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    user_data = data;
                })
                .catch((error) => {
                    // console.error("Fetch error:", error);
                });
        } catch (err) {
            // console.log(err);
        }
        return user_data;

        //
    } else {
        return null;
    }
};