// use this type when you wants to change color of the SVG
import React from 'react';

interface Quantity1IconProps {
  stroke: string;
  width: string;
  height: string;
}

const Quantity1Icon: React.FC<Quantity1IconProps> = ({ stroke, height = "48", width = "48" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="2" fill={stroke} />
    </svg>
  );
}
export default Quantity1Icon;
