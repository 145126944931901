import { Link } from "react-router-dom"



const FooterLinks: React.FC = () => {

  return <div className=" mb-[4rem] flex flex-col justify-between  text-white  tracking-normal
  tablet:text-[1.3125rem] tablet:text-end tablet:mb-[2rem]
  desktopsm:text-[1.6875rem]
 " >
    <Link to="/termsconditions" className="
    active:text-light-grey2 hover:underline" >
      terms & conditions</Link>
    <Link to="/salesrefunds" className=" py-[0.8rem] active:text-light-grey2 hover:underline" >
      sales & refunds</Link>
    <Link to="/policyprivacy" className=" active:text-light-grey2 hover:underline" >
      privacy policy</Link>
  </div>
}

export default FooterLinks