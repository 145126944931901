import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../utils/routines/validations";
import ShowFieldError from "../../components/error";
import { AdminServiceLogin } from "../../services/admin/loginAdmin";
import { AdminDashboardScreenRouterName } from "../../utils/routers/routersNames";
import Loading from "../../components/loading";
import { useAppDispatch } from "../../utils/store/redux/hooks";
import { setUserAdminProfile } from "../../utils/store/redux/slices/userAdminProfile";
import { localStorage_setItem } from "../../utils/store/localStorage/localStorage";
import { LS_ADMIN_PROFILE } from "../../utils/constants/constants";


const AdminIndex: React.FC = () => {
  const [formData, setFormData] = useState<any>({ email: "", password: "", name: "" });
  const [loginStatus, setLoginStatus] = useState<any>({ loading: false, message: "" });
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // 
  async function handleLoginBtn(e: any) {
    e.preventDefault();
    setLoginStatus({ ...loginStatus, loading: true, message: "loading..." });
    try {
      if (formData && formData.email && formData.password && formData.name) {
        const isValidEmail = validateEmail(formData.email);
        if (isValidEmail === 1) {
          const login_res = await AdminServiceLogin(formData);
          if (login_res && login_res.success == true) {
            const adminData = login_res.data;
            setLoginStatus({ ...loginStatus, loading: false });
            dispatch(setUserAdminProfile(adminData));
            // store data in local storage
            localStorage_setItem(LS_ADMIN_PROFILE, adminData);
            // 
            navigate(AdminDashboardScreenRouterName);
          }
          else {
            setLoginStatus({ ...loginStatus, loading: false });
            let msg: string = login_res.message;
            if (msg.includes(""))
              msg = "network error. server is down or please your internet connection";
            // 
            alert(`${msg}`);
          }
        }
        else {
          setLoginStatus({ ...loginStatus, loading: false });
          setErrors({ ...errors, email: "please provide correct email" })
        }
      }
      else {
        setLoginStatus({ ...loginStatus, loading: false });
        alert(`please fill in the forms`);
      }
    }
    catch (err: any) {
      setLoginStatus({ ...loginStatus, loading: false });
    }
    // finally { setLoginStatus({ ...loginStatus, loading: false, message: "" }) }
  }

  return <div className=" py-[2rem] px-[1rem] h-screen font-inter bg-white" >

    <div className=" mx-auto max-w-[40rem] mt-[3rem] " >

      <form
        onSubmit={(e) => handleLoginBtn(e)}
        className=" text-[1rem] bg-slate-400 border-[0.1rem] rounded-[0.5rem] px-[1rem] py-[1rem] " >
        <div className=" text-3xl mb-[2rem] " >
          Admin Login
        </div>
        {/*  */}
        <div className=" flex flex-col" >
          <label className=" mb-[0.2rem] " >Email</label>
          <input className=" w-full py-[0.4rem] px-[0.5rem] "
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            placeholder="Enter Email" />
          {errors && errors.email && <ShowFieldError errorMessage={errors.email} />}
        </div>

        <div className=" flex flex-col mt-[1rem] " >
          <label className=" mb-[0.2rem] " >Name</label>
          <input className=" w-full py-[0.4rem] px-[0.5rem] "
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            placeholder="Enter Eame" />
        </div>

        <div className=" flex flex-col mt-[1rem] " >
          <label className=" mb-[0.2rem] " >Password</label>
          <input className=" w-full py-[0.4rem] px-[0.5rem] "
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            placeholder="Enter Password" />
        </div>

        <button
          className=" hover:bg-slate-600 active:bg-grey-like2 w-full mt-[3rem] bg-slate-950 text-white py-[0.5rem] text-[1.1rem] "
          onClick={(e) => handleLoginBtn(e)} >Login</button>
      </form>
    </div>

    {
      loginStatus &&
      loginStatus.loading === true &&
      <Loading message={loginStatus.message} />
    }
  </div>
}

export default AdminIndex;