import React from "react";
import Stars3Icon from "../../../utils/svg/stars3Icon";
import { HOMEPAGE_IMAGE3 } from "../../../utils/constants/constants";
import PlusIcon from "../../../utils/svg/plusIcon";

const TEXT_DESCRIPTION1: string = "Our purification process enhances water by removing impurities and increasing its alkalinity, delivering a unique and refreshing taste that not only quenches your thirst but can help you elevate your wellness game. Make it an essential part of a healthy, balanced lifestyle with every sip, and unlock your hydration and invigoration potential."

const TextImageStar: React.FC = () => {

  return <div className=' py-[2rem] relative tablet768:mt-[4rem] ' >

    <div className=' uppercase text-[3.75rem] 
      tablet768:text-[4.625rem] tablet768:w-[28rem] 
      tablet:text-[5rem] tablet:w-[30rem]
      desktopsm:text-[6.25rem] desktopsm:w-[40rem] ' >
      <span >experience <br />the purity of solid alkaline  <br /> water</span>
      <Stars3Icon width='74' height='75' stroke='#232526' />
    </div>
    {/*  */}
    <div className=' 
      tablet768:flex tablet768:flex-col tablet768:items-end tablet768:mt-[-7rem] desktopsm:top-[15rem]

      tablet768:w-[21.5625rem] tablet768:absolute tablet768:right-0
      tablet:w-[29.4375rem]
      desktopsm:w-[33.625rem]
      desktopmd:w-[37.375rem]
      ' >
      <img src={HOMEPAGE_IMAGE3} className=" 
        tablet768:h-[27.0625rem]
        tablet:h-[36.8125rem]
        desktopsm:h-[42rem] 
        desktopmd:h-[46.6875rem]
       " />
      <div className=' flex flex-row pt-[0.3rem] ' >
        {[1, 2, 3].map((i: number) => <span key={i} className=' mr-[0.2rem] tablet768:ml-[0.2rem] tablet768:mr-[0rem] ' >
          <PlusIcon width='48' height='48' stroke='#171718' />
        </span>
        )}
      </div>
    </div>
    <div className='  pt-[0.8rem] font-inter text-[1.5rem] mobilels:text-[1.6875rem] 
      tablet768:w-[20rem] tablet768:mt-[5rem]
      tablet:w-[26rem]
      desktopsm:text-[2.25rem] desktopsm:w-[38rem] desk desktopsm:pl-[3rem] desktopsm:ml-[2rem] 
      desktopmd:ml-[4rem] desktopmd:w-[42rem] ' >
      {TEXT_DESCRIPTION1}
    </div>
  </div>
}


export default TextImageStar;
