import React from "react";
import { useNavigate } from 'react-router-dom';
import { ContactUsScreenRouterName } from "../../../utils/routers/routersNames";
import ArrowRightIcon from "../../../utils/svg/arrowRigtIcon";
import ArrowTopRight from "../../../utils/svg/arrowTopRight";


const NeedSomethingBigger: React.FC = () => {
  const navigate = useNavigate();

  // 
  function handleBtnClick() {
    navigate(ContactUsScreenRouterName);
  }

  return <div className=" mx-[1rem] mt-[1.5rem] relative bg-secondary-grey px-[1rem] py-[1rem] uppercase " >
    <div className=" text-[3.375rem] desktopsm:text-[4.625rem] " >need <br />something <br /> bigger?</div>
    <div className=" font-inter text-[1.3125rem] font-bold desktopsm:text-[1.5rem] " >let's get in touch.</div>

    <button onClick={() => handleBtnClick()}
      className=" w-full mt-[1rem] px-[0.7rem] py-[0.8rem] rounded-[0.3rem] mobilels:hidden uppercase font-inter font-semibold text-white flex flex-row justify-between bg-very-dark-grey text-[1.0625rem] " >
      <span >contact us</span>
      <ArrowRightIcon width="21" height="22" stroke="#fff" />
    </button>

    <button className="hidden mobilels:block mobilels:absolute mobilels:top-[2rem] mobilels:right-[2rem] " onClick={() => handleBtnClick()} >
      <ArrowTopRight stroke="#000" width="39" height="38" />
    </button>
  </div>
}

export default NeedSomethingBigger;