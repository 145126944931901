import React from "react";
import StarLikeIcon from "../../../utils/svg/starLikeIcon";
import DropletIcon from "../../../utils/svg/dropletIcon";

const WaterDropletText: React.FC = () => {

  return <div className=' uppercase 
    tablet768:mt-[3rem] tablet768:flex tablet768:flex-row-reverse tablet768:justify-between tablet768:items-center 
    tablet:items-end
     ' >
    <div className=' text-end text-[1.0625rem] flex items-end flex-col mb-[3rem] 
      tablet768:w-5/12 tablet:pb-[0.3rem]
      ' >
      <StarLikeIcon width='47' height='47' stroke='#232526' />
      <span className=' font-inter py-[0.2rem] ' >
        water not <br />
        actually solid <br />
        *gasps*
      </span>
    </div>
    <div className=' text-[3.5rem] mobile:text-[4.625rem] mobilels:text-[5.25rem] tablet:text-[6.5rem] desktopsm:text-[7.75rem] 
    tablet768:w-7/12 
    desktopmd:w-8/12
    
    ' >
      <span >smooth, <br /> gentle and <br /> refreshing.</span>
      <div className=' flex flex-row' >
        {[1, 2, 3].map((i: number) => <span key={i} className=' mr-[0.2rem] ' >
          <DropletIcon width='19' height='29' stroke='#171718' />
        </span>
        )}
      </div>
    </div>
  </div>
}

export default WaterDropletText;