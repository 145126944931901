import React, { useState } from "react";
import EyeIcon from "../../../utils/svg/eyeIcon";
import ArrowRightIcon from "../../../utils/svg/arrowRigtIcon";
import BackProfileScreenBtn from "../component/backToProfileScreenBtn";


const AccountPasswordSecurity: React.FC = () => {
  const [seePassword, setSeePassword] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [sendOptViaEmail, setSendOptViaEmail] = useState<boolean>(false);
  const _selectedOptBtnStyle: string = "bg-white border-dark-grey rounded-[0.2rem] font-semibold border-[0.15rem]";



  function handleEyeBtn() {
    setSeePassword(!seePassword);
  }

  function savePassword() {
    alert("save password");
  }

  function receiveOptViaEmail(opt: boolean) {
    setSendOptViaEmail(opt);
  }

  return <div className=" bg-light-grey6 pb-[1rem] " >

    <div className="mobile:max-w-[22.5rem] mobile:mx-auto mobilels:max-w-[25rem] tablet768:max-w-[31.25rem] tablet:max-w-[37.5rem]" >
      {/*  */}
      <div className=" w-full mobile:px-0 px-[1rem] pt-[2rem] pb-[1rem] " >
        <BackProfileScreenBtn />
      </div>
      {/*  */}

      <div className=" uppercase mobile:px-0 px-[1rem] mt-[3rem] " >
        <div className=" flex flex-col mobile:flex-row text-[1.1875rem] font-inter font-semibold mobilels:text-[1.375rem] desktopsm:text-[1.6875rem]  " >
          <span >settings & </span>
          <span >preferences</span>
        </div>
        <span className=" text-[3.375rem] font-anton mobilels:text-[4.625rem] desktopsm:text-[5.875rem] " >password & security</span>
      </div>

      <div className=" bg-white pb-[2rem] " >
        {/*  */}
        <div className="  uppercase px-[1rem] py-[1rem] " >
          <span className=" font-inter text-locked-grey text-[0.75rem] font-semibold " >send opt via</span>
          {/* bg-white rounded-[0.4rem] font-semibold border-[0.15rem]" */}
          <div className=" bg-light-grey6 p-[0.4rem] w-full flex-row justify-between items-center font-inter " >
            <button
              className={`w-6/12 py-[0.4rem] uppercase text-[0.8125rem] ${sendOptViaEmail ? _selectedOptBtnStyle : ""} `}
              onClick={() => receiveOptViaEmail(true)} >phone</button>

            <button
              className={`w-6/12 py-[0.4rem] uppercase text-[0.8125rem] ${sendOptViaEmail === false ? _selectedOptBtnStyle : ""} `}
              onClick={() => receiveOptViaEmail(false)} >email</button>
          </div>
        </div>

        <div className=" uppercase px-[1rem] pb-[1rem] " >
          <span className=" font-inter text-locked-grey text-[0.75rem] font-semibold " >current password</span>
          <div className=" flex flex-row bg-light-grey6 p-[0.5rem] w-full " >
            <input className="w-full font-inter uppercase px-[0.2rem] py-[0.2rem] bg-light-grey6 " type={seePassword ? "text" : "password"} onChange={(e) => setNewPassword(e.target.value)} />
            <button onClick={() => handleEyeBtn()} >
              {seePassword ? <EyeIcon width="22" height="23" stroke="#232526" /> : <EyeIcon width="22" height="23" stroke="#232526" />}
            </button>
          </div>
        </div>

        <div className=" px-[1rem] pt-[2rem] w-full " >
          <button onClick={() => savePassword()}
            className="flex w-full flex-row justify-between items-center rounded-[0.2rem] py-[0.9rem] px-[0.7rem] bg-almost-black hover:bg-black active:bg-dark-grey" >
            <span className=" font-inter uppercase text-white text-[1.1875rem] " >change password</span>
            <ArrowRightIcon width="22" height="23" stroke="#fff" />
          </button>
        </div>
      </div>
    </div>
  </div >
}

export default AccountPasswordSecurity;