import { API_BASE_URL } from "../../../utils/constants/constants";

export async function service_charge_user(token_id: string, amount: number, userID: string): Promise<any> {
  try {
    let _result = null
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "token": token_id,
      "amount": amount,
      "userID": userID
    });

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    await fetch(`${API_BASE_URL}/payments/chargeclient`, requestOptions)
      .then((response) => response.json())
      .then((result) => _result = result)
      .catch((error) => _result = { message: error.message, data: [] });

    return _result;
  }
  catch (err: any) {
    return { message: err.message, data: [] }
  }
}