import React, { useState } from "react";
import EyeIcon from "../../../utils/svg/eyeIcon";
import ArrowRightIcon from "../../../utils/svg/arrowRigtIcon";

const ChangePasswordForm: React.FC = () => {
  const [pwd, setPwd] = useState<string>("");
  const [retypePwd, setRetypePwd] = useState<string>("");
  const [canSeePwd, setCanSeePwd] = useState({ pwd: false, rePwd: false });


  function handleSeePwdBtn(field: number) {
    if (field === 1) {
      setCanSeePwd({ ...canSeePwd, pwd: !canSeePwd.pwd })
    }
    else if (field === 2) {
      setCanSeePwd({ ...canSeePwd, rePwd: !canSeePwd.rePwd })
    }
  }

  function handleConfirmBtn() {
    alert("working on it")
  }


  return <div className=" m2-[1rem] " >

    <div className=" " >
      <div className=" font-inter uppercase font-semibold text-[0.75rem] mb-[0.2rem] " >
        Enter new password
      </div>

      <div className=" bg-secondary-grey mb-[1rem] flex flex-row rounded-[0.2rem] py-[0.8rem] px-[0.7rem]" >
        <input
          value={pwd}
          type={canSeePwd.pwd ? "password" : "text"}
          className=" active:outline-none focus:outline-none text-[1.0625rem] uppercase w-11/12 bg-secondary-grey font-inter "
          placeholder="enter new password"
          onChange={(e) => setPwd(e.target.value)} />

        {/*  */}
        <button
          onClick={() => handleSeePwdBtn(1)}
          className=" w-1/12 " >
          <EyeIcon width="22" height="23" stroke="#232526" />
        </button>
      </div>
    </div>

    <div className=" " >
      <div className=" font-inter uppercase font-semibold text-[0.75rem] mb-[0.2rem] " >
        confirm new password
      </div>

      <div className=" bg-secondary-grey mb-[1rem] flex flex-row rounded-[0.2rem] py-[0.8rem] px-[0.7rem]" >
        <input
          value={retypePwd}
          type={canSeePwd.rePwd ? "password" : "text"}
          className=" active:outline-none focus:outline-none text-[1.0625rem] uppercase w-11/12 bg-secondary-grey font-inter "
          placeholder="confirm new password"
          onChange={(e) => setRetypePwd(e.target.value)} />

        {/*  */}
        <button
          onClick={() => handleSeePwdBtn(2)}
          className=" w-1/12 " >
          <EyeIcon width="22" height="23" stroke="#232526" />
        </button>
      </div>
    </div>

    <button onClick={() => handleConfirmBtn()}
      className=" hover:bg-card-grey active:bg-dark-grey rounded-[0.5rem] flex flex-row justify-between py-[1rem] text-[1.0625rem] px-[0.8rem] bg-almost-black font-inter text-white uppercase font-semibold w-full mt-[3rem] " >
      <span >confirm new password</span>

      <ArrowRightIcon width="21" height="23" stroke="#fff" />
    </button>


  </div>
}

export default ChangePasswordForm;