import React, { useEffect, useState } from "react";
import ArrowRightIcon from "../../../utils/svg/arrowRigtIcon";
import { useNavigate } from "react-router-dom";
import { AccountScreenRouterName, CheckOutScreenRouterName, ShopScreenRouterName } from "../../../utils/routers/routersNames";
import { updateWasShoppingAuth } from "../../../utils/store/redux/slices/activitiesSlice";
import { useAppDispatch, useAppSelector } from "../../../utils/store/redux/hooks";
import { RootState } from "../../../utils/store/redux/store";
import ProductType from "./productType";
import TotalAmount from "./totalAmount";
import { CART_ITEM } from "../../../utils/interfaces/item";

const CartOrderSummary: React.FC = () => {
  const cartItemSelector = useAppSelector((state: RootState) => state.cartItemsSlice);
  const userProfile = useAppSelector((state: RootState) => state.userProfile);
  const ml500 = cartItemSelector.filter((i) => i.name === '500ml');
  const l1 = cartItemSelector.filter((i) => i.name === '1l');
  const l5 = cartItemSelector.filter((i) => i.name === '5l');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [totalCartAmount, setTotalCartAmount] = useState<number>(0);

  function handleCheckOutBtn() {
    // 
    if (totalCartAmount > 0) {
      // ask user to sign-in, if not signed in, before proceeding
      if (Object.keys(userProfile).length === 0) {
        alert("Please sign-in to continue");
        dispatch(updateWasShoppingAuth(true));
        navigate(AccountScreenRouterName);
      }
      // move user to address screen
      else if (Object.keys(userProfile).length > 0) {
        navigate(CheckOutScreenRouterName);
      }
    }
    else {
      alert("No Item in the cart");
    }
  }

  useEffect(() => {
    if (cartItemSelector.length === 0) { navigate(ShopScreenRouterName); }
    // 
    const tca = cartItemSelector.reduce((sum: number, prod: CART_ITEM) => sum += prod.total, 0);
    setTotalCartAmount(tca);
  }, [])

  return <div className=" pt-[1rem] bg-very-dark-grey px-[1rem] pb-[2.5rem] " >

    <div
      className=" text-white tablet:text-[3.375rem] tablet:block uppercase text-[2.5rem] flex flex-col " >
      <span>order </span>
      <span> summary</span>
    </div>

    {/* bottles */}
    <div className=" pt-[2rem]" >
      {ml500 && ml500.length > 0 && <ProductType totalCartAmount={totalCartAmount} set_totalCartAmount={setTotalCartAmount} name="500ml" />}
      {l1 && l1.length > 0 && <ProductType totalCartAmount={totalCartAmount} set_totalCartAmount={setTotalCartAmount} name="1l" />}
      {l5 && l5.length > 0 && <ProductType totalCartAmount={totalCartAmount} set_totalCartAmount={setTotalCartAmount} name="5l" />}
    </div>


    {/* checkout part */}
    <div className=" mt-[3rem] font-inter " >
      {/* VAT */}
      <TotalAmount totalCartAmount={totalCartAmount} />

      <button onClick={() => handleCheckOutBtn()}
        className=" hover:bg-light-grey3 active:bg-light-grey3 rounded-[0.3rem] text-very-dark-grey mt-[2rem] w-full px-[0.7rem] py-[1rem] font-semibold uppercase flex flex-row justify-between tablet:text-[1.1875rem] text-[1.0625rem] bg-white font-inter" >
        <span >Check out</span>
        <ArrowRightIcon width="21" height="23" stroke="#232526" />
      </button>
    </div>

  </div>
}

export default CartOrderSummary;