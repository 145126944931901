// use this type when you wants to change color of the SVG

import React from 'react';

interface ArrowUpperLeftProps {
  stroke: string;
  width: string;
  height: string;
}

const ArrowUpperLeft: React.FC<ArrowUpperLeftProps> = ({ stroke, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.16442 25.7921V9.16099M25.7956 9.16099H9.16442M9.16442 9.16099L25.7956 25.7921" stroke={stroke} strokeWidth="3.36" />
    </svg>

  );
}

// 
export default ArrowUpperLeft;
