import React, { useState } from "react";
import BackProfileScreenBtn from "../component/backToProfileScreenBtn";
import ScreenName from "../component/screenName";
import HelpIcon from "../../../utils/svg/helpIcon";
import TrashIcon from "../../../utils/svg/trashIcon";


const AccountMore: React.FC = () => {

  const [btnStatus, setBtnStatus] = useState({ whatsapp: false, email: true, sms: false });

  function handleGetHelpBtn() {
    alert("get more help")
  }

  function handleDeleteAccountBtn() {
    alert("delete account")
  }

  return <div className=" bg-light-grey6 pb-[1rem] h-screen  " >
    <div className="mobile:max-w-[22.5rem] mobile:mx-auto mobilels:max-w-[25rem] tablet768:max-w-[31.25rem] tablet:max-w-[31.25rem]" >
      {/*  */}
      <div className=" w-full mobile:px-0 px-[1rem] pt-[2rem] pb-[1rem] " >
        <BackProfileScreenBtn />
      </div>

      {/* screen name */}
      <div className=" uppercase mobile:px-0 px-[1rem] mt-[3rem] " >
        <div className=" flex flex-col mobile:flex-row text-[1.1875rem] font-inter font-semibold mobile:text-[1.375rem] mobilels:text-[1.375rem] desktopsm:text-[1.6875rem]  " >
          <span >settings & </span>
          <span >preferences</span>
        </div>
        <span className=" text-[3.375rem] font-anton mobile:text-[4.625rem] mobilels:text-[4.625rem] desktopsm:text-[5.875rem] " >
          more...
        </span>
      </div>

      <div className=" bg-white px-[1rem] py-[1rem] " >
        {/* get help */}
        <button className=" w-full font-semibold flex flex-row justify-between py-[0.7rem] px-[1rem] rounded-[0.3rem] mb-[1rem] hover:bg-marking-grey active:bg-light-grey4 bg-secondary-grey text-almost-black "
          onClick={() => handleGetHelpBtn()} >
          <span className=" font-inter uppercase text-[1.0625rem] desktopsm:text-[1.0625rem] " >get help</span>
          <HelpIcon width="23" height="23" stroke="#171718" />
        </button>
        {/* delete account */}
        <button className=" w-full font-semibold flex flex-row justify-between py-[0.7rem] px-[1rem] rounded-[0.3rem] mb-[1rem] hover:bg-marking-grey active:bg-light-grey4 bg-red2 text-red1 "
          onClick={() => handleDeleteAccountBtn()} >
          <span className=" font-inter uppercase text-[1.0625rem] desktopsm:text-[1.0625rem] " >delete Account</span>
          <TrashIcon width="23" height="23" stroke="#FF3131" />
        </button>
      </div>
      {/*  */}
    </div>

    {/*  */}
  </div>
}

export default AccountMore;