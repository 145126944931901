import React from 'react';
import WhatsappIcon from '../../utils/svg/whatsappIcon';
import EmailIcon from '../../utils/svg/emailIcon';
import CallIcon from '../../utils/svg/callIcon';
import { BUSINESSEMAIL, PHONENUMBER } from '../../utils/constants/constants';

export interface ContactTypeDataInterface {
  icon: React.ReactNode;
  type: string;
  text: string;
  rel: string
}

export interface SearchDataInterface {
  question: string;
  answer: string
}

const color: string = "#232526";

export const CONTACTTYPEDATA: ContactTypeDataInterface[] = [
  {
    icon: <WhatsappIcon width="50.4" height="50.4" stroke={color} />,
    type: "whatsapp",
    text: PHONENUMBER,
    rel: `https://wa.me/${PHONENUMBER}`
  },
  {
    icon: <EmailIcon width="55.44" height="55.44" stroke={color} />,
    type: "email",
    text: BUSINESSEMAIL,
    rel: `mailto:${BUSINESSEMAIL}`
  },
  {
    icon: <CallIcon width="52" height="52" stroke={color} />,
    type: "call",
    text: PHONENUMBER,
    rel: `tel:${PHONENUMBER}`,
  }
]


export const SEARCHDATA: SearchDataInterface[] = [
  {
    question: "how long will my order take",
    answer: "less than 2hrs",
  },
  {
    question: "do you have physical store location",
    answer: "no"
  },
  {
    question: "this is another question that is very long and not useful",
    answer: "yeah ne"
  }
]
