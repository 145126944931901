import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState: Partial<any> = {}

export const userAdminProfileSlice = createSlice({
  name: 'userAdminProfileSlice',
  initialState,
  reducers: {
    setUserAdminProfile(state, action: PayloadAction<any>) {
      return { ...action.payload };
    },
    updateAdminUserProfile(state, action: PayloadAction<Partial<any>>) {
      return { ...state, ...action.payload };
    },
    clearUserAdminProfile(state) {
      // reset state to initial empty state
      return initialState;
    }
  }
})

export const { setUserAdminProfile, clearUserAdminProfile } = userAdminProfileSlice.actions;

export const UserAdminProfileSelector = (state: RootState) => state.userProfile;

export default userAdminProfileSlice.reducer;