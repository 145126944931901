import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState: Partial<any> = {}

export const contentImageSlice = createSlice({
  name: 'contentImage',
  initialState,
  reducers: {
    setContentImages(state, action: PayloadAction<any>) {
      return { ...action.payload };
    }
  }
})

export const { setContentImages } = contentImageSlice.actions;

export const contentImageSelector = (state: RootState) => state.contentImageSlice

export default contentImageSlice.reducer;