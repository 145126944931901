import React from "react";
import { deflateRaw } from "zlib";
import EditIcon from "../../../utils/svg/editIcon";
import TrashIcon from "../../../utils/svg/trashIcon";
import BackProfileScreenBtn from "../component/backToProfileScreenBtn";
import ScreenName from "../component/screenName";


const AccountSavedAddress: React.FC = () => {


  function handleDeleteAddress() {
    alert("delete address");
  }

  function handleEditBtnAddress() {
    alert("edit saved address");

  }

  return <div className=" bg-light-grey6 pb-[1rem] " >
    <div className="mobile:max-w-[22.5rem] mobile:mx-auto mobilels:max-w-[25rem] tablet768:max-w-[31.25rem] tablet:max-w-[31.25rem]" >
      {/*  */}
      <div className=" w-full mobile:px-0 px-[1rem] pt-[2rem] pb-[1rem] " >
        <BackProfileScreenBtn />
      </div>

      {/* screen name */}
      <ScreenName screenName="saved addresses" />

      <div className=" bg-white px-[1rem] py-[1rem] " >

        {
          [1, 2, 3].map((item, idx) => (

            <div key={idx} className=" mb-[1rem] border-[0.1rem] rounded-[0.2rem] p-[0.5rem] tablet768:flex tablet768:flex-row tablet768:items-center " >
              <div className=" tablet768:w-8/12 " >
                <div className=" flex flex-col pb-[1rem] font-inter uppercase  " >
                  <span className=" text-locked-grey text-[0.875rem] " >shipped to</span>
                  <span className=" text-card-grey text-[1.0625rem] font-semibold  " >{"firstname"}'s</span>
                  <span className=" text-locked-grey text-[0.875rem] " >{"street name"},</span>
                  <span className=" text-locked-grey text-[0.875rem] " >{"suburb"},{"city"},{"postcode,"}</span>
                  <span className=" text-locked-grey text-[0.875rem] " >{"province"},{"south africa"}</span>
                </div>
              </div>

              <div className=" tablet768:w-4/12 tablet768:ml-[1rem] "  >
                <div className=" pt-[1rem] flex flex-row tablet768:flex-col " >
                  {/* edit */}
                  <button className=" tablet768:mr-0 mr-[0.3rem] w-6/12 tablet768:w-full font-semibold flex flex-row justify-between py-[0.7rem] px-[1rem] rounded-[0.3rem] mb-[1rem] hover:bg-marking-grey active:bg-light-grey4 bg-light-grey6 text-almost-black "
                    onClick={() => handleEditBtnAddress()} >
                    <span className=" font-inter uppercase text-[1.0625rem] desktopsm:text-[1.0625rem] " > edit</span>
                    <EditIcon width="22" height="22" stroke="#171718" />
                  </button>
                  {/* delete bank account */}
                  <button className=" tablet768:ml-0 ml-[0.3rem] w-6/12 tablet768:w-full font-semibold flex flex-row justify-between py-[0.7rem] px-[1rem] rounded-[0.3rem] mb-[1rem] hover:bg-marking-grey active:bg-light-grey4 bg-light-grey6 text-red1 "
                    onClick={() => handleDeleteAddress()} >
                    <span className=" font-inter uppercase text-[1.0625rem] desktopsm:text-[1.0625rem] " >delete</span>
                    <TrashIcon width="23" height="23" stroke="#FF3131" />
                  </button>
                </div>
              </div>
            </div>
            // 
          ))
        }

      </div>
      {/*  */}
    </div>

    {/*  */}
  </div >
}

export default AccountSavedAddress;