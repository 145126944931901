import React from "react";
import NeedSomethingBigger from "./needSomethingBigger";
import ML500 from "./500ml";
import OneLiter from "./1l";
import FiveLiter from "./5l";

// interface itemInterface {
//   id: String;
//   quantity: number;
// }

const RightComponent: React.FC = () => {

  return <div className=" tablet:px-0 py-[1rem] " >

    <ML500 />

    <OneLiter />

    <FiveLiter />

    <NeedSomethingBigger />

  </div>
}


export default RightComponent;