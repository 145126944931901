import React, { useEffect, useState } from "react";
import { AdminServiceAddProduct } from "../../services/admin/serviceAddProducts";
import Loading from "../../components/loading";
import { LOADING_INTERFACE } from '../../utils/interfaces/loadingPropInterface';
import { AdminDashboardScreenRouterName, AdminScreenRouterName } from "../../utils/routers/routersNames";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../utils/store/redux/hooks";
import { RootState } from "../../utils/store/redux/store";
import { localStorage_getItem } from "../../utils/store/localStorage/localStorage";
import { LS_ADMIN_PROFILE } from "../../utils/constants/constants";
import { setUserAdminProfile } from "../../utils/store/redux/slices/userAdminProfile";

interface PRODUCTS_INTERFACE {
  name: string;
  description: string;
  quantity: number[];
}

const PRODUCTS: PRODUCTS_INTERFACE[] = [
  { name: "500ml", description: "", quantity: [6, 24] },
  { name: "1l", description: "", quantity: [6, 12] },
  { name: "5l", description: "", quantity: [1, 4] }
]
// 
// 
const AdminAddProduct: React.FC = () => {
  const amdinProfile = useAppSelector((state: RootState) => state.userAdminProfile);
  const [selectedProduct, setSelectedProduct] = useState<PRODUCTS_INTERFACE | null>(null);
  const [loadingStatus, setLoadingStatus] = useState<LOADING_INTERFACE>({ isloading: false, message: "" })
  const [formdata, setFormData] = useState<any | null>({ name: "", quantity: "", price: "", discount: "", available: "" });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // 
  async function handleSaveBtn() {
    try {
      setLoadingStatus({ ...loadingStatus, isloading: true, message: "loading" });
      if (formdata.name && formdata.quantity && formdata.price && formdata.discount && formdata.available) {
        const res = await AdminServiceAddProduct(formdata, amdinProfile.auth);
        setLoadingStatus({ ...loadingStatus, isloading: false, message: "" });
        setSelectedProduct(null);
        setFormData({ ...formdata, name: "", quantity: "", price: "", discount: "", available: "" });
        if (res) {
          alert(`${res.message}`)
        }
        else {
          alert(`${res.message}`)
        }
      }
      else {
        alert("fill all fields");
        setLoadingStatus({ ...loadingStatus, isloading: false, message: "" });
      }
    }
    catch (e: any) {
      alert(`${e.message}`)
      setLoadingStatus({ ...loadingStatus, isloading: false, message: "" });
    }
  }

  // 
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const prod_name = event.target.value.toLowerCase();
    const filter_prod = PRODUCTS.filter((p: PRODUCTS_INTERFACE) => p.name === prod_name)[0]
    setSelectedProduct(filter_prod);
    // 
    setFormData({ ...formdata, name: event.target.value });
    // 
  };


  useEffect(() => {
    // check ls for data
    const profileData = localStorage_getItem(LS_ADMIN_PROFILE);
    if (profileData) {
      dispatch(setUserAdminProfile(profileData));
      // if (Object.keys(adminProfileInfo).length === 0) { }
    }
    else {
      navigate(AdminScreenRouterName);
    }
  }, []);

  return <div className=" pt-[2rem] px-[1rem] text-center font-inter bg-white h-screen " >

    <div className=" max-w-[40rem] mx-auto " >

      <div className=" mb-[2rem] text-left" >
        <Link to={AdminDashboardScreenRouterName} className=" underline" >Back</Link>
      </div>

      <div className=" text-3xl uppercase text-left mb-[2rem] font-bold " >Add Product</div>

      <div className=" flex flex-col text-left mb-[1rem] " >
        <label className=" text-almost-black uppercase " htmlFor="products">Select A Product</label>
        <select className=" rounded-[0.5rem] py-[0.8rem] px-[1rem] " id="products" onChange={handleChange}>
          <option selected={true} disabled={true} className=" text-almost-black " >
            {"select an option"}
          </option>
          {PRODUCTS.map((option, idx) => (
            <option className=" uppercase text-almost-black " key={idx} value={option.name}>
              {option.name}
            </option>
          ))}
        </select>
      </div>

      <div className=" flex flex-col text-left mb-[1rem] " >
        <label className=" text-almost-black uppercase" htmlFor="products">Enter {selectedProduct?.name} Quantity </label>
        <input disabled={selectedProduct ? false : true}
          placeholder="enter quantity" type="number" value={formdata.quantity} min={1}
          onChange={(e) => setFormData({ ...formdata, quantity: e.target.value })}

          className=" bg-[#e9e9ed] px-[0.8rem] py-[0.6rem] rounded-[0.5rem] font-inter " />
        {/* <select className=" rounded-[0.5rem] py-[0.8rem] px-[1rem] " id="products"
          // value={selectedProduct?.quantity[0]}
          onChange={(e) => setFormData({ ...formdata, quantity: e.target.value })}>
          <option selected={true} disabled={true} className=" text-almost-black " >
            Select <span className=" uppercase " > {selectedProduct?.name}</span>'s Quantity
          </option>
          {selectedProduct && selectedProduct?.quantity.map((option, idx) => (
            <option className=" uppercase text-almost-black " key={idx} value={option}>
              {option}
            </option>
          ))}
        </select> */}
      </div>

      <div className=" flex flex-col text-left mb-[1rem] " >
        <label className=" text-almost-black uppercase" htmlFor="products">Enter Available Quantity </label>
        <input disabled={selectedProduct ? false : true}
          placeholder="enter available quantity" type="number" value={formdata.available} min={1}
          onChange={(e) => setFormData({ ...formdata, available: e.target.value })}
          className=" bg-[#e9e9ed] px-[0.8rem] py-[0.6rem] rounded-[0.5rem] font-inter " />
      </div>

      {/*  */}
      <div className=" flex flex-col text-left mb-[1rem] " >
        <label className=" text-almost-black uppercase" htmlFor="products">Enter {selectedProduct?.name} Price </label>
        <input disabled={selectedProduct ? false : true}
          placeholder="enter price" type="number" value={formdata.price} min={1}
          onChange={(e) => setFormData({ ...formdata, price: e.target.value })}
          className=" bg-[#e9e9ed] px-[0.8rem] py-[0.6rem] rounded-[0.5rem] font-inter " />
      </div>

      {/*  */}
      <div className=" flex flex-col text-left mb-[1rem] " >
        <label className=" text-almost-black uppercase" htmlFor="products">Enter {selectedProduct?.name} Discount </label>
        <input disabled={selectedProduct ? false : true}
          placeholder="enter discount" type="number" value={formdata.discount} min={0}
          onChange={(e) => setFormData({ ...formdata, discount: e.target.value })}
          className=" bg-[#e9e9ed] px-[0.8rem] py-[0.6rem] rounded-[0.5rem] font-inter " />
      </div>

      <button onClick={() => handleSaveBtn()}
        className=" uppercase font-semibold mt-[2rem] bg-black w-full text-white py-[0.8rem] rounded-[0.3rem] hover:bg-slate-700 active:bg-green-950 " >
        Add
      </button>
    </div>

    {/* for loading */}
    {loadingStatus.isloading && <Loading message={loadingStatus.message} />}
    {/*  */}

  </div>
}


export default AdminAddProduct;

