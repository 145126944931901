import React, { useState } from "react";
import PlusIcon from "../../../utils/svg/plusIcon";
import MinusIcon from "../../../utils/svg/minusIcon";
import DottedQuantity from "./dottedQuantity";
import { addItemToCart, removeItemFromCart } from "../../../utils/store/redux/slices/cartSlice";
import { useDispatch } from "react-redux";

interface OrderQuantityProps {
  numberBottles: number;
  setNumberBottles: (v: number) => void;
  currentProduct: any;
  productsSetter: (param: any) => void
}

const OrderQuantity: React.FC<OrderQuantityProps> = ({ numberBottles, setNumberBottles, currentProduct, productsSetter }) => {
  const [userQuantityValue, setUserQuantityValue] = useState<number>(0);
  const [localSubTotal, setLocalSubTotal] = useState<number>(0);
  const dsipatch = useDispatch();

  function handleQuantityChange(value: number) {
    // when quantity is equal to 0 and user presses -1, exit function
    if (value === -1 && userQuantityValue === 0) return;
    const new_user_quantity = userQuantityValue + value
    // make sure user input is equal to or less than what in store
    if (new_user_quantity <= parseInt(currentProduct.available)) {
      // 
      setUserQuantityValue(new_user_quantity);
      // set the number of bottles
      let nb = numberBottles + (parseInt(currentProduct.quantity) * value);
      setNumberBottles(nb);
      // 
      // calculate local sub total here
      // remove discount amount, and multiply by the user quantity value
      const p = parseFloat(currentProduct.price) - parseFloat(currentProduct.discount);
      const lst = p * new_user_quantity;
      // set local subtotal
      setLocalSubTotal(lst);
      // set for product. find better way to update total
      // loop over all products. find products in list where id is current product id. 
      // change it subtotal amount, otherwise just assign back what already there.
      productsSetter((prev: any) =>
        prev.map((prod: any) => prod.id === currentProduct.id ? { ...prod, subtotal: lst } : prod)
      )
      // add to the number of products in redux
      // state = action.payload;
      if (new_user_quantity && new_user_quantity > 0) {
        dsipatch(addItemToCart({
          id: currentProduct.id,
          name: currentProduct.name,
          pack: currentProduct.quantity,
          quantity: new_user_quantity,
          total: lst,
          price: parseFloat(currentProduct.price),
          available: currentProduct.available,
        }))
      }
      else if (new_user_quantity === 0) {
        dsipatch(removeItemFromCart(currentProduct.id));
      }
    }
    else {
      alert("no more than what is available");
    }

  }

  return <div
    className=" mb-[1rem] relative flex flex-row justify-between pr-[0.5rem] items-center bg-quantity-bar uppercase font-inter" >

    <div className=" w-2/12 mobilels:w-2/12 tablet768:w-1/12 tablet:w-2/12 flex justify-center relative top-0 bottom-0 bg-white 
      desktopsm:w-1/12
      " >
      <DottedQuantity quantity={parseInt(currentProduct.quantity)} stroke="#232526" />
    </div>

    {/*  */}
    <div className=" w-5/12 mobilels:w-4/12 tablet768:w-6/12 
    bg-very-dark-grey mobile:px-[0.4rem] flex flex-row items-center py-[0.25rem]
      tablet:w-4/12 tablet:flex tablet:flex-row tablet:justify-between
      desktopsm:w-6/12 
      
      " >
      {/* bg-light-grey9 */}
      <div className=" mobile560:flex mobile560:flex-row mobile560:items-center w-5/12
       flex text-center flex-col border-light-grey5 
        tablet:flex-col  tablet:px-0 tablet:w-5/12
        desktopsm:flex-row 
        tablet768:flex-row tablet768:w-5/12
        border-r-[0.12rem] border-r-light-grey4
       " >
        <span className=" tablet:text-[1.5rem] tablet768:text-[1.6875rem] text-[1.5rem] text-white font-semibold 
          desktopsm:text-[1.6875rem] 
        " >{currentProduct.quantity}</span>
        <span className=" text-marking-grey font-semibold text-[0.875rem] tablet768:text-[1.0625rem]
          tablet:text-[0.875rem] desktopsm:text-[1.0625rem] desktopsm:px-[0.5rem] tablet768:px-[0.5rem]
        " >{parseInt(currentProduct.quantity) === 1 ? "unit" : "pack"}</span>
      </div>


      {/*  */}
      <div className=" flex flex-col w-7/12 px-[0.5rem]
        mobile560:flex 
        desktopsm:flex-row 
        tablet768:flex-row tablet768:w-7/12
        tablet:ml-[0.5rem] tablet:flex-col tablet:w-7/12 tablet:text-[0.9375rem]

       " >
        <div className=" flex flex-col
          tablet768:pl-[0.5rem] tablet768:w-6/12
          tablet:pl-[0rem] 
          desktopsm:pl-[0.5rem] desktopsm:w-6/12
          border-b-[0rem] border-light-grey5 " >
          <span className=" text-white text-[0.9375rem] " >R{currentProduct.price}</span>
          <span className=" text-marking-grey text-[0.6875rem] " >Price</span>
        </div>

        {parseInt(currentProduct.quantity) !== 1 && <span className=" tablet768:hidden border-t-[0.1rem] tablet:block border-t-marking-grey my-[0.2rem] " ></span>}

        {parseInt(currentProduct.quantity) !== 1 &&
          <div className=" text-start flex flex-col
          desktopsm:ml-[1rem] desktopsm:border-l-[0.1rem] desktopsm:mx-[0.5rem] desktopsm:pl-[1rem] desktopsm:w-6/12
          tablet:pl-[0rem] tablet:border-l-[0rem] tablet:ml-[0rem] 
          tablet768:ml-[1rem] tablet768:border-l-[0.1rem] tablet768:mx-[0.5rem] tablet768:pl-[1rem] tablet768:w-6/12
           " >
            <span className=" text-white text-[0.9375rem] " >R{currentProduct.discount}</span>
            <span className=" text-marking-grey text-[0.6875rem] " >save</span>
          </div>}
      </div>
    </div>

    {/* add or remove btn */}
    <div className=" w-4/12 mobile:w-5/12 mobilels:pl-[0.6rem] flex justify-center items-center text-center
      tablet768:w-6/12 
      desktopsm:w-5/12
      tablet:w-6/12 tablet:justify-between
      mobilels:w-6/12 mobilels:justify-between     
      mobilels:flex mobilels:flex-row  mobilels:items-center
      
      " >
      <div className=" hidden mobilels:flex mobilels:flex-col  " >
        <div className=" flex flex-row items-center" >
          <span className=" text-white font-semibold text-[1.1875rem] ">
            R{localSubTotal}
          </span>
          <span className=" text-marking-grey " >.00</span>
        </div>
        <span className=" text-marking-grey text-[0.6875rem]  " > subtotal</span>
      </div>
      {/*  */}
      <div className=" flex flex-row h-full " >
        <button onClick={() => handleQuantityChange(-1)}
          className=" bg-almost-black px-[0.6rem] py-[.4rem] rounded-[0.3rem] hover:bg-very-dark-grey active:bg-dark-grey "
        >
          <MinusIcon width={"16"} height={"16"} stroke="#fff" />
        </button>
        {/*  */}
        <span className=" text-white text-center w-[2.5rem] mx-[0.2rem] px-[0.3rem] text-[1.5rem]
          desktopsm:text-[1.75rem]
        ">
          {userQuantityValue}
        </span>
        {/*  */}
        <button onClick={() => handleQuantityChange(1)}
          className=" bg-almost-black px-[0.6rem] py-[.4rem] rounded-[0.3rem] hover:bg-very-dark-grey active:bg-dark-grey "
        >
          <PlusIcon width={"16"} height={"16"} stroke="#fff" />
        </button>
      </div>
    </div>
    {/* add or remove btn | end */}
  </div >
}

export default OrderQuantity;