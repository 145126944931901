import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import XCancelIcon from "../../../../utils/svg/xCancelIcon";
import ArrowRightIcon from "../../../../utils/svg/arrowRigtIcon";
import GreaterThanIcon from "../../../../utils/svg/greaterThanIcon";
import EyeIcon from "../../../../utils/svg/eyeIcon";
import { validateEmail } from "../../../../utils/routines/validations";
import { ServiceLoginUser } from "../../../../services/auth/login";
import { useAppDispatch, useAppSelector } from "../../../../utils/store/redux/hooks";
import { setUserProfile } from "../../../../utils/store/redux/slices/userProfile";
import Loading from "../../../../components/loading";
import { RootState } from "../../../../utils/store/redux/store";
import { CheckOutScreenRouterName, ShopScreenRouterName } from "../../../../utils/routers/routersNames";


interface LoginScreenProps {
  showForm: {};
  setShowform: ({ }) => void
}

const LoginScreen: React.FC<LoginScreenProps> = ({ showForm, setShowform }) => {
  // redux
  const activitySlice = useAppSelector((state: RootState) => state.activity);
  // 
  const [loadingStatus, setLoadingStatus] = useState<any>({ isloading: false, message: "" })
  const [formdata, setFormdata] = useState<any>({ email: "", password: "" });
  const [seePassword, setSeePassword] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  function handleEyeBtn() {
    setSeePassword(!seePassword);
  }

  function handleCancelBtn() { setShowform({ ...showForm, signin: false }); }

  async function handleForm(event: any) {
    event.preventDefault();
    // navigate(ProfileScreenRouterName);
    if (formdata && formdata.email && formdata.password && formdata.email.length > 0 && formdata.password.length > 0) {
      // check if email is valid
      const isValidEmail = validateEmail(formdata.email);
      if (isValidEmail === 1) {
        const userdata = { ...formdata, isthirdparty: 0 }
        setLoadingStatus({ ...loadingStatus, isLoading: true, message: "loading" });
        const response = await ServiceLoginUser(userdata);
        dispatch(setUserProfile(response.data)); // set user in SM
        setLoadingStatus({ ...loadingStatus, isLoading: false, message: "" });
        // store data in state | local storage
        if (response.message === 'success') { // clear form
          setFormdata({ ...formdata, email: "", password: "" });
          // make user go back to shopping screen if user was shopping, 
          // otherwise make user go to shop page
          if (activitySlice.wasShopping === true) { navigate(CheckOutScreenRouterName); }
          else { navigate(ShopScreenRouterName); }
        }
        // make route go back page where user was, before here.
        alert(`${response.message}`);
        // 
      }
      else {
        alert(`${isValidEmail}`);
      }
    }
    else {
      alert("Plese valid email and password");
    }
  }

  return <div className=" bg-white max-w-[992px] mx-auto px-[1rem] py-[1rem] flex flex-col font-inter" >
    <div className=" text-5xl mb-[2rem] flex flex-row justify-between items-center uppercase desktopsm:items-start desktopsm:flex-col-reverse " >
      <span className=" text-almost-black text-[2.75rem] font-anton text-start desktopsm:text-[3.375rem] " >
        sign in <span className="hidden desktopsm:inline " >to your account</span>
      </span>
      <div className=" desktopsm:flex justify-end desktopsm:w-full desktopsm:mb-[1.5rem] " >
        <button className="" onClick={() => handleCancelBtn()} >
          <XCancelIcon stroke="#171718" width="40" height="38" />
        </button>
      </div>
    </div>

    {/* <div onSubmit={(e) => handleForm(e)} > */}
    <div className="" >

      <div className=" uppercase font-inter mb-[1rem] " >
        <div className=" text-[0.75rem] mb-[0.3rem] font-semibold text-locked-grey desktopsm:text-[0.9375rem] " >name</div>
        <input className=" bg-secondary-grey text-almost-black px-[0.9rem] rounded-[0.3rem] py-[1rem] placeholder:text-card-grey text-[1.0625rem] w-full " type="text"
          placeholder="name@exmple.com" value={formdata.email}
          onChange={(e) => setFormdata({ ...formdata, email: e.target.value })} />
      </div>

      <div className=" uppercase " >
        <span className=" font-inter text-locked-grey text-[0.75rem] font-semibold " >enter password</span>
        <div className=" flex flex-row bg-light-grey6 p-[0.5rem] w-full " >
          <input className="w-full text-almost-black font-inter px-[0.2rem] py-[0.2rem] bg-light-grey6 " type={seePassword ? "text" : "password"} value={formdata.password}
            placeholder="password" onChange={(e) => setFormdata({ ...formdata, password: e.target.value })} />
          <button onClick={() => handleEyeBtn()} >
            {seePassword ? <EyeIcon width="22" height="23" stroke="#232526" /> : <EyeIcon width="22" height="23" stroke="#232526" />}
          </button>
        </div>
      </div>

      <div className=" flex flex-row mt-[1rem] " >
        <Link to="/auth/forgotpassword"
          className=" text-very-dark-grey text-[0.875rem] desktopsm:text-[0.9375rem] font-semibold uppercase underline " >
          having trouble signing in?
        </Link>
        <GreaterThanIcon width="22" height="22" stroke="#171718" />
      </div>

      <button onClick={(e) => handleForm(e)} className=" mt-[2.5rem] rounded-[0.4rem] flex flex-row justify-between bg-almost-black w-full px-[1rem] py-[0.8rem] active:bg-slate-800 hover:bg-dark-grey uppercase " >
        <span className=" desktopsm:text-[1.1875rem] font-inter font-semibold text-[1.0625rem] text-white active:text-white " >
          sign in
        </span>
        <ArrowRightIcon width="22" height="22" stroke="#fff" />
      </button>

    </div >

    {/* for loading */}
    {loadingStatus.isLoading && <Loading message={loadingStatus.message} />}

  </div >
}

export default LoginScreen;