// 
import React, { useEffect } from "react";
import HeaderMenu from "../../components/headerMenu/headerMenu";
import { useAppSelector } from "../../utils/store/redux/hooks";
import { RootState } from "../../utils/store/redux/store";
// import { removeItemFromCart } from "../../utils/store/redux/slices/cartSlice";
// import { Link } from "react-router-dom";
import CartInfoLeft from './component/cartInfoLeft';
import CartOrderSummary from "./component/cartOrderSummary";
import ScrollToTopRoutine from "../../utils/routines/scrollToTopRoutine";

// 
const Cart: React.FC = () => {
  const cartItemsSelector = useAppSelector((state: RootState) => state.cartItemsSlice);
  // const dispatch = useAppDispatch();

  // function handleRemoveCartItemBtn(idx: string) {
  //   if (idx) dispatch(removeItemFromCart(idx));
  // }

  useEffect(() => {
    ScrollToTopRoutine();
  }, [])

  return <div >
    <HeaderMenu screen={'5'} />


    <div className=" tablet:w-[59rem] desktopmd:w-[87rem] desktoplg:w-[90rem] desktopsm:w-[77rem] tablet:mx-auto flex flex-col tablet:flex-row  pb-[2rem] " >
      <div className=" tablet:w-6/12" >
        <CartInfoLeft />
      </div>

      <div className=" tablet:w-6/12 mt-[3rem] tablet:pr-[1rem] tablet:mt-[2rem] " >
        <CartOrderSummary />
      </div>
    </div>

    {/* <div className="flex  flex-col justify-center text-center" >

      <div className=" flex justify-end bg-slate-300 py-[1rem] px-[0.5rem]" >
        <button disabled={cartItemsSelector.length > 0 ? false : true} className=" px-[0.6rem] py-[0.3rem] bg-green-600" onClick={() => handleCheckoutBtn()} >Checkout</button>
      </div>

      <div className=" px-[0.5rem] py-[1rem] w-full mx-auto max-w-[992px] " >
        {
          cartItemsSelector && cartItemsSelector.length > 0 && cartItemsSelector.map((item, idx) => (
            <div className=" mobilels:flex-row flex-col mb-[1rem] w-full flex items-center bg-green-600" key={idx} >
              <img className=" cursor-pointer w-full mobilels:w-[15rem]" src={item.image} />
              <div className=" px-[0.6rem] flex justify-center w-full flex-col " >
                <div >Price R{item.price * item.quantity}</div>
                <div >Quantity {item.quantity}</div>
                <div >Price R{item.price}</div>
                <button className=" bg-red-500 py-[0.2rem] px-[0.5rem] " onClick={() => handleRemoveCartItemBtn(item.id)} >remove</button>
              </div>
            </div>
          ))
        }

      </div>
    </div> */}


  </div >
}

export default Cart;