import React, { useEffect, useState } from "react";
import ShoppingCartIcon from "../../../utils/svg/shopCartIcon";
import ArrowRightIcon from "../../../utils/svg/arrowRigtIcon";
import { useNavigate } from "react-router-dom";
import { CartScreenRouterName } from "../../../utils/routers/routersNames";
import { useAppSelector } from "../../../utils/store/redux/hooks";
import { RootState } from "../../../utils/store/redux/store";
import { CART_ITEM } from "../../../utils/interfaces/item";

// 
const OrderSummary: React.FC = () => {
  const cartItemSelector = useAppSelector((state: RootState) => state.cartItemsSlice);
  const navigate = useNavigate() // 
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  // 
  function handleOrderBtn() {
    if (cartItemSelector.length > 0) {
      // store data in redux
      navigate(CartScreenRouterName);
    }
    else {
      alert("please add products to cart to proceed!");
    }
  }

  function showTotal() {
    const t: number = cartItemSelector.reduce((s: number, product: CART_ITEM) => s += product.total, 0)
    return t;
  }

  function showTotalWTax() {
    const t: number = cartItemSelector.reduce((s: number, product: CART_ITEM) => s += product.total, 0)
    return t;
  }

  function showTax() {
    return 0.0;
  }

  useEffect(() => {
    // 
    // 
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener('resize', handleResize);
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return <div className="  bg-very-dark-grey pb-[0.8rem] px-[0.7rem]
    tablet:flex-row tablet:flex tablet:justify-between  
    
    fixed z-10 bottom-0 left-0 right-0
  " >
    <div className="  
      tablet:justify-start tablet:text-[2.125rem] tablet:border-b-0
      desktopsm:text-[2.75rem]
      border-b-light-grey8 border-b-[0.1rem] pb-[0.7rem] pt-[0.1srem] flex justify-between flex-row font-anton uppercase items-center iphone8:text-[1.6875rem]  text-[1.3125rem] text-white" >

      <div className=" hidden tablet:block" >
        <ShoppingCartIcon
          width={screenSize.width >= 1280 ? "52" : "42"}
          height={screenSize.width >= 1280 ? "52" : "42"}
          stroke="#fff" />
      </div>
      {/*  */}
      <span className="tablet:px-[1rem]" > order summary</span>
      {/*  */}
      <div className=" tablet:hidden" >
        <ShoppingCartIcon
          width={screenSize.width >= 375 ? "30" : "24"}
          height={screenSize.width >= 375 ? "30" : "24"}
          stroke="#fff" />
      </div>
    </div>

    <div className="  py-[0.5rem] font-inter uppercase flex flex-row justify-between items-center" >
      <div className=" flex flex-col" >
        <div className=" text-[1.3125rem] flex flex-row ">
          <div className=" text-white font-semibold" >R {showTotalWTax()}</div>
          <div className=" text-marking-grey" >.00</div>
        </div>
        <span className=" text-[0.8125rem] text-marking-grey" >total (vat inc.)</span>
      </div>

      {/* vat */}
      <div className="hidden py-[0.9rem] 
       mobile640:flex mobile640:flex-row mobile640:text-[1.1875rem]
       tablet:px-[2rem] 
      " >
        {/*  */}
        <div className=" mobile640:flex-col mobile640:px-[1rem] border-l-light-grey8 border-l-[0.1rem] justify-evenly border-r-[0.1rem] border-r-light-grey8 text-[0.8125rem] uppercase font-inter flex flex-row" >
          <div className=" flex flex-row " >
            <span className="text-white font-semibold" >R {showTotal()}</span>
            <span className=" text-marking-grey" >.00</span>
          </div>
          <span className=" text-marking-grey " >exc. vat</span>
        </div>
        {/*  */}
        {/*  */}
        {/*  */}
        <div className=" px-[1rem] text-left  mobile640:flex-col justify-center text-[0.8125rem] uppercase font-inter flex flex-row items-center" >
          <div className=" flex flex-row  " >
            <span className="text-white font-semibold" >R {showTax()}</span>
            <span className=" text-marking-grey" >.00</span>
          </div>
          <span className=" px-[0.2rem] font-semibold text-marking-grey " >vat</span>
        </div>
      </div>
      {/*  */}
      {/*  */}
      <button
        // disabled={}
        onClick={() => handleOrderBtn()}
        className=" hover:bg-light-grey7 active:bg-faint-grey
          tablet:px-[1rem] text-almost-black
        text-[1.0625rem] iphone8:w-6/12 mobile640:w-auto font-semibold bg-white rounded-[0.3rem] py-[0.8rem] px-[0.6rem] uppercase flex flex-row justify-between items-center" >
        <span className=" iphone8:hidden " >order</span>
        <span className=" hidden iphone8:block tablet:pr-[2rem] " >place order</span>
        <ArrowRightIcon width="21" height="22" stroke="#232526" />
      </button>
    </div>

    {/*  */}
    <div className="hidden py-[0.9rem] mobile640:hidden iphone8:flex iphone8:flex-row border-t-[0.1rem] border-t-light-grey8" >
      {/*  */}
      <div className=" justify-evenly w-6/12 border-r-[0.1rem] border-r-light-grey8 text-[0.8125rem] uppercase font-inter flex flex-row items-center" >
        <div className=" flex flex-row" >
          <span className="text-white font-semibold" >R {showTotal()}</span>
          <span className=" text-marking-grey" >.00</span>
        </div>
        <span className=" text-marking-grey " >exc. vat</span>
      </div>
      {/*  */}
      <div className=" justify-center w-6/12 text-[0.8125rem] uppercase font-inter flex flex-row items-center" >
        <div className=" flex flex-row " >
          <span className="text-white font-semibold" >R {showTax()}</span>
          <span className=" text-marking-grey" >.00</span>
        </div>
        <span className=" px-[0.2rem] font-semibold text-marking-grey " >vat</span>
      </div>
    </div>
  </div>
}

export default OrderSummary;