// 
import React, { useEffect } from "react";
import HeaderMenu from "../../components/headerMenu/headerMenu";
// import { Link } from "react-router-dom";
import "./contact.css";
import FooterBottomPart from '../../components/footer/footer';
import ContactUsType from "./components/contactUsType";
import { CONTACTTYPEDATA } from "./data";
import FrequentlyAskedQuestion from "./components/faq";
import { CONTACT_IMAGE } from "../../utils/constants/constants";
import ScrollToTopRoutine from "../../utils/routines/scrollToTopRoutine";

const Contact: React.FC = () => {

  useEffect(() => {
    // dont delete this
    ScrollToTopRoutine();
  }, [])

  return <div >
    <HeaderMenu screen={"3"} />

    {/* Contact  */}
    <div className=" font-inter uppercase bg-white "  >

      {/* second part */}
      <div className=" max-w-[87rem] mx-auto px-[1rem] py-5 desktopsm:pb-[2.5rem] uppercase flex flex-row justify-between" >
        <div className=" desktopmd:w-8/12 desktopsm:w-7/12 w-full " >
          {/* | */}
          <div className=" text-[3.375rem] font-anton tablet:text-[5.875rem] desktopsm:text-[10rem] desktopmd:text-[11.25rem] p-0 m-0 tracking-0 " >
            contact us
          </div>

          <img
            className=" tablet768:hidden w-full flex flex-col justify-center text-center"
            src={CONTACT_IMAGE} />

          <div className=" flex flex-col justify-between w-full mt-[1rem] desktopsm:flex-row " >
            {/* types of contact us*/}
            {
              CONTACTTYPEDATA.map((item, idx) => (
                <div className=" desktopsm:w-[32%] " key={idx} >
                  <ContactUsType
                    idx={idx}
                    contactIcon={item.icon}
                    contactText={item.text}
                    rel={item.rel}
                    contactType={item.type} />
                </div>
              ))
            }
          </div>
        </div>

        {/*  */}
        <img
          className=" desktopmd:w-5/12 desktopsm:w-5/12 hidden pl-[1rem] w-full tablet768:flex tablet768:flex-col tablet768:justify-center tablet768:text-center"
          src={CONTACT_IMAGE} />
      </div>

      {/* first part */}
      {/* bg-very-dark-grey */}
      <div className=" px-[1rem] pt-[2rem] pb-[2rem] text-white bg-very-dark-grey " >
        <div className=" font-anton text-[3.125rem] w-full tablet:text-[6.875rem] "  >
          Want an answer now
          Check out our FAQ.
        </div>
        {/*  */}
        <div className=" text-[1.3125rem] tablet:text-[1.6875rem] mb-[3rem] " >
          here are some of the most frequently <br />
          asked questions by our customers
        </div>

        <FrequentlyAskedQuestion />
      </div>

      {/* third part of the screen */}
      <FooterBottomPart />
    </div>

  </div>
}

export default Contact