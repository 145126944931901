import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IgIcon } from "../../../utils/svg/ig_icon.svg"


const InstagramLink: React.FC = () => {
  const IgLink: string = "https://www.instagram.com/solidwatersa/";
  return <Link to={IgLink}
    target="_blank"
    className=" text-light-grey2 active:text-white hover:text-white flex hover:underline" >
    <IgIcon />
    <span className="  pl-[0.5rem]" >instagram</span>
  </Link>
}

export default InstagramLink;