import { API_BASE_URL } from "../../utils/constants/constants";

async function serviceGetImages(): Promise<any> {
  try {
    let _result = null;

    const requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow"
    };

    await fetch(`${API_BASE_URL}/staticfiles/getimages`, requestOptions)
      .then((response) => response.json())
      .then((result) => _result = result)
      .catch((error) => _result = { success: false, message: error.message, data: null });

    return _result;
  }
  catch (e: any) {
    return { success: false, message: e.message, data: null }
  }
}

export default serviceGetImages;