// use this type when you wants to change color of the SVG

import React from 'react';

interface DropletIconProps {
  stroke: string;
  width: string;
  height: string;
}

const DropletIcon: React.FC<DropletIconProps> = ({ stroke, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3507 19.6467C17.3507 24.1932 13.6651 27.8787 9.11872 27.8787C4.57231 27.8787 0.886719 24.1932 0.886719 19.6467C0.886719 14.8681 5.7884 9.13146 8.027 6.76072C8.62771 6.12455 9.60973 6.12455 10.2104 6.76072C12.449 9.13146 17.3507 14.8681 17.3507 19.6467Z" fill={stroke} />
    </svg>

  );
}

// 19, 29, 171718
export default DropletIcon;
