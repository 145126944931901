import React, { useEffect, useState } from "react";
import { AdminServiceGetProduct } from "../../services/admin/getProducts";
import { Link, useNavigate } from "react-router-dom";
import { AdminDashboardScreenRouterName, AdminScreenRouterName } from "../../utils/routers/routersNames";
import { AdminServiceDeleteProduct } from "../../services/admin/deleteProduct";
import Loading from "../../components/loading";
import { useAppDispatch, useAppSelector } from "../../utils/store/redux/hooks";
import { RootState } from "../../utils/store/redux/store";
import { localStorage_getItem } from "../../utils/store/localStorage/localStorage";
import { LS_ADMIN_PROFILE } from "../../utils/constants/constants";
import { setUserAdminProfile } from "../../utils/store/redux/slices/userAdminProfile";

interface PRODUCTS_INTERFACE {
  name: string;
  description: string;
  quantity: number[];
}

const PRODUCTS: PRODUCTS_INTERFACE[] = [
  { name: "500ml", description: "", quantity: [6, 24] },
  { name: "1l", description: "", quantity: [6, 12] },
  { name: "5l", description: "", quantity: [1, 4] }
]
// 
// 
const AdminViewProduct: React.FC = () => {
  const adminProfile = useAppSelector((state: RootState) => state.userAdminProfile);
  const [products, setProducts] = useState<any | null>(null);
  const [loadingStatus, setLoadingStatus] = useState({ status: false, message: "" });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function handleEditProduct(idx: number) {
    alert("disabled");
  }

  async function handleDeleteProduct(id: string) {
    setLoadingStatus({ ...loadingStatus, status: true, message: "loading" });
    try {
      const del_prod_res = await AdminServiceDeleteProduct(id, adminProfile.auth);
      if (del_prod_res && del_prod_res.success) {
        alert("Product deleted");
        navigate(AdminScreenRouterName)
      }
      else alert(`${del_prod_res.message}`);

      setLoadingStatus({ ...loadingStatus, status: false });
    }
    catch (err: any) {
      setLoadingStatus({ ...loadingStatus, status: false });
      alert(`${err.message}`);
    }
  }

  async function loadProducts() {
    const products = await AdminServiceGetProduct(adminProfile.auth);
    setProducts(products.data)
  }
  // 
  useEffect(() => {
    // check ls for data
    const profileData = localStorage_getItem(LS_ADMIN_PROFILE);
    if (profileData) {
      loadProducts();
      dispatch(setUserAdminProfile(profileData));
    }
    else {
      navigate(AdminScreenRouterName);
    }
  }, []);

  return <div className=" px-[1rem] pt-[1rem] text-center font-inter bg-white h-screen " >

    <div className=" max-w-[40rem] mx-auto" >

      <div className=" mb-[2rem] text-left" >
        <Link to={AdminDashboardScreenRouterName} className=" underline" >Back</Link>
      </div>

      <div className=" text-3xl uppercase text-left mb-[2rem] font-bold " >View Product</div>

      <div className="" >
        {products && products.length > 0 ? products.map((prod: any, idx: number) =>
          <div key={idx} className=" px-[1rem] mb-[1rem] py-[1rem] flex flex-row items-center bg-gray-300 justify-between" >
            <div className=" flex flex-col text-left" >
              <div className=" " >
                <span >Name</span>
                <span className=" font-bold " > {prod.name}</span>
              </div>
              <div className=" " >
                <span >Price</span>
                <span className=" font-bold " > R{prod.price}</span>
              </div>
              <div className=" " >
                <span >Quantity</span>
                <span className=" font-bold " > {prod.quantity}</span>
              </div>
              <div className=" " >
                <span >Available</span>
                <span className=" font-bold " > {prod.available}</span>
              </div>
            </div>
            {/*  */}
            <div className=" flex flex-col" >
              <button className=" text-white mb-[0.5rem] px-[1rem] py-[0.5rem] bg-slate-600 uppercase"
                onClick={() => handleEditProduct(idx)} >edit</button>
              <button className=" text-white px-[1rem] py-[0.5rem] bg-red-600 uppercase"
                onClick={() => handleDeleteProduct(prod.id)} >delete</button>
            </div>
          </div>)
          : <div className=" font-bold text-red-700" >
            No Products
          </div>
        }
      </div>


      {/*  */}

    </div>

    {loadingStatus && loadingStatus.status && <Loading message={loadingStatus.message} />}
  </div>
}

export default AdminViewProduct;