
const yocoSDK = new window.YocoSDK({
  publicKey: process.env.REACT_APP_YOCO_PUBLIC
});

export const YocoPayCreateTransaction = async (price, myCallback) => {
  try {
    price = parseFloat(price);
    const description = "solid water payments";
    // 
    await yocoSDK.showPopup({
      amountInCents: price * 100,
      currency: "ZAR",
      name: "Solid Water",
      description: description,
      callback: function (result) {
        myCallback(result);
      },
    });
  }
  catch (catch_err) { // log this to database
    // console.log(catch_err)
    return { message: catch_err.error, data: [] }
  }
}