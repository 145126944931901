import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import { supabase_upload } from '../services/supabase/supabase';
import { RootState } from '../utils/store/redux/store';
import { useAppDispatch, useAppSelector } from '../utils/store/redux/hooks';
import { AdminServiceStoreImage } from '../services/admin/storeImages';
import Loading from './loading';
import { localStorage_getItem } from '../utils/store/localStorage/localStorage';
import { LS_ADMIN_PROFILE } from '../utils/constants/constants';
import { setUserAdminProfile } from '../utils/store/redux/slices/userAdminProfile';
import { AdminScreenRouterName } from '../utils/routers/routersNames';
import { useNavigate } from 'react-router-dom';

interface ImageUploadProps {
  imageName: string;
  setSelectedImage: (v: string) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ imageName, setSelectedImage }) => {
  const [loadingStatus, setLoadingStatus] = useState<{ isLoading: boolean, message: string }>({ isLoading: false, message: "" });
  const adminProfileInfo = useAppSelector((state: RootState) => state.userAdminProfile);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const fileInputRef = useRef<any | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleUpload = () => {
    fileInputRef?.current?.click();
  };

  // 
  // 
  async function handleUploadImage() {
    try {
      setLoadingStatus({ ...loadingStatus, isLoading: true, message: "storing image in bucket" });
      if (adminProfileInfo && adminProfileInfo.auth) {
        if (selectedFile) {
          const upload_img_res = await supabase_upload(selectedFile, "admins_images_files");
          if (upload_img_res) {
            setLoadingStatus({ ...loadingStatus, message: "storing image in database" });
            // 
            const uploadRes = await AdminServiceStoreImage(upload_img_res, imageName, adminProfileInfo.auth);
            if (uploadRes && uploadRes.success === true) {
              setSelectedFile(null);
              setPreviewUrl(null);
              setSelectedImage("");
            }
            alert(`${uploadRes.message}`);
            setLoadingStatus({ ...loadingStatus, isLoading: false });
          }
          else {
            alert(`image not uploaded`);
            setLoadingStatus({ ...loadingStatus, isLoading: false });
          }
        }
      }
      else {
        setLoadingStatus({ ...loadingStatus, isLoading: false });
      }
    }
    catch (err: any) {
      setLoadingStatus({ ...loadingStatus, isLoading: false });
      alert(`${err.message}`);
    }
  }

  // 
  // 
  useEffect(() => {
    // check ls for data
    const profileData = localStorage_getItem(LS_ADMIN_PROFILE);
    if (profileData) {
      dispatch(setUserAdminProfile(profileData));
      // if (Object.keys(adminProfileInfo).length === 0) { }
    }
    else {
      navigate(AdminScreenRouterName);
    }
  }, []);
  // 
  // 
  // 
  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".jpg, .jpeg, .png, .gif, .webp, .avif" />

      {previewUrl &&
        <div className=' flex items-center justify-between py-[1rem] px-[1rem] ' >
          <img src={previewUrl} alt="Preview" style={{ maxWidth: '200px' }} />
          <div >
            <button className=' px-[1rem] py-[0.5rem] bg-slate-500' onClick={() => handleUploadImage()} >Upload Image</button>
          </div>
        </div>

      }

      <button className=" text-white w-full cursor-pointer bg-slate-500 px-[1rem] py-[0.5rem]"
        onClick={handleUpload}
      >
        Browse Images
      </button>


      {
        loadingStatus.isLoading && <Loading message={loadingStatus.message} />
      }
    </div>
  );
};

export default ImageUpload;