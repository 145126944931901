import React from "react";
import { useNavigate } from "react-router-dom";
import UserAccountIcon from "../../../utils/svg/userAccount";
import { DARK_MODE } from "../../../utils/constants/constants";
import { useAppSelector } from "../../../utils/store/redux/hooks";
import { RootState } from "../../../utils/store/redux/store";
import { AccountScreenRouterName, ProfileScreenRouterName } from "../../../utils/routers/routersNames";

interface HeaderMenuAccountBtnProps {
  screen: string,
}

const HeaderMenuAccountBtn: React.FC<HeaderMenuAccountBtnProps> = ({ screen }) => {
  const _userProfileSelector = useAppSelector((state: RootState) => state.userProfile);
  const navigate = useNavigate();

  function handleBtn() {
    if (Object.keys(_userProfileSelector).length > 0) {
      navigate(ProfileScreenRouterName);
    }
    else {
      navigate(AccountScreenRouterName)
    }
  }

  return <button onClick={() => handleBtn()}
    className={` hidden showMenuAccBtn:flex flex-row items-center font-inter py-1 px-[0.9rem] hover:underline text-almost-black ${DARK_MODE ? "text-almost-black hover:bg-light-grey2 bg-white active:bg-white hover:no-underline rounded-[3px] " : "text-faint-grey"} `} >

    <UserAccountIcon height="18" width="16" cssStyle={"text-black"} stroke={`#232526`} />

    <span className={` px-2 uppercase ${DARK_MODE ? "text-almost-black " : "text-faint-grey"} `} >Account</span>

  </button>
}

export default HeaderMenuAccountBtn;