// use this type when you wants to change color of the SVG

import React from 'react';

interface EmailIconProps {
  stroke: string;
  width: string;
  height: string;
}

const EmailIcon: React.FC<EmailIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.91394 13.4406L26.6443 31.2717C27.6038 32.1389 29.0641 32.1389 30.0236 31.2717L49.7539 13.4406M49.7539 46.2005L32.6179 28.3315M6.91394 46.2005L24.0499 28.3315" stroke={stroke} strokeWidth="5" />
    <rect x="6.63342" y="13.16" width="42.84" height="32.76" rx="2.52" stroke={stroke} strokeWidth="5" />
  </svg>

}

export default EmailIcon;
