import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { CART_ITEM } from "../../../interfaces/item";

const initialState: CART_ITEM[] = [];

export const cartItemsSlice = createSlice({
  name: 'cartItemsSlice',
  initialState,
  reducers: {
    addItemToCart: (state, action: PayloadAction<CART_ITEM>) => {
      // make sure the ID is unique
      const filter_prod = state.filter(i => i.id !== action.payload.id);
      filter_prod.push(action.payload)
      state = filter_prod;
      return state;
    },
    removeItemFromCart: (state, action: PayloadAction<string>) => {
      // add more possible validations here
      if (action.payload) {
        state = state.filter(i => i.id !== action.payload);
        return state;
      }
    },
    // removes all items from the cart
    clearCartItems: (state) => {
      state = [];
      return state;
    },
    // update item
    updateCartItem: (state, action: PayloadAction<{ id: string, quantity: number, total: number }>) => {
      // 
      const { id, quantity, total } = action.payload;
      state = state.map(
        (
          (item: CART_ITEM) => item.id === id ? { ...item, total: total, quantity: quantity } : item)
      )
      return state;
    }
  }
  // returns the number of items in a cart
  // numberItemInCart: (state) => { return state.length; },
})

export const { addItemToCart, removeItemFromCart, clearCartItems, updateCartItem } = cartItemsSlice.actions;
// selector
export const CartItemSelector = (state: RootState) => state.cartItemsSlice;

export default cartItemsSlice.reducer;