import React, { useEffect, useState } from "react"
import OrderQuantity from "./orderQuantity";
import getProducts from "../../../services/product/getProduct";

const OneLiter: React.FC = () => {
  const [numberBottles, setNumberBottles] = useState<number>(0);
  const [_products, _setProducts] = useState<any | null>(null);


  async function loadProducts() {
    // replace this code with redux in the root file
    const products = await getProducts();
    if (products && products.success == true) {
      const data = products['data'];
      let l1 = data.filter((prod: any) => prod.name === '1l');
      // add the cost of subtotal to each product
      l1 = l1.map((p: any) => ({ ...p, subtotal: 0 }));
      _setProducts(l1);
    }
    else {
    }
  }

  function sumTotals() {
    if (_products) {
      const _total = _products.reduce((sum: number, product: any) => sum += product.subtotal, 0);
      return _total;
    }
  }

  useEffect(() => {
    // 
    loadProducts();
    // 
  }, [])



  return <div className=" my-[1rem] bg-very-dark-grey  mobile:pl-[0.5rem] mobile:pr-[0.7rem] " >

    <div className=" mb-[2rem] px-[0.7rem] mobile:px-0 " >
      <div className=" desktopsm:text-[4.625rem] text-white text-[3.375rem] uppercase " >1l</div>
      <div className=" desktopsm:text-[2.125rem] font-inter font-bold uppercase text-[1.5rem] text-subtext-grey " >the big one</div>
    </div>

    {
      _products && _products.length > 0 && _products.map((product: any, idx: number) => <div key={idx} >
        <OrderQuantity
          numberBottles={numberBottles}
          setNumberBottles={setNumberBottles}
          currentProduct={product}
          productsSetter={_setProducts} // use redux here
        />
      </div>
      )
    }

    <div className=" border-t-[0.1rem] border-t-marking-grey py-[1rem] mt-[2rem] font-inter  flex flex-row justify-between  mx-[0.5rem] mobile:mx-0 " >
      <div className=" flex items-center text-marking-grey font-semibold " >
        <span className=" uppercase text-white text-[0.9375rem] font-bold " >{numberBottles}</span>
        <span className=" text-[0.5625rem]" > x</span>
        <span className=" uppercase text-[0.75rem] " >1l bottles</span>
      </div>
      {/*  */}
      <div className=" flex flex-row items-center text-marking-grey font-semibold " >
        <span className="uppercase" >sub</span>
        <span >=</span>
        <div className=" uppercase flex flex-row text-[1.3125rem] " >
          <span className=" text-white font-bold " >R{sumTotals()}</span>
          <span className=" " >.00</span>
        </div>
      </div>
    </div>
  </div >
}

export default OneLiter;