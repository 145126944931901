
const Dev: React.FC = () => {

  return <div className=" bg-white font-inter h-screen items-center text-center p-[2rem] " >
    {/* <PaymentInfoForm /> */}

    dev screen
  </div >
}

// tok_nBjgXCmXdWPZWEFQAptLR9ulVQ


export default Dev;