import React, { useEffect } from "react";
import HeaderMenu from "../../components/headerMenu/headerMenu";

import ShopLeftComponent from "./components/leftComponent";
import RightComponent from "./components/rightComponent";
import OrderSummary from "./components/orderSummary";
import { useAppDispatch } from "../../utils/store/redux/hooks";
import { setProducts } from "../../utils/store/redux/slices/products";
import serviceGetProducts from "../../services/product/getProduct";
import ScrollToTopRoutine from "../../utils/routines/scrollToTopRoutine";


const Shop: React.FC = () => {

  const dispatch = useAppDispatch();

  async function loadProducts() {
    const products = await serviceGetProducts();
    if (products && products.data && products.data.length > 0) {
      dispatch(setProducts(setProducts(products.data)))
    }
  }

  useEffect(() => {
    // scroll to to
    ScrollToTopRoutine();
    // 
    loadProducts();
  }, []);



  return <div className=" bg-white " >
    <HeaderMenu screen={'2'} />

    <div className=" pb-[2rem] flex flex-col tablet768:max-w-[45.5rem] tablet:flex tablet:flex-row desktoplg:max-w-[90rem] desktopmd:max-w-[87rem] desktopsm:max-w-[77rem] tablet:max-w-[59.5rem] mx-auto" >

      <div className=" tablet:w-6/12 tablet:mr-[0.5rem] desktopsm:w-5/12 desktopmd:w-6/12 " >
        <ShopLeftComponent />
      </div>

      <div className=" tablet:w-6/12 tablet:ml-[0.5rem] desktopsm:w-7/12 desktopmd:w-6/12 " >
        <RightComponent />
      </div>
    </div>

    {/* make this component stick to the button of the page */}
    <OrderSummary />
    {/*  */}
  </div>
}

export default Shop;