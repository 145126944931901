// use this type when you wants to change color of the SVG

import React from 'react';

interface ArrowUpIconProps {
  stroke: string;
  width: string;
  height: string;
}

const ArrowUpIcon: React.FC<ArrowUpIconProps> = ({ stroke, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.691406 15.6801L3.46326 18.452L13.8373 8.07794L13.8373 30.7861H17.7573L17.7573 8.07794L28.1314 18.452L30.9033 15.6801L15.7973 0.574219L0.691406 15.6801Z" fill={stroke} />
    </svg>

  );
}

// 31, 232526
export default ArrowUpIcon;
