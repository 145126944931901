// use this type when you wants to change color of the SVG
import React from 'react';

interface MinusIconProps {
  stroke: string;
  width: string;
  height: string;
}

const MinusIcon: React.FC<MinusIconProps> = ({ stroke, height, width }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.22908 10H15.7711" stroke={stroke} strokeWidth="1.71429" />
    </svg>


  );
}

// width="18" height="20" stroke="white" 

export default MinusIcon;
