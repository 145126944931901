import _supabase from "./configs";

export async function supabase_upload(file: File, bucket_sub_dir: string): Promise<any> {
  try {
    const bucket_name: string = "solidwater";
    const file_path: string = `${bucket_name}/${bucket_sub_dir}/${file?.name}`;
    // upsert : true -> override file is exist
    const { data, error } = await _supabase.storage.from(bucket_name).upload(file_path, file, { upsert: true });
    // get public url of image
    if (error === null && data && "path" in data && data.path) {
      const public_url = await _supabase.storage.from(bucket_name).getPublicUrl(`${data.path}`);
      const result = public_url?.data?.publicUrl;
      return result;
    }
    return null;
  }
  catch (err: any) {
    return null;
  }
}