import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../utils/store/redux/hooks";
import { RootState } from "../../utils/store/redux/store";
import ShoppingCartIcon from "../../utils/svg/shopCartIcon";
import UserAccountIcon from "../../utils/svg/userAccount";
import { AccountScreenRouterName, CartScreenRouterName, ProfileScreenRouterName } from "../../utils/routers/routersNames";
import XCancelIcon from "../../utils/svg/xCancelIcon";
import { DARK_MODE } from "../../utils/constants/constants";

const MENUITEMS = [
  { "title": "Home", "link": "/" },
  { "title": "Shop", "link": "/shop" },
  { "title": "Contact us", "link": "/contactus" },
]

interface MobileMenuInterfaceProps {
  callbackRoutine: (v: boolean) => void,
  callbackState: boolean,
}

const MobileMenu: React.FC<MobileMenuInterfaceProps> = ({ callbackRoutine, callbackState }) => {
  const _userProfileSelector = useAppSelector((state: RootState) => state.userProfile);
  const cartItemSlice = useAppSelector((state: RootState) => state.cartItemsSlice);
  const btn_bg_color = DARK_MODE ? "white" : "almost-black";
  const navigate = useNavigate();

  function handleNav(nav_link: string): void {
    // don't show nav menu
    callbackRoutine(!callbackState);
    // navigate to provided link page
    navigate(nav_link);
  }

  function handleCloseMenu() { callbackRoutine(false); }

  function handleAccountBtn() {
    if (Object.keys(_userProfileSelector).length > 0) {
      navigate(ProfileScreenRouterName);
    }
    else {
      navigate(AccountScreenRouterName)
    }
  }

  function handleCartBtn() { navigate(CartScreenRouterName); }

  return <div className=" w-full top-[5rem] left-0 text-left absolute tablet:hidden h-full
    tablet768:w-full tablet768:flex tablet768:top-0 tablet768:h-full tablet768:py-0 bg-light-grey6
  " >

    <div className="hidden bg-white tablet768:flex tablet768:w-7/12 tablet768:text-[1.875rem] py-[1rem] px-[1rem] text-almost-black " >
      solid water
    </div>

    <div className=" font-inter tablet:hidden h-full 
      tablet768:w-5/12 tablet768:flex tablet768:flex-col tablet768:justify-between " >
      <div className=" hidden tablet768:flex tablet768:p-[1rem] tablet768:items-end tablet768:justify-end " >
        <button onClick={() => handleCloseMenu()}
          className={` bg-${btn_bg_color} ${DARK_MODE ? "" : "hover:bg-grey-like1 active:bg-grey-like1"} hover:bg-grey-like1 active:bg-grey-like1 ml-[0.8rem] uppercase flex flex-row text-center rounded-[0.3rem] items-center px-[0.9rem] py-[0.5rem] tablet:hidden `} >
          <span className={` pr-2 text-[0.9375rem] font-inter font-bold ${DARK_MODE ? "" : "text-white"} `} >
            close
          </span>
          <div className=" " >
            <XCancelIcon width="16" height="16" stroke="#fff" />
          </div>
        </button>
      </div>

      <div className=" flex flex-col bg-light-grey6 pb-[3rem] " >
        {
          MENUITEMS && MENUITEMS.map((item, idx) => (
            <button className=" text-almost-black bg-light-grey6 border-b-[0.15rem] hover:text-white py-[0.8rem] px-[0.8rem] hover:bg-almost-black text-[1.6875rem] mobile:text-[2.375rem] uppercase text-left mb-[0.1rem]"
              onClick={() => handleNav(item.link)} key={idx} >
              {item.title}
            </button>
          ))
        }
      </div>

      <div className=" py-[0.7rem] bg-light-grey6 text-card-grey flex text-[1.5rem] font-normal flex-col uppercase pb-[2rem] " >

        <button className=" flex flex-row border-b-[0.15rem] items-center px-[0.8rem] text-left py-[0.8rem] hover:bg-white active:bg-slate-300 "
          onClick={() => handleAccountBtn()} >
          <UserAccountIcon cssStyle={"text-black"} stroke="#404244" width="28" height="29" />
          <span className=" px-[0.6rem] uppercase" >account</span>
        </button>
        {/*  */}
        <button className=" flex flex-row items-center justify-between px-[0.8rem] text-left py-[0.8rem] hover:bg-white active:bg-slate-300 "
          onClick={() => handleCartBtn()} >
          <div className=" flex flex-row items-center ">
            <ShoppingCartIcon stroke="#404244" width="28" height="29" />
            <span className=" uppercase px-[0.4rem] " >cart</span>
          </div>
          <span className=" bg-almost-black text-white px-[0.65rem] rounded-full " >{cartItemSlice.length}</span>
        </button>

      </div>
    </div>
    {/*  */}
  </div>

}

export default MobileMenu;