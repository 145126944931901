// 
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckOutScreenRouterName, ShopScreenRouterName } from "../../../../utils/routers/routersNames";
import XCancelIcon from "../../../../utils/svg/xCancelIcon";
import ArrowRightIcon from "../../../../utils/svg/arrowRigtIcon";
import EyeIcon from "../../../../utils/svg/eyeIcon";
import { validateEmail, validatePassword, validatePhone } from "../../../../utils/routines/validations";
import { Service_RegisterUser } from "../../../../services/auth/register";
import Loading from "../../../../components/loading";
import { useAppDispatch, useAppSelector } from "../../../../utils/store/redux/hooks";
import { setUserProfile } from "../../../../utils/store/redux/slices/userProfile";
// import { formatPhoneNumber } from "../../../../utils/routines/helps";
import { RootState } from "../../../../utils/store/redux/store";

interface RegisterScreenProps {
  showForm: {};
  setShowform: ({ }) => void
}

const RegisterScreen: React.FC<RegisterScreenProps> = ({ showForm, setShowform }) => {
  // redux
  const activitySlice = useAppSelector((state: RootState) => state.activity);
  const dispatch = useAppDispatch();
  const [formdata, setFormdata] = useState<any>({});
  // const [formatedPN, setFormatedPN] = useState<string>("");
  const navigate = useNavigate();
  const [seePassword, setSeePassword] = useState<boolean>(false);
  const [cseePassword, setSeeCPassword] = useState<boolean>(false);
  const [_errors, _setErrors] = useState({});
  const [loadingStatus, setLoadingStatus] = useState({ isLoading: false, message: "" });

  function handleCancelBtn() { setShowform({ ...showForm, signup: false }); }

  function handleEyeBtn(option: number): void {
    if (option === 1) {
      setSeePassword(!seePassword);
    }
    else if (option === 2) {
      setSeeCPassword(!cseePassword);
    }
  }

  function handlePhoneNumberChange(value: string): void {
    if (value.length < 11) {
      setFormdata({ ...formdata, phonenumber: value });
      // format phonenumber to have and show 000 000 0000 in input field
      // setFormatedPN(formatPhoneNumber(value));
    }
  }

  async function handleForm(event: any) {
    event.preventDefault();

    // validations, make sure all fields are filled || && formdata.cpassword !== undefined
    if (formdata.name !== undefined && formdata.email !== undefined && formdata.phonenumber !== undefined && formdata.password !== undefined) {
      // vlaidate email
      const isValidEmail = validateEmail(formdata.email);
      if (isValidEmail === 1) {
        // validate phone number(SA)
        const isValidPhoneNumber = validatePhone(formdata.phonenumber);
        if (isValidPhoneNumber === 1) {
          // check if the passwords are the same and validate password
          const isValidPassword = validatePassword(formdata.password)
          if (isValidPassword) { // if password and confirm password are the same
            // if (formdata.password === formdata.cpassword) {
            if (formdata.name && formdata.name.length >= 2) {
              const userdata = { ...formdata, isthirdparty: 0 }
              setLoadingStatus({ ...loadingStatus, isLoading: true, message: "loading" });
              const response = await Service_RegisterUser(userdata);
              dispatch(setUserProfile(response.data)); // set user in SM
              setLoadingStatus({ ...loadingStatus, isLoading: false, message: "" });
              // store data in state | local storage
              if (response.message === 'success') { // clear form
                setFormdata({ ...formdata, name: "", email: "", phonenumber: "", password: "" });
                // make user go back to shopping screen if user was shopping, 
                // otherwise make user go to shop page
                if (activitySlice.wasShopping === true) {
                  navigate(CheckOutScreenRouterName);
                }
                else {
                  navigate(ShopScreenRouterName);
                }
              }
              // make route go back page where user was, before here.
              alert(`${response.message}`);
            }
            else {
              alert("name must be more than 2 characters");
            }
            // }
            // else {
            //   alert("Password does not match retyped password");
            // }
          }
          else {
            alert(`${isValidPassword}`);
          }
        }
        else {
          alert(`${isValidPhoneNumber}`);
        }
      }
      else {
        alert(`${isValidEmail}`);
      }
    }
    else {
      alert("Please fill all fields");
    }
  }

  return <div className=" bg-white max-w-[992px] mx-auto px-[1rem] flex flex-col font-inter py-[1rem] " >

    <div className=" text-5xl mb-[2rem] flex flex-row justify-between uppercase desktopsm:items-start desktopsm:flex-col-reverse " >
      <span className=" text-almost-black text-[2.75rem] font-anton text-start desktopsm:text-[3.375rem] " >
        create an account
      </span>
      <div className=" desktopsm:flex justify-end desktopsm:w-full desktopsm:mb-[1.5rem] " >
        <button className="" onClick={() => handleCancelBtn()} >
          <XCancelIcon stroke="#171718" width="40" height="38" />
        </button>
      </div>
    </div>

    {/* <form onSubmit={(e) => handleForm(e)} > */}
    <div  >

      <div className=" uppercase font-inter mb-[1rem] " >
        <span className=" text-[0.75rem] mb-[0.3rem] font-semibold text-locked-grey desktopsm:text-[0.9375rem] " >name</span>
        <input className=" bg-secondary-grey text-almost-black px-[0.9rem] rounded-[0.3rem] py-[0.7rem] text-[1.0625rem] w-full  " type="text" value={formdata.name}
          placeholder="name" onChange={(e) => setFormdata({ ...formdata, name: e.target.value })} />
      </div>

      <div className=" uppercase font-inter mb-[1rem] " >
        <span className=" text-[0.75rem] mb-[0.3rem] font-semibold text-locked-grey desktopsm:text-[0.9375rem] " >email</span>
        <input className=" bg-secondary-grey text-almost-black px-[0.9rem] rounded-[0.3rem] py-[0.7rem] text-[1.0625rem] w-full  " type="text" value={formdata.email}
          placeholder="a@exmple.com" onChange={(e) => setFormdata({ ...formdata, email: e.target.value })} />
      </div>

      <div className=" uppercase font-inter mb-[1rem] " >
        <span className=" text-[0.75rem] mb-[0.3rem] font-semibold text-locked-grey desktopsm:text-[0.9375rem] " >phone number</span>
        <input className=" bg-secondary-grey text-almost-black px-[0.9rem] rounded-[0.3rem] py-[0.7rem] text-[1.0625rem] w-full  "
          type="text"
          value={formdata.phonenumber}
          placeholder="000 000 0000" onChange={(e) => handlePhoneNumberChange(e.target.value)} />
      </div>

      <div className=" uppercase mb-[1rem] " >
        <span className=" font-inter text-locked-grey text-[0.75rem] font-semibold desktopsm:text-[0.9375rem] " >password</span>
        <div className=" flex flex-row bg-light-grey6 p-[0.5rem] w-full " >
          <input className="w-full text-almost-black font-inter px-[0.2rem] py-[0.2rem] bg-light-grey6 "
            type={seePassword ? "text" : "password"} value={formdata.password}
            onChange={(e) => setFormdata({ ...formdata, password: e.target.value })}
            placeholder="type password" />
          {/*  */}
          <button onClick={() => handleEyeBtn(1)} >
            {seePassword ? <EyeIcon width="22" height="23" stroke="#232526" /> : <EyeIcon width="22" height="23" stroke="#232526" />}
          </button>
        </div>
      </div>

      {/* <div className=" uppercase " >
        <span className=" font-inter text-locked-grey text-[0.75rem] font-semibold desktopsm:text-[0.9375rem] " >confirm password</span>
        <div className=" flex flex-row bg-light-grey6 p-[0.5rem] w-full " >
          <input className="w-full text-almost-black font-inter px-[0.2rem] py-[0.2rem] bg-light-grey6 "
            type={cseePassword ? "text" : "password"} onChange={(e) => setFormdata({ ...formdata, cpassword: e.target.value })} placeholder="confirm password" />
          <button onClick={() => handleEyeBtn(2)} >
            {cseePassword ? <EyeIcon width="22" height="23" stroke="#232526" /> : <EyeIcon width="22" height="23" stroke="#232526" />}
          </button>
        </div>
      </div> */}

      <button onClick={(e) => handleForm(e)}
        className=" mt-[2.5rem] rounded-[0.4rem] flex flex-row justify-between bg-almost-black w-full px-[1rem] py-[0.8rem] active:bg-slate-800 hover:bg-dark-grey uppercase " >
        <span className=" desktopsm:text-[1.1875rem] font-inter font-semibold text-[1.0625rem] text-white active:text-white " >
          sign up
        </span>
        <ArrowRightIcon width="22" height="22" stroke="#fff" />
      </button>

      {/* </form > */}
    </div >

    {loadingStatus.isLoading && <Loading message={loadingStatus.message} />}

  </div >
}

export default RegisterScreen;