import ShoppingCartIcon from "../../../utils/svg/shopCartIcon";



const CartInfoLeft: React.FC = () => {

  return <div className=" px-[1rem] pt-[0.5rem]  " >
    <div className=" desktopsm:text-[7.75rem] tablet:text-[5.875rem] uppercase font-anton text-white text-[3.375rem] pb-[0.5rem]" >
      your cart
    </div>

    {/* cart icon here */}
    <div className=" bg-card-grey items-center flex justify-center py-[7rem]" >
      <ShoppingCartIcon width="168" height="176" stroke="#fff" />
    </div>

    <div className=" desktopsm:text-[1.6875rem] tablet:text-[1.5rem] pt-[1rem] font-inter text-[1.1875rem] uppercase text-marking-grey font-semibold " >
      Review your order and make any changes before you checkout
    </div>
  </div>
}


export default CartInfoLeft;