import { useEffect } from 'react';
import LogoText from './component/logoText';
import Products from './component/products';
import ProudlySA from './component/proudlySA';
import BottomBtns from './component/bottmButtons';
import TextImageStar from './component/textImageStar';
import Image2Component from './component/image2Component';
import Image1Component from './component/image1Component';
import WaterDropletText from './component/waterDropletText';
import getProducts from '../../services/product/getProduct';
import { LS_IMAGES } from '../../utils/constants/constants';
import FooterBottomPart from '../../components/footer/footer';
import { useAppDispatch } from '../../utils/store/redux/hooks';
import HeaderMenu from '../../components/headerMenu/headerMenu';
import serviceGetImages from '../../services/content/getImages';
import { setProducts } from '../../utils/store/redux/slices/products';
import { setContentImages } from '../../utils/store/redux/slices/contentImages';
import { localStorage_setItem } from '../../utils/store/localStorage/localStorage';

function formatImage(imagesArray: any) {
  try {
    const imagesObject = imagesArray.reduce((acc: any, image: any) => {
      acc[image.name] = image.url;
      return acc;
    }, {} as Record<string, string>);
    // 
    return imagesObject;
  }
  catch (err: any) { }
}

function HomePage() {
  const dispatch = useAppDispatch();

  async function loadProducts() {
    const products = await getProducts();
    if (products && products.data && products.data.length > 0) {
      dispatch(setProducts(setProducts(products.data)))
    }
  }

  async function loadImages(): Promise<void> {
    try {
      const loadImageRes = await serviceGetImages();
      // console.log(loadImageRes);
      if (loadImageRes && loadImageRes.success === true) {
        const images = formatImage(loadImageRes.data);
        dispatch(setContentImages(images));
        // store images in LS
        localStorage_setItem(LS_IMAGES, images);
      }
    }
    catch (err: any) { }
  }

  useEffect(() => {
    loadProducts();
    // 
    loadImages();
  }, []);

  // load product here and store in redux

  return (<div className="App bg-white ">
    <HeaderMenu screen={'1'} />

    {/*  */}
    <div className=' leading-none max-w-[18.5rem] mx-auto 
      mobile:max-w-[22rem] 
      mobilels:max-w-[27rem] 
      tablet768:max-w-[42.5rem] 
      tablet:max-w-[56.5rem] 
      desktopsm:max-w-[74.5rem] 
      desktopmd:max-w-[84.5rem]
    ' >
      <div className="  pt-[1.5rem] " >
        <ProudlySA />
        <div className=" italic bg-black text-yellow-500 py-[1rem] text-center mt-[2rem] mb-[4rem] uppercase tablet:pb-[2.5rem] font-inter text-[1.125rem] desktopsm:text-[1.25rem] font-medium " >
          R{15} delivery in {24} hours
        </div>
        <LogoText />
      </div>

      {/* delivery information */}

      {/* image and text */}
      <Image1Component />

      {/* text and water droplets */}
      <WaterDropletText />

      {/* text description and image */}
      <Image2Component />

      {/* image, text, plus icons and star icons */}
      <TextImageStar />

      {/* products images */}
      <Products />

      {/* buttons */}
      <BottomBtns />
      {/*  */}
    </div>

    {/* footer */}
    <FooterBottomPart />
    {/* footer */}
  </div>
  );
}

export default HomePage;
