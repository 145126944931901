import { API_BASE_URL } from "../../utils/constants/constants";

export async function CreateOrderPayment(data: any, order_id: string, transaction_id: string, products: any): Promise<any> {
  try {
    if (data) {
      let _results = null;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "order_id": order_id,
        "transaction_id": transaction_id,
        message: data.message,
        status: data.status,
        amountInCents: data.amountInCents,
        captureState: data.captureState,
        chargeId: data.chargeId,
        currency: data.currency,
        liveMode: data.liveMode,
        object: data.object,
        products: products,
      });

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${API_BASE_URL}/orders/createordertransactionpayments`, requestOptions)
        .then((response) => response.json())
        .then((result) => _results = result)
        .catch((error) => _results = { success: false, message: error.message, data: null });

      return _results;
    }
    else {
      return { success: false, message: "provide correct data", data: null }
    }
  }
  catch (err: any) {
    return { success: false, message: err.message, data: null }
  }
}