import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { RouterProvider, ScrollRestoration } from 'react-router-dom';
import AppRouter from './utils/routers/browserRouter';
import { Provider } from 'react-redux';
import { solidWaterReduxStore } from './utils/store/redux/store';


const root = ReactDOM.createRoot(
  document.getElementById('root')
);

const id = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

root.render(
  <React.StrictMode>
    <Provider store={solidWaterReduxStore} >
      <GoogleOAuthProvider clientId={id} >
        <div className='page_div' >
          <RouterProvider router={AppRouter} />
        </div>
        {/* <ScrollRestoration /> */}
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
