import React, { useEffect, useState } from "react";
import ArrowTopRight from "../../../utils/svg/arrowTopRight";
import LoginScreen from "../screens/login/login";
import RegisterScreen from "../screens/register/register";
import { AUTH_PAGE_IMAGE } from "../../../utils/constants/constants";
import GoogleAuthBtn from "./googleAuthBtn";
import GuestAccountBtn from "./guestAccountBtn";
// import { useAppSelector } from "../../../utils/store/redux/hooks";
// import { RootState } from "../../../utils/store/redux/store";
// import { localStorage_getItem } from "../../../utils/store/localStorage/localStorage";

const FORMS_OPTIONS = [
  { a: "sign up", b: "need an account", "path": "/auth/register", option: 1 },
  { a: "sign in", b: "got an account", "path": "/auth/login", option: 2 },
]

const SmallScreenView: React.FC = () => {
  const [showForms, setShowForms] = useState<any>({ signin: false, signup: false });
  const [screenHeight, setScreenHeight] = useState<number>(window.screen.height);
  // let images = useAppSelector((state: RootState) => state.contentImageSlice);
  // const [image, setImage] = useState<string | null>(null);


  function handleFormBtns(option: number) {
    if (option === 1) {
      setShowForms({ ...showForms, signup: !showForms?.signup })
    }
    else if (option === 2) {
      setShowForms({ ...showForms, signin: !showForms?.signin })
    }
  }

  useEffect(() => {
    setScreenHeight(window.screen.height);
    // if (images && Object.keys(images).length === 0) {
    //   images = localStorage_getItem(LS_IMAGES);
    //   setImage(images['accountpageimage']);
    // }
  }, [])

  return <div style={{ height: screenHeight * 0.899 }}
    className=" mx-auto max-w-[27.5rem] pt-[1rem] bg-almost-black tablet:hidden pb-[1rem] uppercase px-[0.7rem]" >

    <div className=" text-[3.375rem] text-white " >Account</div>
    {
      showForms && showForms.signin === true ? <LoginScreen showForm={showForms} setShowform={setShowForms} /> : showForms.signup === true ? <RegisterScreen showForm={showForms} setShowform={setShowForms} /> : <div >

        <div style={{ height: (screenHeight * 0.899) * 0.48 }} className=" bg-green-500 flex flex-col justify-center text-center" >
          <img className=" w-full h-full " alt="account page"
            src={AUTH_PAGE_IMAGE} />
        </div>

        {/* {
          image && image.length > 0 ?
            <div style={{ height: (screenHeight * 0.899) * 0.48 }} className=" bg-green-500 flex flex-col justify-center text-center" >
              <img className=" w-full h-full " alt="account page"
                src={image} />
            </div> : (<div style={{ height: (screenHeight * 0.899) * 0.38 }} className="card w-full bg-slate-800">
              <div className="image-placeholder placeholder bg-slate-100"></div>
            </div>)
        } */}

        <div className=" mt-[1rem]" >
          {
            FORMS_OPTIONS.map((item, idx) => (
              <button
                className=" bg-dark-grey active:bg-very-dark-grey hover:bg-very-dark-grey  w-full px-[0.8rem] py-[0.5rem] my-[1rem] text-[1.8125rem] flex flex-col uppercase "
                key={idx}
                onClick={() => handleFormBtns(item.option)}>
                <div className=" flex text-white flex-row justify-between items-center " >
                  <span >{item.b}</span>
                  <ArrowTopRight height="31.31" width="32" stroke={"#fff"} />
                </div>
                <span className=" text-light-grey2 text-[1.0625rem] font-normal font-inter text-left" >{item.a}</span>
              </button>
            ))
          }
        </div>
      </div>
    }

    {/* google auth bottom */}
    <div className=" font-inter mt-[2rem] border-t-[0.1rem] pt-[1rem] " >
      <GoogleAuthBtn />
    </div>

    <div className=" mb-[2rem] " >
      <GuestAccountBtn />
    </div>

  </div >
}


export default SmallScreenView;