import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/store/redux/hooks";
import { RootState } from "../../utils/store/redux/store";
import { AdminDashboardScreenRouterName, AdminScreenRouterName } from "../../utils/routers/routersNames";
import { Link, useNavigate } from "react-router-dom";
import { LS_ADMIN_PROFILE } from "../../utils/constants/constants";
import { localStorage_getItem } from "../../utils/store/localStorage/localStorage";
import { setUserAdminProfile } from "../../utils/store/redux/slices/userAdminProfile";
import ImageUpload from "../../components/uploadImage";

interface IMG_DESC_PROPS {
  name: string;
  desc: string;
  title: string;
}


const IMG_DESC: IMG_DESC_PROPS[] = [
  { name: "homepagefirstimage", desc: "", title: "home page first image" }, // home page
  { name: "homepagesecondimage", desc: "", title: "home page second image" },
  { name: "homepagethirdimage", desc: "", title: "home page third image" },
  { name: "homepage500mlimage", desc: "", title: "home page 500ml image" },
  { name: "homepage1limage", desc: "", title: "home page 1 image" },
  { name: "homepage5limage", desc: "", title: "home page 5l image" },
  { name: "shoppagefirstimage", desc: "", title: "shop page first image" }, // shop page
  { name: "shoppagesecondimage", desc: "", title: "shop page second image" },
  { name: "shoppagethirdimage", desc: "", title: "shop page third image" },
  { name: "contactuspageimage", desc: "", title: "contact us page image" }, // contact us page
  { name: "accountpageimage", desc: "", title: "account page image" }, // account page
];

const AdminImages: React.FC = () => {
  const adminProfileInfo = useAppSelector((state: RootState) => state.userAdminProfile);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const handleItemClick = (item: any) => {
    if (false)
      setSelectedItem(item);
  };


  useEffect(() => {
    // check ls for data
    const profileData = localStorage_getItem(LS_ADMIN_PROFILE);
    if (profileData) {
      dispatch(setUserAdminProfile(profileData));
    }
    else {
      navigate(AdminScreenRouterName);
    }
  }, []);

  return <div className=" font-inter px-[1rem] pt-[2rem] bg-white pb-[3rem] ">

    <div className=" mx-auto max-w-[40rem]" >
      {/*  */}

      <div className=" mb-[2rem] text-left" >
        <Link to={AdminDashboardScreenRouterName} className=" underline" >Back</Link>
      </div>

      <div className=" font-bold text-red-600" >
        can upload images, images removed from user website because of loading issues
      </div>

      <div className=" my-[1rem] py-[0.5rem] bg-slate-300 px-[1rem] " >

        <div className=" my-[1rem] w-full ">
          <select
            className="block bg-very-dark-grey w-full font-inter uppercase px-[0.8rem] py-[1rem] font-semibold appearance-none text-white border border-gray-400 hover:border-gray-500 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            value={selectedItem}
            onChange={(e) => handleItemClick(e.target.value)}
          >
            <option className=' uppercase' value="">Pick Image</option>

            {
              IMG_DESC && IMG_DESC.length > 0 && IMG_DESC.map((image: IMG_DESC_PROPS, idx: number) =>
                <option className=' uppercase' value={image.name}>{image.title}</option>)
            }
          </select>
        </div>

        {
          selectedItem && selectedItem.length > 0 &&
          <ImageUpload setSelectedImage={setSelectedItem} imageName={selectedItem} />
        }

      </div>
    </div>
  </div>
}


export default AdminImages;