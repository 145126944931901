import { API_BASE_URL } from "../../utils/constants/constants";

export async function AdminServiceGetProduct(auth: string): Promise<any> {
  try {
    let _results = null;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth}`);

    const requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    await fetch(`${API_BASE_URL}/administrators/getproducts`, requestOptions)
      .then((response) => response.json())
      .then((result) => _results = result)
      .catch((error) => _results = { success: false, message: error.message, data: null });

    return _results;
  }
  catch (e: any) {
    return { success: false, message: e.message, data: [] }
  }
}