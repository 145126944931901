import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface billingAddressSliceInterface {
  username: string;
  email: string;
  phoneNumber: string;
  country: string;
  city: string;
  buildingType: string;
  zipCode: string;
  suburb: string;
  streetAddress1: string;
  streetAddress2: string | null;
  shippingMethod: string | null;
}

const initialState: Partial<billingAddressSliceInterface> = {};

export const billingAddressSlice = createSlice({
  name: 'billingAddressSlice',
  initialState,
  reducers: {
    assignBillingAddress: (state, action: PayloadAction<Partial<billingAddressSliceInterface>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    clearBillingAddress: (state) => {
      state = {};
      return state;
    },
    editShippingMethod: (state, action: PayloadAction<string>) => {
      state = { ...state, shippingMethod: action.payload }
      return state;
    }
  }
})

export const { assignBillingAddress, clearBillingAddress, editShippingMethod } = billingAddressSlice.actions;
// selector
export const useBillingAddress = (state: RootState) => state.billingAddressSlice;

export default billingAddressSlice.reducer;