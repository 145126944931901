import React, { useState } from "react";
import AdminHeader from "./header";

export default function ChangeDeliverAmount(): React.JSX.Element {
  const [amount, setAmount] = useState<number>(0);



  function handleChangeAmount(e: React.ChangeEvent<HTMLInputElement>) {
    try {
      let val: string = e.target.value.trim();
      if (val !== "" || !isNaN(Number(val))) {
        setAmount(Number(val));
      }
    }
    catch (err: any) {
      alert("issues handling delivery amount");
    }
  }

  function handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      console.log(amount);
    }
    catch (e: any) {
      alert(`form submit: ${e.message}`);
    }
  }


  return <div className=" py-[2rem] px-[1rem] h-screen font-inter bg-white" >

    <AdminHeader />

    <div className=" mx-auto max-w-[40rem]" >
      <div className=" text-2xl" >Change Delivery Amount</div>

      <form onSubmit={(e) => handleFormSubmit(e)} className="my-[3rem] " >
        <div className=" flex flex-col " >
          <label className=" text-[0.9rem] text-almost-black uppercase mb-[0.3rem] " >Delivery Amount</label>
          <input
            min={0}
            max={100}
            className=" bg-[#e9e9ed] px-[0.8rem] py-[0.6rem] rounded-[0.5rem] font-inter "
            placeholder="Enter Develiry Amount"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeAmount(e)}
            type="number" />
        </div>

        <button
          className=" uppercase font-semibold mt-[2rem] bg-black w-full text-white py-[0.8rem] rounded-[0.3rem] hover:bg-slate-700 active:bg-green-950 "
          type="submit" >Change Amount</button>
      </form>
    </div>
  </div>
}