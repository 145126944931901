import React from "react";
import ArrowTopRight from "../../../utils/svg/arrowTopRight";
import { useNavigate } from "react-router-dom";
import { CartScreenRouterName } from "../../../utils/routers/routersNames";
import ArrowUpperLeft from "../../../utils/svg/arrowUpperLeft";


const CheckOutLeftComponent: React.FC = () => {
  const navigate = useNavigate();

  function handleGoBackBtn() {
    navigate(CartScreenRouterName);
  }

  return <div className="" >

    <div className=" text-[3.375rem] tablet:text-[5.875rem] desktopsm:text-[7.75rem] font-anton uppercase text-white" >
      check out</div>

    <div className=" bg-card-grey flex justify-center rounded-[0.3rem] py-[7rem] " >
      <ArrowTopRight stroke="#fff" width="112" height="112" />
    </div>

    <div className=" text-[1.1875rem] tablet:text-[1.5rem] uppercase font-inter my-[1rem] desktopsm:text-[1.6875rem] text-marking-grey " >
      Fill in your shipping information and payment info to continue with your order
    </div>

    <button onClick={() => handleGoBackBtn()}
      className=" tablet:text-[1.3125rem] hover:bg-dark-grey active:bg-dark-grey bg-very-dark-grey py-[0.8rem] px-[1.5rem] desktopsm:mt-[2rem] text-subtext-grey underline flex flex-row items-center desktopsm:text-[1.5rem] font-inter uppercase" >
      <ArrowUpperLeft height="35" width="35" stroke={"#BEC2C6"} />
      <span className=" pl-[0.5rem]" > go back to cart page</span>
    </button>

  </div>
}

export default CheckOutLeftComponent;