import React from "react";
import { useNavigate } from "react-router-dom";
import { ContactUsScreenRouterName, ShopScreenRouterName } from "../../../utils/routers/routersNames";
import ArrowTopRight from "../../../utils/svg/arrowTopRight";


const BottomBtns: React.FC = () => {
  const navigate = useNavigate();
  const style: string = `py-[1.1rem] px-[0.9rem] w-full bg-secondary-grey text-almost-black 
      hover:bg-almost-black hover:text-white active:bg-grey-like2
      flex flex-row justify-between items-center mb-[1rem]`


  return <div className=" py-[2rem] " >
    <button className={style}
      onClick={() => navigate(ShopScreenRouterName)} >
      <span className=" uppercase text-[2.375rem] " >shop now</span>
      <ArrowTopRight width="40" height="40" stroke={"#232526"} />
    </button>

    {/*  */}
    <button className={style}
      onClick={() => navigate(ContactUsScreenRouterName)} >
      <span className=" uppercase text-[2.375rem] " >contact us</span>
      <ArrowTopRight width="40" height="40" stroke={"#232526"} />
    </button>

    {/*  */}
    <button className={style}
      onClick={() => navigate(`${ContactUsScreenRouterName}#faq`)} >
      <span className=" uppercase text-[2.375rem] " >faq</span>
      <ArrowTopRight width="40" height="40" stroke={"#232526"} />
    </button>
  </div>
}


export default BottomBtns