import React, { useState } from "react";
import BackProfileScreenBtn from "../component/backToProfileScreenBtn";
import ScreenName from "../component/screenName";
import WhiteBgCheckBox from "../../../utils/svg/whiteBgCheckbox";
import WhiteBgUnCheckBox from "../../../utils/svg/whiteBgUnCheckbox";

const AccountNotification: React.FC = () => {
  const [btnStatus, setBtnStatus] = useState({ whatsapp: false, email: true, sms: false });

  function handleBtnClicked(btnNum: number) {
    if (btnNum === 1) {
      setBtnStatus({ ...btnStatus, whatsapp: !btnStatus.whatsapp })
    }
    else if (btnNum === 2) {
      setBtnStatus({ ...btnStatus, email: !btnStatus.email })
    }
    else if (btnNum === 3) {
      setBtnStatus({ ...btnStatus, sms: !btnStatus.sms })
    }
  }

  return <div className=" bg-light-grey6 pb-[1rem] h-screen  " >
    <div className="mobile:max-w-[22.5rem] mobile:mx-auto mobilels:max-w-[25rem] tablet768:max-w-[31.25rem] tablet:max-w-[31.25rem]" >
      {/*  */}
      <div className=" w-full mobile:px-0 px-[1rem] pt-[2rem] pb-[1rem] " >
        <BackProfileScreenBtn />
      </div>

      {/* screen name */}
      <ScreenName screenName="notifications" />

      <div className=" bg-white px-[1rem] py-[1rem] " >
        {/* whatsapp */}
        <button className={` ${btnStatus.whatsapp ? "bg-almost-black text-white" : " bg-secondary-grey text-almost-black "} w-full font-semibold flex flex-row py-[0.7rem] px-[0.5rem] rounded-[0.3rem] mb-[1rem] `}
          onClick={() => handleBtnClicked(1)} >
          {btnStatus.whatsapp ? <WhiteBgCheckBox width="23" height="23" stroke="#171718" /> : <WhiteBgUnCheckBox width="23" height="23" stroke="#979CA0" />}
          <span className=" font-inter uppercase px-[0.5rem] text-[0.9375rem] desktopsm:text-[1.0625rem] " >whatsapp</span>
        </button>

        {/* email */}
        <button className={` ${btnStatus.email ? "bg-almost-black text-white" : " bg-secondary-grey text-almost-black "} w-full   font-semibold flex flex-row py-[0.7rem] px-[0.5rem] rounded-[0.3rem] mb-[1rem] `}
          onClick={() => handleBtnClicked(2)} >
          {btnStatus.email ? <WhiteBgCheckBox width="23" height="23" stroke="#171718" /> : <WhiteBgUnCheckBox width="23" height="23" stroke="#979CA0" />}
          <span className=" font-inter uppercase px-[0.5rem] text-[0.9375rem] desktopsm:text-[1.0625rem] " >email</span>
        </button>
        {/* sms */}
        <button className={` ${btnStatus.sms ? "bg-almost-black text-white" : " bg-secondary-grey text-almost-black "} w-full font-semibold flex flex-row py-[0.7rem] px-[0.5rem] rounded-[0.3rem] `}
          onClick={() => handleBtnClicked(3)} >
          {btnStatus.sms ? <WhiteBgCheckBox width="23" height="23" stroke="#171718" /> : <WhiteBgUnCheckBox width="23" height="23" stroke="#979CA0" />}
          <span className=" font-inter uppercase px-[0.5rem] text-[0.9375rem] desktopsm:text-[1.0625rem] " >sms</span>
        </button>
      </div>
      {/*  */}
    </div>

    {/*  */}
  </div>
}

export default AccountNotification;