import { Link, useLocation, useNavigate } from "react-router-dom";
import { AdminScreenRouterName, AdminViewOrdersScreenRouterName } from "../../utils/routers/routersNames";
import { useEffect, useState } from "react";
import { AdminServiceGetOrderProducts } from "../../services/admin/getOrderProducts";
import { AdminServiceGetOrderBillingAddress } from "../../services/admin/getOrderBillingAddress";
import { useAppDispatch, useAppSelector } from "../../utils/store/redux/hooks";
import { RootState } from "../../utils/store/redux/store";
import { setUserAdminProfile } from "../../utils/store/redux/slices/userAdminProfile";
import { localStorage_getItem } from "../../utils/store/localStorage/localStorage";
import { LS_ADMIN_PROFILE } from "../../utils/constants/constants";

//
const AdminOrderDetails: React.FC = () => {
  const adminProfile = useAppSelector((state: RootState) => state.userAdminProfile);
  const [orderProducts, setOrderProducts] = useState<any | any>(null);
  const [orderBillAddr, setOrderBillAddr] = useState<any | any>(null);
  const [orderProductsTotalAmount, setOrderProductsTotalAmount] = useState<number>(0);
  const use_location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // 
  const { id } = (use_location.state as { id: string }) || { id: null };

  // 
  async function getOrderBillingAddress() {
    const orderAddress = await AdminServiceGetOrderBillingAddress(id, adminProfile.auth);
    console.log(orderAddress);
    if (orderAddress && orderAddress.success) {
      setOrderBillAddr(orderAddress.data);
    }
  }
  // 
  async function loadOrderProducts() {
    const orderProductsRes = await AdminServiceGetOrderProducts(id, adminProfile.auth);
    if (orderProductsRes && orderProductsRes.success && orderProductsRes.data.length > 0) {
      const ordersProds = orderProductsRes.data
      const ota = ordersProds.reduce((sum: number, op: any) => sum += op.total, 0);
      setOrderProductsTotalAmount(ota);
      setOrderProducts(ordersProds);
    }
  }
  // 
  useEffect(() => {
    // check ls for data
    const profileData = localStorage_getItem(LS_ADMIN_PROFILE);
    if (profileData) {
      // load products. load address
      if (id) {
        loadOrderProducts();
        getOrderBillingAddress();
      }
      else {
        navigate(AdminViewOrdersScreenRouterName);
      }
      dispatch(setUserAdminProfile(profileData));
      // if (Object.keys(adminProfileInfo).length === 0) { }
    }
    else {
      navigate(AdminScreenRouterName);
    }
  }, [id]);

  // 
  return <div className=" font-inter px-[1rem] pt-[2rem] bg-white pb-[3rem] " >

    <div className=" mx-auto max-w-[40rem]" >

      <div className=" mb-[2rem] text-left" >
        <Link to={AdminViewOrdersScreenRouterName} className=" underline" >Back</Link>
      </div>

      <div className=" text-3xl uppercase text-left mb-[2rem] font-bold " >
        View Order Details
      </div>

      {/* order products */}
      <div className=" border-[0.1rem] rounded-[0.5rem] px-[1rem] py-[0.5rem]" >
        <div className=" font-bold text-xl mb-[1rem] flex flex-col " >
          <span >Order Total Amount</span>
          <span className=" text-green-600 " >R{orderProductsTotalAmount}</span>
        </div>
        <div className="" >
          {
            orderProducts && orderProducts.length > 0 ? orderProducts.map((product: any, idx: number) =>
              <button key={idx}
                className=" border-[0.1rem] rounded-[0.5rem] w-full px-[1rem] mb-[1rem] py-[1rem] bg-gray-300 " >
                {/*  */}
                <div className=" mb-[0.5rem] text-left flex flex-col" >
                  <span className=" uppercase font-bold" >{product.name}</span>
                </div>

                <div className="flex flex-row items-center justify-between" >
                  <div >
                    <div className=" mb-[0.5rem] text-left flex flex-col" >
                      <span >Order Quantity</span>
                      <span className=" font-bold" >{product.quantity}</span>
                    </div>
                    {/*  */}
                    <div className=" mb-[0.5rem] text-left flex flex-col" >
                      <span >Pack</span>
                      <span className=" font-bold" >{product.pack}</span>
                    </div>
                  </div>

                  {/*  */}
                  <div >
                    <div className=" mb-[0.5rem] text-left flex flex-col " >
                      <span >Price</span>
                      <span className=" font-bold" >R{product.price}</span>
                    </div>

                    <div className=" mb-[0.5rem] text-left flex flex-col " >
                      <span >Total</span>
                      <span className=" text-green-600 font-bold" >R{product.total}</span>
                    </div>
                  </div>
                </div>
              </button>
            )
              : (
                <div className=" pt-[3rem] font-bold text-red-700" >
                  No Order Products
                </div>
              )
          }
        </div>
      </div>

      {/* order billing address */}
      <div className=" mt-[1rem] border-[0.1rem] rounded-[0.5rem] px-[1rem] py-[0.5rem]" >
        <div className=" font-bold text-xl mb-[1rem] " >Order Billing Address</div>
        <div className="" >
          {
            orderBillAddr && Object.keys(orderBillAddr).length > 0 ?
              (<div className=" border-[0.1rem] rounded-[0.5rem] w-full px-[1rem] mb-[1rem] py-[1rem] bg-gray-300 flex flex-row items-center justify-between ">
                <div className="" >
                  <div className=" mb-[0.5rem] text-left flex flex-col" >
                    <span >Email</span>
                    <span className=" font-bold" >{orderBillAddr.email}</span>
                  </div>
                  {/*  */}
                  <div className=" mb-[0.5rem] text-left flex flex-col" >
                    <span >Username</span>
                    <span className=" font-bold" >{orderBillAddr.username}</span>
                  </div>
                  {/*  */}
                  <div className=" mb-[0.5rem] text-left flex flex-col" >
                    <span >Address</span>
                    <span className=" font-bold" >{orderBillAddr.streetaddress},{orderBillAddr.streetaddress1}</span>
                  </div>
                  {/*  */}
                  <div className=" mb-[0.5rem] text-left flex flex-col" >
                    <span >City</span>
                    <span className=" font-bold" >{orderBillAddr.city}</span>
                  </div>
                  {/*  */}
                  <div className=" mb-[0.5rem] text-left flex flex-col" >
                    <span >Suburb</span>
                    <span className=" font-bold" >{orderBillAddr.suburb}</span>
                  </div>
                </div>

                {/*  */}
                <div className="" >
                  <div className=" mb-[0.5rem] text-left flex flex-col " >
                    <span >Phone Number</span>
                    <span className=" font-bold" >{orderBillAddr.phonenumber}</span>
                  </div>
                  {/*  */}
                  <div className=" mb-[0.5rem] text-left flex flex-col " >
                    <span >Post Code</span>
                    <span className=" font-bold" >{orderBillAddr.postcode}</span>
                  </div>
                  {/*  */}
                  <div className=" mb-[0.5rem] text-left flex flex-col " >
                    <span >Ship Method</span>
                    <span className=" font-bold" >{orderBillAddr.shipmethod}</span>
                  </div>
                  {/*  */}
                  <div className=" mb-[0.5rem] text-left flex flex-col " >
                    <span >Country</span>
                    <span className=" font-bold" >{orderBillAddr.country}</span>
                  </div>
                  {/*  */}
                  <div className=" mb-[0.5rem] text-left flex flex-col " >
                    <span >Building Type</span>
                    <span className=" font-bold" >{orderBillAddr.buildingtype}</span>
                  </div>
                </div>
              </div>)
              : (
                <div className=" pt-[3rem] font-bold text-red-700" >
                  No Order Billing Address
                </div>
              )
          }
        </div>
      </div>
    </div >
  </div >
}

export default AdminOrderDetails;