import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LinkinIcon } from "../../../utils/svg/linkedin_icon.svg"

const LinkedInLink: React.FC = () => {

  return <Link to="/contactus" target="_blank" className=" text-light-grey2 active:text-white hover:text-white flex hover:underline" >
    <LinkinIcon />
    <span className=" flex pl-[0.5rem]" > linkedin</span>
  </Link>
}

export default LinkedInLink;