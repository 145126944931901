// import bcrypt from "bcryptjs";
import { sha256 } from "crypto-hash";

export async function hash_text(text) {
  let hashedText = null;
  try {
    hashedText = await sha256(text);
  } catch (err) { } finally {
    return hashedText;
  }
}