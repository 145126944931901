// use this type when you wants to change color of the SVG
import React from 'react';

interface Quantity4IconProps {
  stroke: string;
  width: string;
  height: string;
}

const Quantity4Icon: React.FC<Quantity4IconProps> = ({ stroke, height = "48", width = "48" }) => {
  // const Quantity4Icon: React.FC = () => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.5" cy="20.5" r="2" fill={stroke} />
      <circle cx="27.5" cy="20.5" r="2" fill={stroke} />
      <circle cx="20.5" cy="27.5" r="2" fill={stroke} />
      <circle cx="27.5" cy="27.5" r="2" fill={stroke} />
    </svg>
  );
}
export default Quantity4Icon;
