import React, { useEffect, useState } from "react";
import { BUILDING_TYPES } from "../../../utils/constants/constants";
import MobileBuildingTypeSelect from "./mobileBuildingTypeSelect";
import { useAppDispatch, useAppSelector } from "../../../utils/store/redux/hooks";
import { RootState } from "../../../utils/store/redux/store";
import { assignBillingAddress } from "../../../utils/store/redux/slices/billingAddress";
import { validateEmail, validatePhone } from '../../../utils/routines/validations';


const BillingAddressForm: React.FC = () => {
  const userProfile = useAppSelector((state: RootState) => state.userProfile);
  const [isBuildingType, setIsBuildingType] = useState<number | null>(null);
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { username, email, phone } = userProfile;
    setFormData({ ...formData, username: username, email: email, phone: phone });
  }, []);

  function handleUserNameChange(value: string): void {
    if (value && value.length > 1) {
      // redux
      dispatch(assignBillingAddress({ username: value }));
      setErrors({ ...errors, name: null });
    }
    else {
      setErrors({ ...errors, name: "Name must be nore than 2 letters" });
    }
    setFormData({ ...formData, username: value });
  }

  function handleZipCodeChange(value: string): void {
    setFormData({ ...formData, zipCode: value });
    // redux
    dispatch(assignBillingAddress({ zipCode: value }));
  }

  function handleSuburbChange(value: string) {
    setFormData({ ...formData, suburb: value });
    // redux
    dispatch(assignBillingAddress({ suburb: value }));
  }

  function handleCityChange(value: string) {
    setFormData({ ...formData, city: value });
    // redux
    dispatch(assignBillingAddress({ city: value }));
  }

  function handlePhoneNumberChange(value: string) {
    const isPhone = validatePhone(value);
    if (isPhone === 1) {
      // redux
      dispatch(assignBillingAddress({ phoneNumber: value }));
      setErrors({ ...errors, phoneNumber: null });
    }
    else {
      setErrors({ ...errors, phoneNumber: isPhone });
    }
    setFormData({ ...formData, phoneNumber: value });
  }

  // function handleCountryChange(value: string) { setFormData({ ...formData, suburb: value }) }

  // email
  function handleEmailChange(value: string) {
    const isValidEmail = validateEmail(value);
    if (isValidEmail && isValidEmail === 1) {
      // redux
      dispatch(assignBillingAddress({ email: value }));
      setErrors({ ...errors, email: null });
    }
    else {
      setErrors({ ...errors, email: isValidEmail });
    }
    setFormData({ ...formData, email: value });
  }

  function handleStreetAddress(stn: number, value: string): void {
    if (stn === 1) {
      setFormData({ ...formData, streetAddress1: value });
      // redux
      dispatch(assignBillingAddress({ streetAddress1: value }));
    }
    else if (stn === 2) {
      setFormData({ ...formData, streetAddress2: value })
      // redux
      dispatch(assignBillingAddress({ streetAddress2: value }));
    }
  }

  function handleBuildingTypeBtns(idx: number) {
    if (isBuildingType && isBuildingType >= 0 && isBuildingType === idx) {
      setIsBuildingType(null);
      return
    }
    setIsBuildingType(idx);
    const building: string = BUILDING_TYPES[idx - 1];
    setFormData({ ...formData, buildingType: building });
    // redux
    dispatch(assignBillingAddress({ buildingType: building }));
  }

  return <div className=" bg-white px-[1rem] mt-[1rem] pb-[2rem]" >

    <div className=" text-[2.5rem] mb-[2rem] tablet:text-[3.375rem] desktopsm:text-[3.375rem] uppercase font-anton text-almost-black" >
      billing address
    </div>

    {/* names */}
    <div className=" text-light-grey 
      tablet:flex tablet:flex-col tablet:justify-between
      desktopsm:flex desktopsm:flex-row 
    " >
      <div className=" desktopsm:pr-[0.3rem] desktopsm:w-12/12 tablet:w-full font-inter uppercase flex flex-col font-semibold" >
        <label className=" text-[0.9375rem] mb-[0.4rem] " >name *</label>
        <input onChange={(e) => handleUserNameChange(e.target.value)}
          placeholder="Eddy" value={formData.username}
          className=" bg-secondary-grey px-[0.8rem] py-[0.8rem] rounded-[0.2rem] uppercase font-inter 
            desktoplg:w-full
          "
        />
        {errors && errors?.name && < div className=" normal-case font-normal font-inter pt-[0.3rem] text-red-600 text-[0.75rem] " > {errors?.name} </div>}
      </div>
      {/* 
      <div className=" desktopsm:pl-[0.3rem] desktopsm:w-6/12 desktopsm:mt-[0rem] tablet:mt-[1rem] tablet:w-full font-inter uppercase flex flex-col font-semibold" >
        <label className=" text-[0.9375rem] mb-[0.4rem] " >last name</label>
        <input onChange={(e) => setFormData({ ...formData, "lastname": e.target.value })}
          placeholder="James"
          className=" bg-secondary-grey px-[0.8rem] py-[0.8rem] rounded-[0.2rem] uppercase font-inter" />
      </div> */}
    </div>

    {/* email */}
    <div className=" text-light-grey mt-[1rem] desktopsm:w-full font-inter uppercase flex flex-col font-semibold" >
      <label className=" text-[0.9375rem] mb-[0.4rem] " >Email</label>
      {/* <div className=" flex flex-row justify-between items-center bg-white border-[0.1rem] rounded-[0.2rem]" > */}
      <input placeholder="email"
        value={formData.email} type={"email"}
        onChange={(e) => handleEmailChange(e.target.value)}
        className=" bg-secondary-grey w-full px-[0.8rem] rounded-[0.2rem] py-[0.8rem] uppercase font-inter"
      />
      {errors && errors?.email && < div className=" normal-case font-normal font-inter pt-[0.3rem] text-red-600 text-[0.75rem] " > {errors?.email} </div>}
      {/* <LockIcon stroke={"#616569"} width={"22"} height={"22"} /> */}
      {/* </div> */}
    </div>

    {/* building type */}
    {/* replace with a dropdown menu */}
    <div className=" hidden text-light-grey mt-[1rem] desktopsm:w-full font-inter uppercase desktoplg:hidden desktopsm:flex tablet:flex-col font-semibold
    " >
      <span className=" text-[0.9375rem] mb-[0.4rem] " >building type *</span>
      <div className=" flex justify-between px-[0.5rem] bg-secondary-grey rounded-[0.3rem] py-[0.8rem]" >
        {
          BUILDING_TYPES.map((item, idx) => (
            <button
              key={idx}
              onClick={() => handleBuildingTypeBtns(idx + 1)}
              className={` uppercase py-[0.3rem] px-[0.5rem] ${isBuildingType === 0 || (isBuildingType && (isBuildingType - 1 === idx)) ? "bg-white rounded-[0.4rem] border-[0.15rem]" : ""} `}
            >{item}</button>
          ))
        }
      </div>
    </div>

    {/* building type select */}
    <div className=" desktopsm:hidden my-[1rem]" >
      <div className=" uppercase text-light-grey font-semibold font-inter text-[0.9375rem] mb-[0.4rem]  " >
        building type *
      </div>
      <MobileBuildingTypeSelect setter_func={setFormData} formdata={formData} />
    </div>

    {/* street address 1 */}
    <div className=" text-light-grey mt-[1rem] mr-[0.5rem] desktopsm:w-full font-inter uppercase flex flex-col font-semibold" >
      <label className=" text-[0.9375rem] mb-[0.4rem] " >street address *</label>
      <input onChange={(e) => handleStreetAddress(1, e.target.value)}
        placeholder="street address 1"
        className=" bg-secondary-grey px-[0.8rem] py-[0.8rem] rounded-[0.2rem] uppercase font-inter" />
    </div>

    {/* street address 2 (if in a complex, estate, office park etc) */}
    <div className=" text-light-grey mt-[1rem] mr-[0.5rem] desktopsm:w-full font-inter uppercase flex flex-col font-semibold" >
      <label className=" text-[0.9375rem] mb-[0.4rem] " >street address 2 (if in a complex, estate, office park etc)</label>
      <input onChange={(e) => handleStreetAddress(2, e.target.value)}
        placeholder="street address 2"
        className=" bg-secondary-grey px-[0.8rem] py-[0.8rem] rounded-[0.2rem] uppercase font-inter" />
    </div>

    {/* suburb and post code */}
    <div className=" text-light-grey mt-[1rem] desktopsm:flex desktopsm:flex-row desktopsm:justify-between
    tablet:flex tablet:flex-row
    " >
      <div className=" tablet:w-4/12 mr-[0.5rem] desktopsm:w-4/12 font-inter uppercase flex flex-col font-semibold" >
        <label className=" text-[0.9375rem] mb-[0.4rem] " >zip/post code</label>
        <input onChange={(e) => handleZipCodeChange(e.target.value)}
          placeholder="0247"
          className=" bg-secondary-grey px-[0.8rem] py-[0.8rem] rounded-[0.2rem] uppercase font-inter" />
      </div>

      <div className=" mt-[1rem] tablet:mt-[0rem] tablet:w-8/12 desktopsm:ml-[0.5rem] desktopsm:w-full font-inter uppercase flex flex-col font-semibold" >
        <label className=" text-[0.9375rem] mb-[0.4rem] " >suburb *</label>
        <input onChange={(e) => handleSuburbChange(e.target.value)}
          placeholder="bryanston" className=" bg-secondary-grey px-[0.8rem] py-[0.8rem] rounded-[0.2rem] uppercase font-inter" />
      </div>
    </div>

    {/* city */}
    <div className=" text-light-grey mt-[1rem] mr-[0.5rem] desktopsm:w-full font-inter uppercase flex flex-col font-semibold" >
      <label className=" text-[0.9375rem] mb-[0.4rem] " >city * </label>
      <input onChange={(e) => handleCityChange(e.target.value)}
        placeholder="city"
        className=" bg-secondary-grey px-[0.8rem] py-[0.8rem] rounded-[0.2rem] uppercase font-inter" />
    </div>

    {/* country */}
    <div className=" text-light-grey mt-[1rem] mr-[0.5rem] desktopsm:w-full font-inter uppercase flex flex-col font-semibold" >
      <label className=" text-[0.9375rem] mb-[0.4rem] " >country</label>
      <input value="south africa" disabled
        placeholder="0987654321"
        className=" bg-secondary-grey px-[0.8rem] py-[0.8rem] rounded-[0.2rem] uppercase font-inter" />
    </div>

    {/* phone number */}
    <div className=" text-light-grey mt-[1rem] mr-[0.5rem] desktopsm:w-full font-inter uppercase flex flex-col font-semibold" >
      <label className=" text-[0.9375rem] mb-[0.4rem] " >phone number * </label>
      <input onChange={(e) => handlePhoneNumberChange(e.target.value)}
        placeholder="0987654321" value={formData.phone}
        className=" bg-secondary-grey px-[0.8rem] py-[0.8rem] rounded-[0.2rem] uppercase font-inter" />
      {errors && errors?.phoneNumber && < div className=" normal-case font-normal font-inter pt-[0.3rem] text-red-600 text-[0.75rem] " > {errors?.phoneNumber} </div>}
    </div>
  </div >
}

export default BillingAddressForm;