import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/store/redux/hooks";
import { RootState } from "../../utils/store/redux/store";
import { AdminScreenRouterName } from "../../utils/routers/routersNames";
import { useNavigate } from "react-router-dom";
import { clearUserAdminProfile, setUserAdminProfile } from "../../utils/store/redux/slices/userAdminProfile";
import { localStorage_deleteItem, localStorage_getItem } from "../../utils/store/localStorage/localStorage";
import { LS_ADMIN_PROFILE } from "../../utils/constants/constants";


const AdminHeader: React.FC = () => {
  const adminProfileInfo = useAppSelector((state: RootState) => state.userAdminProfile);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function handleLogoutBtn() {
    try {
      dispatch(clearUserAdminProfile());
      // delete data from local storage
      localStorage_deleteItem(LS_ADMIN_PROFILE);
      // 
      navigate(AdminScreenRouterName);
    }
    catch (err: any) {
      navigate(AdminScreenRouterName);
      dispatch(clearUserAdminProfile());
    }
  }

  useEffect(() => {
    // check ls for data
    const profileData = localStorage_getItem(LS_ADMIN_PROFILE);
    if (profileData) {
      dispatch(setUserAdminProfile(profileData));
      // if (Object.keys(adminProfileInfo).length === 0) { }
    }
    else {
      navigate(AdminScreenRouterName);
    }
  }, []);

  return <div className=" pb-[2rem] flex flex-row justify-between items-center ">
    <div >
      <div className=" text-2xl" >{adminProfileInfo.name}</div>
      <div >{adminProfileInfo.email}</div>
    </div>
    <div >
      <button
        className=" text-[1.2rem] hover:bg-red-800 bg-red-700 text-white py-[0.4rem] px-[1rem] "
        onClick={() => handleLogoutBtn()} >
        Logout
      </button>
    </div>
  </div>
}


export default AdminHeader;