import React, { useState } from "react";
import ArrowRightIcon from "../../../utils/svg/arrowRigtIcon";
import { useAppDispatch } from "../../../utils/store/redux/hooks";
import { editShippingMethod } from "../../../utils/store/redux/slices/billingAddress";
import { RUSH_SHIPPING_COST } from "../../../utils/constants/constants";


interface InputsCheckInterface {
  standard: boolean;
  rush: boolean;
}

interface ShippingMethodFormProps {
  setShippingAmount: (v: number) => void;
}

const ShippingMethodForm: React.FC<ShippingMethodFormProps> = ({ setShippingAmount }) => {
  const [shipMethodCheck, setShipMethodCheck] = useState<InputsCheckInterface>({ standard: false, rush: false });
  const dispath = useAppDispatch();

  function handleShippingMethodCheckBtn(btn: number) {
    if (btn === 1) {
      setShippingAmount(0);
      setShipMethodCheck({ ...shipMethodCheck, standard: !shipMethodCheck.standard, rush: false });
      dispath(editShippingMethod("standard"));
    }
    else if (btn === 2) {
      if (shipMethodCheck.rush === true) setShippingAmount(0);
      else setShippingAmount(RUSH_SHIPPING_COST);
      setShipMethodCheck({ ...shipMethodCheck, rush: !shipMethodCheck.rush, standard: false })
      dispath(editShippingMethod("rush"));
    }
  }

  return <div className=" px-[0.8rem] py-[1rem] my-[2rem] bg-white" >
    <div className=" text-almost-black text-[3.375rem] mb-[1rem] font-anton uppercase " >
      shipping method
    </div>

    <button
      onClick={() => handleShippingMethodCheckBtn(1)}
      className=" mb-[1rem] w-full flex flex-row justify-between items-center rounded-[0.3rem] px-[1rem] py-[0.7rem] font-inter uppercase bg-secondary-grey text-[1.0625rem] text-very-dark-grey " >
      <div className=" flex flex-row justify-between">
        <input
          checked={shipMethodCheck?.standard}
          className=" rounded-[0.5rem] tablet:h-[1.75rem] tablet:w-[1.75rem] focus:outline-none outline-none border-none checked:text-black checked:bg-white" type="checkbox" id="checkbox" name="checkbox" />

        <span className="ml-[0.5rem]" >standard </span>
      </div>
      <span className="text-locked-grey flex flex-row items-center" > 3 <ArrowRightIcon width={"12.6"} height={"13.2"} stroke={"#616569"} /> 7 working days</span>
    </button>

    <button
      onClick={() => handleShippingMethodCheckBtn(2)}
      className=" mb-[1rem] w-full flex flex-row justify-between items-center rounded-[0.3rem] px-[1rem] py-[0.7rem] font-inter uppercase bg-very-dark-grey text-[1.0625rem] " >
      <div className=" flex flex-row justify-between">

        <input
          checked={shipMethodCheck?.rush}
          className=" rounded-[0.5rem] tablet:h-[1.75rem] tablet:w-[1.75rem] focus:outline-none outline-none border-none checked:text-black checked:bg-white" type="checkbox" id="checkbox" name="checkbox" />

        <span className="text-white ml-[0.5rem]" >rush (+R{RUSH_SHIPPING_COST})</span>
      </div>

      <span className=" text-light-grey4 flex flex-row items-center" > 1 <ArrowRightIcon width={"12.6"} height={"13.2"} stroke={"#CBD1D7"} /> 2 working days</span>
    </button>
  </div >
}

export default ShippingMethodForm;