// use this type when you wants to change color of the SVG

import React from 'react';

interface GreaterThanIconProps {
  stroke: string;
  width: string;
  height: string;
}

const GreaterThanIcon: React.FC<GreaterThanIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 4.5L14.5 11.5L7.5 18.5" stroke={stroke} strokeWidth="2" />
  </svg>
}

//
export default GreaterThanIcon;
