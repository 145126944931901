import React, { useEffect } from "react";
import CheckOutLeftComponent from "./component/checkOutLeftComponent";
import HeaderMenu from "../../components/headerMenu/headerMenu";
import BillingAddressForm from "./component/billAddressForm";
import { useAppSelector } from "../../utils/store/redux/hooks";
import { RootState } from "../../utils/store/redux/store";
import { useNavigate } from "react-router-dom";
import PaymentYoco from "./component/paymentYoco";
import { ShopScreenRouterName } from "../../utils/routers/routersNames";
import ScrollToTopRoutine from "../../utils/routines/scrollToTopRoutine";

const CheckOutScreen: React.FC = () => {
  const userProfile = useAppSelector((state: RootState) => state.userProfile);
  const navigate = useNavigate();

  useEffect(() => {
    // dont delete this
    ScrollToTopRoutine();
    // 
    if (Object.keys(userProfile).length === 0) {
      navigate(ShopScreenRouterName);
    }
  }, []);

  return <div className=" " >

    <HeaderMenu screen={'5'} />

    <div className=" px-[1rem] 
    tablet:max-w-[59rem] tablet:mx-auto tablet:flex tablet:flex-row 
    desktopsm:max-w-[77rem] desktopsm:flex desktopsm:flex-row desktopsm:mx-auto
    desktopmd:max-w-[87rem] desktoplg:text-[90rem] 
    desktoplg:max-w-[90rem] " >

      <div className=" mb-[3rem] tablet:w-6/12 tablet:mr-[0.9rem] desktopsm:mr-[0.9rem]" >
        <CheckOutLeftComponent />
      </div>

      <div className=" tablet:w-6/12 tablet:mr-[0.9rem] desktopsm:mr-[0.9rem] pb-[3rem] " >
        <BillingAddressForm />

        {/* <ShippingAddressForm /> */}

        <PaymentYoco />
      </div>
    </div>

  </div>
}

export default CheckOutScreen;