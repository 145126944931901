export function formatPhoneNumber(input: string): string {
  try {
    // format string to '000 000 0000'
    const formattedNumber = `${input.slice(0, 3)} ${input.slice(3, 6)} ${input.slice(6)}`;
    return formattedNumber;
  }
  catch (e: any) {
    return ""
  }
}

// remove special characters from a string
export function removeSpecialChars(text: string): string {
  try {
    return text.replace(/[^a-zA-Z0-9 ]/g, '');
  }
  catch (e: any) {
    return ""
  }
}

export function formatAmount(amount: any): string[] | null {
  try {
    const a = amount.ToString();
    const b = a.split(".");
    return b
  }
  catch (e: any) {
    return null
  }
}

export function getInformation(): string {
  // const userAgent, luna
  try {
    const userAgent = navigator.userAgent.toLowerCase();
    const language = navigator.language.toLowerCase();
    let platform = navigator.platform.toLowerCase()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase();
    platform = platform ? platform : "";

    const res: string = `${userAgent}_${language}_${platform}_${timezone}`.replace("/", "");
    return res;
  }
  catch (err: any) {
    // log app errors
    return ""
  }
  finally {
    return ""
  }
}

export function getRandomString(length: number = 16): string {
  try {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++)
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    return result;
  }
  catch (err: any) {
    return ""
  }
}
