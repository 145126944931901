import { Link, useNavigate } from "react-router-dom";
import { AdminDashboardScreenRouterName, AdminScreenRouterName, AdminViewOrdersDetailsScreenRouterName } from "../../utils/routers/routersNames";
import { useEffect, useState } from "react";
import { AdminServiceGetOrders } from "../../services/admin/getOrders";
import { useAppDispatch, useAppSelector } from "../../utils/store/redux/hooks";
import { RootState } from "../../utils/store/redux/store";
import { localStorage_getItem } from "../../utils/store/localStorage/localStorage";
import { LS_ADMIN_PROFILE } from "../../utils/constants/constants";
import { setUserAdminProfile } from "../../utils/store/redux/slices/userAdminProfile";


const AdminViewOrder: React.FC = () => {
  const amdinProfile = useAppSelector((state: RootState) => state.userAdminProfile);
  const [orders, setOrders] = useState<any | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // 
  async function loadOrders(): Promise<void> {
    const ords = await AdminServiceGetOrders(amdinProfile.auth);
    if (ords && ords.success && ords.data.length > 0) {
      setOrders(ords.data);
    }
  }

  function handleOrderBtn(order_id: string): void {
    navigate(AdminViewOrdersDetailsScreenRouterName, { state: { id: order_id } })
  }

  // 
  // 
  useEffect(() => {
    // check ls for data
    const profileData = localStorage_getItem(LS_ADMIN_PROFILE);
    if (profileData) {
      loadOrders();
      dispatch(setUserAdminProfile(profileData));
    }
    else { navigate(AdminScreenRouterName); }
  }, []);

  // 
  // 
  return <div className=" font-inter px-[1rem] pt-[2rem] bg-white pb-[3rem] " >

    <div className=" mx-auto max-w-[40rem]" >

      <div className=" mb-[2rem] text-left" >
        <Link to={AdminDashboardScreenRouterName} className=" underline" >Back</Link>
      </div>

      <div className=" text-3xl uppercase text-left mb-[2rem] font-bold " >
        View Orders
      </div>

      <div className="" >
        {
          orders && orders.length > 0 ? orders.map((order: any, idx: number) =>
            <button key={idx} onClick={() => handleOrderBtn(order.id)}
              className=" hover:bg-slate-500 active:bg-slate-800 hover:text-white w-full px-[1rem] mb-[1rem] py-[1rem] flex flex-row items-center bg-gray-300 justify-between" >
              <div className=" text-left flex flex-col" >
                <span >Order Amount</span>
                <span className=" font-bold" >R{order.total_amount}</span>
              </div>
              {/*  */}
              <div className=" text-left flex flex-col " >
                <span >Order Discount</span>
                <span className=" font-bold" >R{order.discount}</span>
              </div>
            </button>
          )
            : (
              <div className=" font-bold text-red-700" >
                No Orders
              </div>
            )
        }
      </div>
    </div>
  </div>
}

export default AdminViewOrder;