import ArrowDownIcon from "../../../utils/svg/arrowDownIcon"


const ThreeArrowsDown: React.FC = () => {
  return <div className=' flex flex-row  ' >
    {[1, 2, 3].map((i: number) => <span key={i} className=' mr-[1rem] tablet768:ml-[1rem] tablet768:mr-0' >
      <ArrowDownIcon width='31' height='31' stroke='#232526' />
    </span>
    )}
  </div>
}

export default ThreeArrowsDown