// use this type when you wants to change color of the SVG

import React from 'react';

interface ArrowTopRightProps {
  stroke: string;
  width: string;
  height: string;
}

const ArrowTopRight: React.FC<ArrowTopRightProps> = ({ stroke, height, width }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.16675 41.45L41.6667 4M41.6667 4H10.1667M41.6667 4V34.45" stroke={stroke} strokeWidth="6.3" />
    </svg>
  );
}

export default ArrowTopRight;
