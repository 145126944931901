import { Link } from "react-router-dom";
import { LOCATIONADDRESSSTRING } from "../../../utils/constants/constants";



const AddressCopyRights: React.FC = () => {


  return <div className=" text-[0.9375em] uppercase  text-light-grey2 mt-[5rem] 
    flex justify-between flex-row-reverse
    tablet:flex-col tablet:text-end tablet:mt-[0rem]
  " >
    <Link className=" mb-[1rem] text-[0.8125rem] tracking-[0.125em] active:text-white hover:underline " to={LOCATIONADDRESSSTRING} target="_blank" >
      office address: <br /> 1 Kingsway Rd Auckland Park </Link>

    <div className=" text-[0.8125rem] text-light-grey2 tracking-[0.125em] " >
      @ copyright <br />
      solid water 2024 <br />
      all rights reserved
    </div>
  </div>
}

export default AddressCopyRights;