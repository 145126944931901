
import React from 'react';

interface WhiteBgCheckBoxProps {
  stroke: string;
  width: string;
  height: string;
}

const WhiteBgCheckBox: React.FC<WhiteBgCheckBoxProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="22" height="22" rx="4" fill="white" />
    <path d="M18.2422 6.5L9.24219 15.5L4.75732 11" stroke={stroke} strokeWidth="2" />
  </svg>

}

export default WhiteBgCheckBox;
