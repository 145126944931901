import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as XIcon } from "../../../utils/svg/x_icon.svg"


const TwitterLink: React.FC = () => {

  return <Link to="/contactus" target="_blank" className=" my-[1.2rem] text-light-grey2 active:text-white hover:text-white flex hover:underline
    tablet:px-[1.5rem]
  " >
    <XIcon />
    <span className=" pl-[0.5rem]" >twitter</span>
  </Link>
}

export default TwitterLink;