import { useEffect } from "react";
import { HOMEPAGE_IMAGE1 } from "../../../utils/constants/constants"
import ThreeArrowsDown from "./threeArrowsDown"
import ThreeArrowsUp from "./threeArrowsUp"


const Image1Component: React.FC = () => {
  // let images = useAppSelector((state: RootState) => state.contentImageSlice);
  // const [image, setImage] = useState<string>("");


  // 
  useEffect(() => {
    // if (images && Object.keys(images).length === 0) {
    //   images = localStorage_getItem(LS_IMAGES);
    //   setImage(images['homepagefirstimage']);
    // }
  }, []);


  return <div className=' tablet768:mt-[3rem] py-[2rem] tablet768:py-0 ' >

    <div className="tablet768:flex tablet768:flex-row tablet:justify-between " >
      {/*  tablet768:w-7/12 | tablet:w-5/12 */}
      <div className=' 
              tablet768:w-[23.375rem]
              tablet768:mr-[1rem] 
              tablet:w-[30.5rem] 
              desktopsm:w-[37.375rem]  ' >
        <img src={HOMEPAGE_IMAGE1} className=" w-full " />
      </div>
      {/* {
        image && image.length > 0 ?
          (
            <div className=' 
              tablet768:w-[23.375rem]
              tablet768:mr-[1rem] 
              tablet:w-[30.5rem] 
              desktopsm:w-[37.375rem]  ' >
              <img src={image} className=" w-full " />
            </div>)
          : (
            <div className="card hidden relative tablet:flex tablet:flex-col h-full tablet:justify-center text-center w-full bg-slate-800">
              <div className="image-placeholder h-full text-center w-ful placeholder bg-slate-100"></div>
            </div>
          )
      } */}

      <div className=" mt-[2rem] tablet768:hidden " >
        <ThreeArrowsDown />
      </div>
      <div className=' text-[4.375rem] mobile:text-[5rem] desktopsm:text-[6.25rem] uppercase 
        py-[2rem]
        tablet768:w-5/12 tablet768:py-0 tablet768:text-right 
        tablet:w-5/12
        desktopmd:w-4/12
    ' >
        <span className="" >south africa's newest alkaline water brand</span>
      </div>
    </div>

    <div className=" tablet768:mt-[2rem] tablet768:flex tablet768:justify-end " >
      <ThreeArrowsUp />
    </div>
  </div>
}


export default Image1Component