
import React from 'react';

interface WhiteBgUnCheckBoxProps {
  stroke: string;
  width: string;
  height: string;
}

const WhiteBgUnCheckBox: React.FC<WhiteBgUnCheckBoxProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="0.5" width="21" height="21" rx="3.5" stroke={stroke} />
  </svg>
}

// 979CA0
export default WhiteBgUnCheckBox;
