
import React, { useState } from "react";
import ArrowTopRight from "../../../utils/svg/arrowTopRight";
import { ReactComponent as FacebookIcon } from "../../../utils/svg/facebook_icon.svg";
import EmailIcon from "../../../utils/svg/emailIcon";
import { cardStyle, facebookBtnStyle, emailBtnStyle } from "../constants";
import GoogleAuthBtn from "./googleAuthBtn";
import LoginScreen from "../screens/login/login";
import GuestAccountBtn from "./guestAccountBtn";


const SigninCard: React.FC = () => {
  const [showForms, setShowForms] = useState<any>({ signin: false, signup: false });

  function handleEmailBtn() { setShowForms({ ...showForms, signin: !showForms.signin }); }

  return <div className={cardStyle} >

    {
      showForms && showForms.signin === true ? <LoginScreen showForm={showForms} setShowform={setShowForms} /> :

        <div className=" px-[0.99rem] pt-[1rem]">

          <div onClick={() => alert("there is nothing here")} className=" cursor-pointer flex justify-end  text-end mb-[1rem] " >
            <ArrowTopRight height="45.52" width="46.8" stroke={"#fff"} />
          </div>
          <div className=" tablet:text-[3.625rem] desktopsm:text-[4.625rem] " >got an<br /> account</div>
          <div className=" font-inter text-[2.125rem] text-light-grey4 mb-[2rem] tablet:text-[1.5rem] desktopsm:text-[2.125rem] " >sign in</div>
          <div className="  text-light-grey4 font-inter py-[0.7rem] tablet:text-[1.0625rem] desktopsm:text-[1.3125rem] " >sign in with</div>


          {/* btns */}
          <div className=" text-[1.1875rem] flex flex-col font-normal font-inter uppercase " >

            <button onClick={() => handleEmailBtn()} className={emailBtnStyle} >
              <EmailIcon stroke={"#171718"} width={"26.4"} height={"26.6"} />
              <span className=" pl-[0.7rem]" >
                email
              </span>
            </button>

            <GoogleAuthBtn />

            <button onClick={() => alert("coming soon...")} className={facebookBtnStyle} >
              <FacebookIcon />
              <span className=" pl-[0.7rem]" >
                Facebook
              </span>
            </button>
            {/* continue as guest */}
            <div >
              <GuestAccountBtn />
            </div>
          </div>


        </div>
    }


  </div >
}


export default SigninCard;