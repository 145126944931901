import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { USERPROFILE } from "../../../interfaces/userProfile";

const initialState: Partial<USERPROFILE> = {}

export const userProfileSlice = createSlice({
  name: 'userProfileSlice',
  initialState,
  reducers: {
    setUserProfile(state, action: PayloadAction<USERPROFILE>) {
      return { ...action.payload };
    },
    updateUserProfile(state, action: PayloadAction<Partial<USERPROFILE>>) {
      return { ...state, ...action.payload };
    },
    clearUserProfile(state) {
      // reset state to initial empty state
      return initialState;
    }
  }
})

export const { setUserProfile, updateUserProfile, clearUserProfile } = userProfileSlice.actions;

export const UserProfileSelector = (state: RootState) => state.userProfile;

export default userProfileSlice.reducer;