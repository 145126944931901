import { SectionComponentProps } from "../components/interfaces_";

const SaleAndRefundData: SectionComponentProps[] = [
  {
    header: "Introduction",
    texts: [
      "Welcome to Solid Water, a registered company with the Companies and Intellectual Property Commission (CIPC) in South Africa. These Terms and Conditions govern your use of our website and services. By accessing and using our website, you agree to comply with and be bound by these Terms and Conditions."
    ]
  },
  {
    header: "Sales Policy",
    texts: [
      "1.1 Product Information",
      "Products: Solid Water offers bottled water in the following sizes: 500ml, 1 liter, and 5 liters.",
      "Pricing: All prices are listed on our website and are subject to change without notice. Prices include applicable VAT but do not include delivery charges, which will be calculated based on your delivery area.",
      "1.2 Ordering",
      "Order Placement: Orders can be placed through our website. You must provide accurate and complete information for order processing.",
      "Order Confirmation: Upon placing an order, you will receive a confirmation email detailing the products ordered, total price, and estimated delivery time.",
      "1.3 Payment",
      "Accepted Methods: We accept payments via [insert payment methods, e.g., credit/debit cards, EFT, etc.]. Payment must be completed before the order is processed.",
      "Security: Payment information is processed securely through our payment gateway. We do not store payment details.",
      "1.4 Delivery",
      "Areas: We deliver within South Africa. Delivery rates vary by location and will be displayed during checkout.",
      "Timing: Estimated delivery times will be provided at checkout. We strive to meet these estimates but cannot guarantee delivery times."
    ]
  },
  {
    header: "Refunds Policy",
    texts: [
      "2.1 General Refunds",
      "Eligibility: Refunds are only available for products that are damaged or incorrect upon delivery. We do not offer refunds for change of mind or incorrect orders placed by the customer.",
      "Request: To request a refund, you must contact our customer service within [insert number of days, e.g., 7 days] of receiving your order. Provide your order number and details of the issue.",
      "2.2 Returns Process",
      "Return Authorization: Our customer service team will review your refund request and provide instructions for returning the product if applicable.",
      "Condition: Products must be returned in their original condition, unopened and unused. We reserve the right to refuse refunds if the product does not meet these conditions.",
      "2.3 Refund Processing",
      "Approval: Once your return is received and inspected, we will notify you of the approval or rejection of your refund.",
      "Issuance: Approved refunds will be processed to the original payment method within [insert number of days, e.g., 14 days] of receiving the returned product. You will receive a notification once the refund has been issued.",
      "2.4 Delivery Charges",
      "Refund of Delivery Charges: Delivery charges are non-refundable, except in cases where a product is returned due to an error on our part or a product defect."
    ]
  },
  {
    header: "Changes to This Policy",
    texts: [
      "We reserve the right to update or modify this Sales and Refunds Policy at any time. Any changes will be posted on our website and will take effect immediately. Your continued use of our website and services constitutes acceptance of any changes."
    ]
  }
];


export default SaleAndRefundData;