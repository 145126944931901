

//
export function localStorage_setItem(key: string, value: any): boolean {
  let results: boolean = false;
  try {
    if (key && value) {
      const value_ = JSON.stringify(value);
      localStorage.setItem(key, value_);
      results = true;
    }
  } catch (error) {
    console.error(error);
  }
  return results;
}

export function localStorage_getItem(key: string): any | null {
  let data: any | null = null;
  try {
    if (key) {
      const store_string: string | null = localStorage.getItem(key);
      if (store_string !== null) {
        data = JSON.parse(store_string);
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    return data;
  }
}

export function localStorage_deleteItem(key: string): boolean {
  try {
    if (localStorage.getItem(key) !== null) {
      localStorage.removeItem(key);
      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}