import ArrowUpIcon from "../../../utils/svg/arrowUpIcon"

const ThreeArrowsUp: React.FC = () => {
  return <div className=' flex flex-row' >
    {[1, 2, 3].map((i: number) => <span key={i} className=' mr-[1rem] tablet768:ml-[1rem] tablet768:mr-0 ' >
      <ArrowUpIcon width='31' height='31' stroke='#232526' />
    </span>
    )}
  </div>
}

export default ThreeArrowsUp