
import React from 'react';

interface WhatsappIconProps {
  stroke: string;
  width: string;
  height: string;
}

const WhatsappIcon: React.FC<WhatsappIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37.2932 29.5604C37.195 29.5133 33.5207 27.7039 32.8678 27.469C32.6012 27.3732 32.3157 27.2798 32.012 27.2798C31.5158 27.2798 31.099 27.5271 30.7744 28.0127C30.4073 28.5583 29.2963 29.8571 28.9529 30.245C28.9081 30.2963 28.8469 30.3575 28.8103 30.3575C28.7774 30.3575 28.2087 30.1233 28.0366 30.0485C24.0951 28.3364 21.1033 24.2191 20.6931 23.5248C20.6345 23.425 20.632 23.3797 20.6315 23.3797C20.6459 23.3268 20.7785 23.194 20.8469 23.1254C21.047 22.9274 21.2638 22.6664 21.4736 22.4139C21.5729 22.2943 21.6724 22.1745 21.7701 22.0616C22.0745 21.7075 22.21 21.4326 22.3671 21.1141L22.4494 20.9487C22.8329 20.1867 22.5053 19.5436 22.3995 19.3359C22.3126 19.1621 20.7611 15.4178 20.5962 15.0244C20.1995 14.0752 19.6754 13.6332 18.9471 13.6332C18.8795 13.6332 18.9471 13.6332 18.6637 13.6451C18.3185 13.6597 16.4392 13.9071 15.6082 14.4309C14.7271 14.9865 13.2363 16.7573 13.2363 19.8717C13.2363 22.6746 15.0151 25.3211 15.7787 26.3276C15.7977 26.353 15.8326 26.4045 15.8831 26.4785C18.8078 30.7497 22.4538 33.9151 26.1499 35.3916C29.7081 36.8129 31.3931 36.9772 32.351 36.9772H32.3512C32.7537 36.9772 33.0759 36.9456 33.3602 36.9177L33.5405 36.9005C34.7697 36.7915 37.471 35.3917 38.0855 33.6842C38.5695 32.3393 38.6972 30.8698 38.3751 30.3365C38.1545 29.9739 37.7744 29.7915 37.2932 29.5604Z" fill={stroke} />
    <path d="M25.6473 0.599976C11.9975 0.599976 0.892435 11.6215 0.892435 25.1688C0.892435 29.5505 2.06505 33.8396 4.28642 37.5934L0.0346529 50.1353C-0.0445471 50.3692 0.014362 50.6277 0.187326 50.8038C0.31218 50.9313 0.481544 51 0.654507 51C0.72078 51 0.787544 50.99 0.852671 50.9692L13.9305 46.8135C17.5092 48.7256 21.554 49.7349 25.6475 49.7349C39.2961 49.7351 50.4 38.7146 50.4 25.1688C50.4 11.6215 39.2961 0.599976 25.6473 0.599976ZM25.6473 44.6172C21.7955 44.6172 18.0648 43.5049 14.8578 41.4006C14.75 41.3297 14.6248 41.2934 14.4988 41.2934C14.4322 41.2934 14.3654 41.3035 14.3005 41.3242L7.74929 43.4066L9.86413 37.1673C9.93252 36.9654 9.89832 36.7427 9.77233 36.5705C7.33022 33.2337 6.03929 29.2912 6.03929 25.1688C6.03929 14.4436 14.8354 5.71787 25.6472 5.71787C36.4577 5.71787 45.2528 14.4436 45.2528 25.1688C45.2529 35.8927 36.458 44.6172 25.6473 44.6172Z" fill={stroke} />
  </svg>
}

export default WhatsappIcon;
