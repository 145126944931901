// use this type when you wants to change color of the SVG

import React from 'react';

interface ArrowRightIconProps {
  stroke: string;
  width: string;
  height: string;
}

const ArrowRightIcon: React.FC<ArrowRightIconProps> = ({ stroke, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 4.27344L17.5 11.2734M10.5 18.2734L17.5 11.2734M17.5 11.2734H3.5" stroke={stroke} strokeWidth="2" />
    </svg>
  );
}

// width="21" height="23"
export default ArrowRightIcon;
