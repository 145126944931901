import React from "react";


interface ShoppingCartIconProps {
  stroke: string;
  width: string;
  height: string;
}

const ShoppingCartIcon: React.FC<ShoppingCartIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.75 9L2.98821 8.02647C2.84156 8.01017 2.71579 7.91447 2.66099 7.77747L0.969423 3.54856C0.864324 3.28581 1.05783 3 1.34081 3H12.9167M11.75 9L12.9167 3M11.75 9L12.1323 10.5291C12.1933 10.7731 12.0165 11.0123 11.7652 11.0255L2.75 11.5M12.9167 3L13.1943 1.33424C13.2264 1.14137 13.3933 1 13.5889 1H15.75" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
    <circle cx="12" cy="14.25" r="1.75" fill={stroke} />
    <circle cx="3.25" cy="14.5" r="1.5" fill={stroke} />
    <path d="M4.4502 8L3.9502 3H6.7002L6.9502 8M9.4502 8.5L9.9502 3" stroke={stroke} strokeWidth="1.5" />
  </svg>

}


export default ShoppingCartIcon;