import { API_BASE_URL } from "../../utils/constants/constants";

// 
// 
export async function CreateInitialTransaction(data: any): Promise<any> {
  try {
    if (data) {
      let _results = null;
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "status": data.status,
        "sourcetype": data.sourceType,
        "tok_id": data.tok_id,
        "card_id": data.card_id,
        "brand": data.brand,
        "expiryMonth": data.expiryMonth,
        "expiryYear": data.expiryYear,
        "fingerprint": data.fingerprint,
        "maskedCard": data.maskedCard,
        "object": data.object
      });

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${API_BASE_URL}/orders/createtransaction`, requestOptions)
        .then((response) => response.json())
        .then((result) => _results = result)
        .catch((error) => _results = { message: error.message, data: null });

      return _results
    }
    else {
      return { message: "provide correct data", data: null }
    }
  }
  catch (err: any) {
    return { message: err.message, data: null }
  }
}