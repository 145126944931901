
import React from "react";

interface UserAccountIconProps {
  stroke: string,
  cssStyle: string,
  height: string;
  width: string;
}


const UserAccountIcon: React.FC<UserAccountIconProps> = ({ stroke, cssStyle, width, height }) => {

  return <svg className={cssStyle} width={width} height={height} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5988 15.5039C14.5729 15.5208 14.5379 15.5417 14.4915 15.5661C14.2272 15.7052 13.7787 15.8501 13.1321 15.9753C11.8557 16.2226 10.0454 16.35 8 16.35C5.95464 16.35 4.14433 16.2226 2.86788 15.9753C2.22129 15.8501 1.77277 15.7052 1.50847 15.5661C1.46209 15.5417 1.42711 15.5208 1.40116 15.5039C1.42117 14.185 1.70312 13.1998 2.47482 12.5017C3.30807 11.7479 4.90507 11.15 8 11.15C11.0949 11.15 12.6919 11.7479 13.5252 12.5017C14.2969 13.1998 14.5788 14.185 14.5988 15.5039ZM14.6651 15.4514C14.6657 15.4515 14.6626 15.4555 14.654 15.4631C14.6601 15.4552 14.6644 15.4514 14.6651 15.4514ZM1.33494 15.4514C1.33556 15.4514 1.33986 15.4552 1.34598 15.4631C1.33738 15.4555 1.33432 15.4515 1.33494 15.4514Z" stroke={stroke} strokeWidth="1.8" />
    <circle cx="8" cy="4.75" r="3.1" stroke={stroke} strokeWidth="1.8" />
  </svg>

}
// w: 16 - h:18
export default UserAccountIcon;