import React from "react";
import { ProfileScreenRouterName } from "../../../utils/routers/routersNames";
import { useNavigate } from "react-router-dom";
import ArrowUpperLeft from "../../../utils/svg/arrowUpperLeft";


const BackProfileScreenBtn: React.FC = () => {
  const navigate = useNavigate();

  function handleBackBtn() {
    navigate(ProfileScreenRouterName);
  }

  return <button onClick={() => handleBackBtn()}
    className=" flex flex-row items-center py-[0.6rem] px-[1rem] bg-light-grey5 hover:bg-grey-like1 active:bg-grey-like w-full " >
    <ArrowUpperLeft stroke="#232526" width="23" height="23" />
    <span className="font-semibold mobile:px-[0.4rem] text-[1.1875rem] uppercase font-inter mobilels:text-[1.3125rem] desktopsm:text-[1.5rem]">
      back to account
    </span>
  </button>
}


export default BackProfileScreenBtn;