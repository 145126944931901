// use this type when you wants to change color of the SVG
import React from 'react';

interface Quantity2IconProps {
  stroke: string;
  width: string;
  height: string;
}

const Quantity2Icon: React.FC<Quantity2IconProps> = ({ stroke, height = "48", width = "48" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.5" cy="24" r="2" fill={stroke} />
      <circle cx="27.5" cy="24" r="2" fill={stroke} />
    </svg>
  );
}
export default Quantity2Icon;
