import React from "react";
import ThreeArrowsDown from "./threeArrowsDown";


const LogoText: React.FC = () => {

  return <div className=" font-anton uppercase mobile:text-[6.875rem] tablet768:text-[9.375rem] tablet:text-[13.125rem] desktopsm:text-[16.25rem] text-[5rem] " >
    <div >solid</div>
    <div className=" tablet768:flex tablet768:flex-row tablet768:justify-between tablet768:items-baseline " >
      <span >water</span>
      <span className=" hidden tablet768:block" >
        <ThreeArrowsDown />
      </span>
    </div>
  </div>
}


export default LogoText;