import { API_BASE_URL } from "../../utils/constants/constants";

export async function AdminServiceLogin(data: any): Promise<any> {
  try {
    if (data && data.email && data.password && data.name) {

      let _results = null;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "name": data.name,
        "email": data.email,
        "password": data.password
      });

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${API_BASE_URL}/administrators/loginadminuser`, requestOptions)
        .then((response) => response.json())
        .then((result) => _results = result)
        .catch((error) => _results = {
          message: error.message,
          data: null,
          success: false,
        });

      return _results;
    }
    else {
      return { success: false, message: "please provide correct data", data: null };
    }
  }
  catch (e: any) {
    return { success: false, message: e.message, data: null };
  }
}