import { CART_ITEM } from "../../../utils/interfaces/item";
import { useAppSelector } from "../../../utils/store/redux/hooks";
import { RootState } from "../../../utils/store/redux/store";


interface TotalAmountProps {
  totalCartAmount: number;
}

const TotalAmount: React.FC<TotalAmountProps> = ({ totalCartAmount }) => {
  // const cartItemSlice = useAppSelector((state: RootState) => state.cartItemsSlice);
  // const totalAmount = cartItemSlice.reduce((s: number, prod: CART_ITEM) => s += prod.total, 0);

  function calculateExcludingTax() {
    // should make request to backend
  }

  return <div >
    <div className=" text-white tablet:text-[2.125rem] py-[1rem] border-b-[0.1rem] uppercase flex justify-between font-inter text-[1.6875rem] font-bold" >
      <span >total</span>
      <span >R{totalCartAmount}</span>
    </div>

    <div className=" py-[1rem] tablet:text-[1.1875rem] text-[1.0625rem] text-marking-grey" >
      <div className=" py-[0.3rem] flex justify-between" >
        <span >Excluding VAT</span>
        <span >R{totalCartAmount}</span>
      </div>
      {/*  */}
      <div className=" py-[0.3rem] flex justify-between" >
        <span >VAT @ {0}%</span>
        <span >R{0}</span>
      </div>
    </div>
  </div>
}


export default TotalAmount;