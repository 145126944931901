import { Link } from "react-router-dom"
import LinkedInLink from "./linkedin";
import TwitterLink from "./twitter";
import InstagramLink from "./instagram";


const MobileLandScapeSocialUrl: React.FC = () => {
  const style: string = " tablet:hidden mobilels:flex mobilels:flex-row justify-between items-center"
  const style2: string = " text-white hover:text-light-grey2 active:text-light-grey2 hover:underline";

  return <div className="mt-[1.2rem] tablet:hidden hidden mobilels:flex mobilels:flex-col " >
    <div className={style} >
      <LinkedInLink />
      <Link to="/termsconditions" className={style2} >
        terms & conditions</Link>
    </div>

    <div className={`${style} py-[1rem]`} >
      <TwitterLink />
      <Link to="/salesrefunds" className={style2} >
        sales & refunds</Link>
    </div>

    <div className={style} >
      <InstagramLink />
      <Link to="/policyprivacy" className={style2} >
        privacy policy</Link>
    </div>
  </div>
}


export default MobileLandScapeSocialUrl;