import { API_BASE_URL } from "../../utils/constants/constants";
import { hash_text } from "../../utils/routines/hash";

export async function ServiceLoginUser(data: any): Promise<any> {
  try {
    let _results = null;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let hashed_pwd: string | null = "";
    if (data.isthirdparty === 0) hashed_pwd = await hash_text(data.password);
    else hashed_pwd = ""

    const raw = JSON.stringify({
      "phone_email": data.email,
      "isthirdparty": data.isthirdparty,
      "password": hashed_pwd
    });

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    await fetch(`${API_BASE_URL}/auth/loginuser`, requestOptions)
      .then((response) => response.json())
      .then((result) => _results = result)
      .catch((error) => {
        _results = { message: error.message, data: [] }
      });

    return _results;
  }
  catch (e: any) {
    return { message: e.message, data: [] }
  }
}