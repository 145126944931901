import React, { useState } from "react";
import AdminHeader from "./header";


export default function AddFaq(): React.JSX.Element {
  const [formData, setFormData] = useState<{ q: string; a: string }>({ q: "", a: "" });

  function handleFormInput(e: React.ChangeEvent<HTMLInputElement>) {
    try {
      const { value, name } = e.target;
      setFormData({ ...formData, [name]: value });
    }
    catch (err: any) {
      alert(`${err.message}}`);
    }
  }

  function handleAddFaq(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      console.log(formData);
    }
    catch (err: any) {
      alert(`${err.message}`);
    }
  }

  return <div className=" py-[2rem] px-[1rem] h-screen font-inter bg-white" >

    <AdminHeader />

    <div className=" mx-auto max-w-[40rem]" >
      <div className=" text-2xl" >Add FAQ</div>

      <form onSubmit={(e) => handleAddFaq(e)} className="my-[3rem] " >
        <div className=" flex flex-col mb-[1rem] " >
          <label className=" text-[0.9rem] text-almost-black uppercase mb-[0.3rem] " >Add Question</label>
          <input
            name={'q'}
            className=" bg-[#e9e9ed] px-[0.8rem] py-[0.6rem] rounded-[0.5rem] font-inter "
            placeholder="Enter Question"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormInput(e)} />
        </div>

        {/*  */}
        <div className=" flex flex-col " >
          <label className=" text-[0.9rem] text-almost-black uppercase mb-[0.3rem] " >Enter Answer</label>
          <input
            name={'a'}
            className=" bg-[#e9e9ed] px-[0.8rem] py-[0.6rem] rounded-[0.5rem] font-inter "
            placeholder="Enter Answer "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormInput(e)} />
        </div>


        {/*  */}
        <button
          className=" uppercase font-semibold mt-[2rem] bg-black w-full text-white py-[0.8rem] rounded-[0.3rem] hover:bg-slate-700 active:bg-green-950 "
          type="submit" >Change Amount</button>
      </form>
    </div>
  </div>
}