import { validateEmail, validatePhone } from "../../../utils/routines/validations";

// export validate billing address form
export function validateBillingAddressForm(formData: any): string | boolean {
  // validate username
  if (formData && formData.username && formData.username.length > 1) {
    // validate email
    const isEmail = validateEmail(formData.email);
    if (isEmail === 1) {
      // building type
      if (formData.buildingType && formData.buildingType.length > 0) {
        // check for street address 
        if (formData.streetAddress1 && formData.streetAddress1.length > 0) {
          // check for zip code
          if (formData.zipCode && formData.zipCode.length > 1) {
            // check for suburb
            if (formData.suburb && formData.suburb.length > 1) {
              // check if city name is provided
              if (formData.phoneNumber && formData.phoneNumber.length > 1) {
                // check for valid south african phone number
                const isValidPhone = validatePhone(formData.phoneNumber);
                if (isValidPhone === 1) {
                  return true;
                }
                else {
                  return isValidPhone;
                }
              }
              else {
                return "Please provide city name";
              }
            }
            else {
              return "Please provide suburb";
            }
          }
          else {
            return "Please provide zip code"
          }
        }
        else {
          return "Please provide street address"
        }
      }
      else {
        return "Please select a correct building type";
      }
    }
    else {
      return isEmail;
    }
  }
  else {
    return "Invalid username";
  }
}