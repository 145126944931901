import React from "react";
import ArrowRightIcon from "../../../utils/svg/arrowRigtIcon";

const TextDescription: React.FC = () => {

  return <div className=" py-[1rem]" >

    <div className=" flex flex-col" >
      <span className=" uppercase text-[1.3125rem] desktopsm:text-[1.5rem] font-semibold font-inter " >
        smooth, gentle and refreshing
      </span>

      <span className=" text-[1.0625rem] mobile:text-[1.1875rem] desktopsm:text-[1.3125rem] font-inter text-locked-grey  " >
        Experience the purity of SOLID Alkaline water – a revitalising elixir meticulously crafted for a crisp, clean taste. Our thorough ionisation process unlocks hydration and invigoration potential, helping you elevate your wellness game – making it an essential for those who prioritise a healthy, balanced lifestyle.
      </span>
    </div>

    <div className=" tablet:flex tablet:flex-col tablet:items-start tablet768:flex tablet768:items-center tablet768:flex-row" >
      <div className=" tablet768:w-8/12 flex flex-col " >
        <span className=" uppercase mt-[1.5rem] text-[1.3125rem] desktopsm:text-[1.5rem] font-semibold font-inter " >
          different sizes for different uses
        </span>
        <span className=" text-[1.0625rem] mobile:text-[1.1875rem] desktopsm:text-[1.3125rem] font-inter text-locked-grey " >
          We offer 3 different size to suit your personal and business needs. If you’re looking for something bigger, get in touch with us!
        </span>
      </div>

      <div className=" tablet:px-0 tablet768:pl-[3rem] tablet768:4/12 my-[1rem] font-inter uppercase font-semibold text-[1.3125rem] desktopsm:text-[1.5rem] " >
        <div className=" flex flex-row items-center  " >
          <ArrowRightIcon stroke="#171718" width="20.28" height="20.28" />
          <span className=" ml-[0.5rem]" >500ml</span>
        </div>
        <div className=" flex flex-row items-center  " >
          <ArrowRightIcon stroke="#171718" width="20.28" height="20.28" />
          <span className=" ml-[0.5rem]" >1l</span>
        </div>
        <div className=" flex flex-row items-center  " >
          <ArrowRightIcon stroke="#171718" width="20.28" height="20.28" />
          <span className=" ml-[0.5rem]" >5l</span>
        </div>
      </div>
    </div>
    {/*  */}
  </div>
}

export default TextDescription;