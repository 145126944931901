import React from 'react'
import { MuiOtpInput } from 'mui-one-time-password-input'

const MUIOtpForm = () => {
  const [otp, setOtp] = React.useState('')

  const handleChange = (newValue: any) => {
    setOtp(newValue)
  }

  return (
    <div className="" >
      <div className=" text-[0.75rem] text-light-grey mb-[1rem] uppercase font-bold font-inter " >Enter OTP</div>

      <MuiOtpInput
        length={5}
        value={otp}
        TextFieldsProps={{ placeholder: '0', size: "medium" }}
        onChange={handleChange} />
    </div>
  )
}

export default MUIOtpForm;