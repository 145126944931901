import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface paymentInformationSliceInterface {
  name: string;
  expiryDate: string;
  cardNumber: string;
  cvv: string | null;
  rememberCard: boolean;
}

const initialState: Partial<paymentInformationSliceInterface> = {};

export const paymentInformationSlice = createSlice({
  name: 'paymentInformationSlice',
  initialState,
  reducers: {
    setPaymentInformation: (state, action: PayloadAction<paymentInformationSliceInterface>) => {
      state = action.payload;
      return state;
    },
  }
})

export const { setPaymentInformation, } = paymentInformationSlice.actions;
// selector
export const usePaymentInformationSlice = (state: RootState) => state.paymentInformation;

export default paymentInformationSlice.reducer;