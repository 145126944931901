// use this type when you wants to change color of the SVG
import React from 'react';


interface PaymentIconProps {
  stroke: string;
  width: string;
  height: string;
}

const PaymentIcon: React.FC<PaymentIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17 15.5V7.5H5V15.5H17ZM4.10039 15.4925C4.1004 15.4925 4.10101 15.4925 4.10219 15.4927L4.10039 15.4925ZM5.00751 6.60039C5.00751 6.6004 5.00746 6.60101 5.00732 6.6022L5.00751 6.60039ZM4.1022 7.50732C4.10101 7.50745 4.1004 7.50751 4.10039 7.50751L4.1022 7.50732ZM16.9927 16.3978C16.9926 16.399 16.9925 16.3996 16.9925 16.3996L16.9927 16.3978ZM17.8996 7.50751C17.8996 7.50751 17.899 7.50746 17.8978 7.50732L17.8996 7.50751ZM3.10899 6.04601C3 6.25992 3 6.53995 3 7.1V15.9C3 16.4601 3 16.7401 3.10899 16.954C3.20487 17.1422 3.35785 17.2951 3.54601 17.391C3.75992 17.5 4.03995 17.5 4.6 17.5H17.4C17.9601 17.5 18.2401 17.5 18.454 17.391C18.6422 17.2951 18.7951 17.1422 18.891 16.954C19 16.7401 19 16.4601 19 15.9V7.1C19 6.53995 19 6.25992 18.891 6.04601C18.7951 5.85785 18.6422 5.70487 18.454 5.60899C18.2401 5.5 17.9601 5.5 17.4 5.5H4.6C4.03995 5.5 3.75992 5.5 3.54601 5.60899C3.35785 5.70487 3.20487 5.85785 3.10899 6.04601Z" fill={stroke} />
    <path d="M5 10H17" stroke={stroke} strokeWidth="2" />
    <rect x="6" y="12" width="3" height="2" rx="0.3" fill={stroke} />
  </svg>
  // 232526
}

export default PaymentIcon;
