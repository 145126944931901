import React, { useEffect, useState } from "react";
import ArrowUpperLeft from "../../../utils/svg/arrowUpperLeft";
import { useNavigate } from "react-router-dom";
import { ProfileScreenRouterName } from "../../../utils/routers/routersNames";
import CheckMarkIcon from "../../../utils/svg/checkMarkIcon";
import { useAppSelector } from "../../../utils/store/redux/hooks";
import { RootState } from "../../../utils/store/redux/store";


const YourDetails: React.FC = () => {
  const navigate = useNavigate()
  const [submitBtnEnable, setSubmitBtnEnable] = useState<boolean>(true);
  const [formData, setFormData] = useState<any>({});
  const _userProfileSelector = useAppSelector((state: RootState) => state.userProfile);

  function onFormSubmit(e: any) {
    e.preventDefault();
    alert("in progress");
  }

  function handleBackBtn() {
    navigate(ProfileScreenRouterName);
  }

  useEffect(() => {
    setFormData(_userProfileSelector);
    // EnableBtn();
    if (formData?.name && formData?.name !== null && formData?.surname && formData?.surname !== null && formData?.email && formData?.email !== null && formData?.phonenumber && formData?.phonenumber !== null) {
      setSubmitBtnEnable(true);
    }
  }, []);

  return <div className=" bg-light-grey6" >

    <div className=" mobile:max-w-[22.5rem] mobile:mx-auto mobilels:max-w-[27rem] tablet:max-w-[37.5rem] " >

      <div className=" w-full px-[1rem] pt-[2rem] pb-[1rem] " >
        <button onClick={() => handleBackBtn()}
          className=" flex flex-row items-center py-[0.6rem] px-[1rem] bg-light-grey5 hover:bg-grey-like1 active:bg-grey-like w-full " >
          <ArrowUpperLeft stroke="#232526" width="23" height="23" />
          <span className=" font-semibold text-[1.1875rem] uppercase font-inter mobilels:text-[1.3125rem] desktopsm:text-[1.5rem] " >
            back to account
          </span>
        </button>
      </div>

      <div className=" uppercase px-[1rem] mt-[4rem] " >
        <div className=" text-[1.1875rem] font-inter font-semibold mobilels:text-[1.375rem] desktopsm:text-[1.6875rem]  " >settings & preferences</div>
        <span className=" text-[3.375rem] font-anton mobilels:text-[4.625rem] desktopsm:text-[5.875rem] " >your details</span>
      </div>

      <form onSubmit={(e) => onFormSubmit(e)}
        className=" bg-white py-[1rem] px-[1rem] mobile:mx-[1rem] " >

        {/* name */}
        <div className=" uppercase font-inter mb-[1rem] " >
          <div className=" text-[0.75rem] mb-[0.3rem] font-semibold text-locked-grey " >name</div>
          <input disabled={true} className=" bg-secondary-grey text-almost-black px-[0.9rem] rounded-[0.3rem] py-[1rem] placeholder:text-almost-black text-[1.0625rem] w-full " type="text" placeholder="name" value={formData.username}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
        </div>
        {/* 
        <div className=" uppercase font-inter mb-[1rem] " >
          <div className=" text-[0.75rem] mb-[0.3rem] font-semibold text-locked-grey " >surname</div>
          <input className=" bg-secondary-grey text-almost-black px-[0.9rem] rounded-[0.3rem] py-[1rem] placeholder:text-almost-black text-[1.0625rem] w-full uppercase " type="text" placeholder="surname" onChange={(e) => setFormData({ ...formData, surname: e.target.value })} />
        </div> */}
        {/* email */}
        <div className=" uppercase font-inter mb-[1rem] " >
          <div className=" text-[0.75rem] mb-[0.3rem] font-semibold text-locked-grey " >email</div>
          <input disabled={true} className=" bg-secondary-grey text-almost-black  px-[0.9rem] rounded-[0.3rem] py-[1rem] placeholder:text-almost-black text-[1.0625rem] w-full " type="text" placeholder="email" value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
        </div>
        {/* phone number */}
        <div className=" uppercase font-inter mb-[1rem] " >
          <div className=" text-[0.75rem] mb-[0.3rem] font-semibold text-locked-grey " >phone number</div>
          <input disabled={true} className=" bg-secondary-grey text-almost-black px-[0.9rem] rounded-[0.3rem] py-[1rem] placeholder:text-locked-grey text-[1.0625rem] w-full uppercase " type="text" placeholder="0987654321" value={formData.phone}
            onChange={(e) => setFormData({ ...formData, phonenumber: e.target.value })} />
        </div>

        {/* <div className="w-full  cursor-pointer " > */}
        <button onClick={(e) => onFormSubmit(e)} className={` ${submitBtnEnable ? "bg-almost-black hover:bg-card-grey  active:bg-light-grey8" : "bg-light-grey10"} mt-[3rem] flex items-center flex-row rounded-[0.3rem]  w-full font-semibold text-center py-[0.8rem] justify-center `} >
          <CheckMarkIcon height="22" width="21" stroke="#fff" />
          <span className=" px-[0.2rem] text-white text-[1.0625rem] font-inter uppercase " >save changes</span>
        </button>
        {/* </div> */}

      </form >

    </div>

  </div>
}

export default YourDetails;