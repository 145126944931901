import { API_BASE_URL } from "../../utils/constants/constants";

export async function AdminServiceAddProduct(data: any, auth: string): Promise<any> {
  try {
    let _results = null;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth}`);

    const raw = JSON.stringify({
      "name": data.name,
      "quantity": data.quantity,
      "price": data.price,
      "discount": data.discount,
      "available": data.available,
    });

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    await fetch(`${API_BASE_URL}/administrators/addproduct`, requestOptions)
      .then((response) => response.json())
      .then((result) => _results = result)
      .catch((error) => _results = { message: error.message, data: [] });

    return _results;
  }
  catch (e: any) {
    return { message: e.message, data: [] }
  }

}