import React from "react";
import "./footer.css"
import SocialLinks from "./components/socials";
import ContactOption from "./components/contactsOption";
import AddressCopyRights from "./components/addressCopyRights";
import FooterLinks from "./components/footerLinks";
import MobileLandScapeSocialUrl from "./components/mobileLandScapeSocialUrls";


const FooterBottomPart: React.FC = () => {

  return <div className=" px-3 mb-5 bg-almost-black py-5 flex flex-col uppercase" >
    {/* top part */}
    <div className=" 
      uppercase flex justify-between flex-col
      mobilels:flex-col 
      tablet:flex-row 
    " >
      <div className=" 
        text-[3.25rem] font-anton text-white tracking-[0.02em] py-0
        tablet:text-[5.75rem] tablet:text-8xl
        mobilels:text-[4.5rem] pb-[0rem]
      " >
        Get in touch <br />with us
      </div>

      {/* for size table and upwards */}
      <div className="  hidden tablet:block" >
        <FooterLinks />
        <AddressCopyRights />
      </div>

      <div className=" tablet:hidden" >
        <ContactOption />
      </div>
    </div>

    {/* right side */}
    <div className=" tablet:hidden mt-[1.5rem] mobilels:hidden uppercase 
    mobilels:justify-between mobilels:flex-row-reverse mobilels:mb-[2rem]
    " >
      <FooterLinks />
      <SocialLinks />
    </div>

    {/* social media links at the bottom */}
    <div className=" tablet:mt-[7rem] hidden tablet:flex tablet:flex-row tablet:justify-between" >
      <ContactOption />
      <SocialLinks />
    </div>

    <MobileLandScapeSocialUrl />

    {/*  bottom part */}
    <div className=" tablet:hidden" >
      <AddressCopyRights />
    </div>
  </div>
}


export default FooterBottomPart