import React from "react";
import PackageIcon from "../../../utils/svg/packageIcon";
// import CurrentOrder from "./currentOrders";
// import PastOrders from "./pastOrders";


const ProfileLeftComponent: React.FC = () => {


  return <div className=" bg-white px-[0.5rem] " >

    <div className=" mt-[1rem] uppercase font-anton text-[2.75rem] flex flex-row items-center 
      desktopsm:text-[3.375rem] 
    " >
      <PackageIcon width="52" height="54" stroke="#232526" />
      <span className=" px-[0.5rem] " >orders</span>
    </div>

    {/*  */}
    {/* <CurrentOrder /> */}
    {/*  */}
    {/*  */}

    {/* past orders */}
    {/* <PastOrders /> */}
    {/* past orders */}
    {/*  */}
  </div>
}

export default ProfileLeftComponent;