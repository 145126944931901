// 
import React from "react";


interface ScreenNameProps {
  screenName: string
}

// 
const ScreenName: React.FC<ScreenNameProps> = ({ screenName }) => {

  return <div className=" uppercase mobile:px-0 px-[1rem] mt-[3rem] " >
    <div className=" flex flex-col mobile:flex-row text-[1.1875rem] font-inter font-semibold mobilels:text-[1.375rem] desktopsm:text-[1.6875rem]  " >
      <span >settings & </span>
      <span >preferences</span>
    </div>
    <span className=" text-[3.375rem] font-anton mobile:text-[4.625rem] mobilels:text-[4.625rem] desktopsm:text-[5.875rem] " >
      {screenName}
    </span>
  </div>
}

export default ScreenName;