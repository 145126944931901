import React from "react";
import { Link } from "react-router-dom";
import { BUSINESSEMAIL, PHONENUMBER } from "../../../utils/constants/constants";
import PhoneLink from "../../../modules/contact/components/phoneLink";
import CallIcon from "../../../utils/svg/callIcon";
import EmailIcon from "../../../utils/svg/emailIcon";
import WhatsappIcon from "../../../utils/svg/whatsappIcon";


const ContactOption: React.FC = () => {


  return <div className="  text-light-grey2
    flex-col pb-[2rem] pt-[0.5rem]
    tablet:flex tablet:flex-row tablet:items-center  
    tablet:pb-[0rem] tablet:pt-[0rem]
  " >
    <Link to={`https://wa.me/${PHONENUMBER}`} target="_blank" className=" flex hover:underline
      py-[0.55rem] border-b-2 border-b-light-grey2 tablet:border-0
    
    " >
      <WhatsappIcon width="21" height="22" stroke={"#fff"} />
      <span className=" pl-[0.5rem]" >whatsapp us</span>
    </Link>

    <span className=" mx-6 tablet:flex hidden " >|</span>

    <Link to={`mailto:${BUSINESSEMAIL}`} target="_blank" className=" flex hover:underline 
       py-[0.55rem] border-b-2 border-b-light-grey2 tablet:border-0
    "  >
      <EmailIcon width="21" height="22" stroke={"#fff"} />
      <span className=" pl-[0.5rem]"  >email us</span>
    </Link>

    <span className=" mx-6 tablet:flex hidden  " >|</span>

    <PhoneLink phoneNumber={PHONENUMBER} cssStyle={" flex hover:underline py-[0.55rem]"} >
      <CallIcon width="21" height="22" stroke={"#fff"} />
      <span className=" pl-[0.5rem]"  >call us</span>
    </PhoneLink>
  </div>
}


export default ContactOption;