import React, { useState } from "react";
import HumBurgerMenu from "../../../utils/svg/humberMenuIcon";
import { DARK_MODE } from "../../../utils/constants/constants";
import MobileMenu from "../../mobileMenu/menu";
import XCancelIcon from '../../../utils/svg/xCancelIcon';


const HamBurgerMenuBtn: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const btn_bg_color = DARK_MODE ? "white" : "almost-black";

  return <>
    <button
      onClick={() => setShowMenu(!showMenu)}
      className={` bg-${btn_bg_color} ${DARK_MODE ? "" : "hover:bg-grey-like1 active:bg-grey-like1"} hover:bg-grey-like1 active:bg-grey-like1 ml-[0.8rem] uppercase flex flex-row text-center rounded-[0.3rem] items-center px-[0.9rem] py-[0rem] tablet:hidden `} >
      {!showMenu ? <>
        <span className={` pr-2 text-[0.9375rem] font-inter font-bold ${DARK_MODE ? "" : "text-white"} `} >
          menu
        </span>
        <div className=" " >
          <HumBurgerMenu stroke={DARK_MODE ? "#232526" : "#fff"} />
        </div>
      </> : <>
        <span className={` pr-2 text-[0.9375rem] font-inter font-bold ${DARK_MODE ? "" : "text-white"} `} >
          close
        </span>
        <div className=" " >
          <XCancelIcon width="16" height="16" stroke="#fff" />
        </div>
      </>
      }
    </button>

    {/* to show the menu on mobile devices */}
    {showMenu && <MobileMenu callbackState={showMenu} callbackRoutine={setShowMenu} />}
  </>
}

export default HamBurgerMenuBtn;