import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState: any[] = [];

export const productSlice = createSlice({
  name: 'producSlice',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<any>) => {
      state = action.payload;
      return state
    },
    clearProducts: (state) => {
      state = [];
      return state;
    }
  }
})

// reducer actions
export const { setProducts, clearProducts } = productSlice.actions;

// selector 
export const ProductsSelector = (state: RootState) => state.productsSlice;

export default productSlice.reducer;