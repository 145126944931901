import React from "react";
import { Link } from "react-router-dom";
import { DARK_MODE } from "../../../utils/constants/constants";
// 
// 
const LogoBtn: React.FC = () => {

  return <Link to="/" className={` ${DARK_MODE ? "text-white " : "text-very-dark-grey  " } text-[1.875rem] text-very-dark-grey active:text-grey-like1 hover:text-grey-like1 flex flex-row `} >
    <span  >Solid </span>
    <span className=" hidden iphone8:hidden iphone14p:flex ml-[0.3rem] " >Water</span>
  </Link>
}

export default LogoBtn;